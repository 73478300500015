/* eslint-disable react-hooks/exhaustive-deps */
import { useState, useEffect } from 'react';
import styled from 'styled-components';
import { useSelector, useDispatch } from 'react-redux';
import { PageHeader, SubHeader, IntegrationCard, SimpleModal } from '../components';
import {
  getIntegrations,
  enableIntegration,
  disableIntegration,
  toggleFinancials,
} from '../store/actions';
import { Page } from '../styles/library/layoutStyles';
import { colors, maxDevice, fonts } from '../styles/variables';
import {
  ErrorNotification,
  headerContent,
  integrationOptions,
  SendTrackingEvent,
} from '../utils';
import { ButtonThemes } from '../styles/themes';

const IntegrationsContainer = () => {
  const dispatch = useDispatch();
  const {
    integrations,
    enableIntegrationError,
    enabledIntegration,
    integrationId,
    invocationId,
    toggledIntegrationFinancials,
  } = useSelector((state) => ({
    integrations: state.user.integrations,
    enableIntegrationError: state.user.enableIntegrationError,
    enabledIntegration: state.user.enabledIntegration,
    integrationId: state.user.integrationId,
    invocationId: state.configs.invocationId,
    toggledIntegrationFinancials: state.user.toggledIntegrationFinancials,
  }));

  const [integrationsList, setIntegrationsList] = useState([]);
  const [currentLogin, setCurrentLogin] = useState({
    username: '',
    password: '',
  });
  const [selectedIntegration, setSelectedIntegration] = useState('');
  const [loadingIntegrations, setLoadingIntegrations] = useState(true);
  const [loginError, setLoginError] = useState('');
  const [buttonLoading, setButtonLoading] = useState(false);
  const [showCreateHousehold, setShowCreateHousehold] = useState(false);
  const [showDeactivateWarningModal, setShowDeactivateWarningModal] =
    useState(false);
  const [deactivateIntegration, setDeactivateIntegration] = useState({});

  useEffect(() => {
    dispatch(getIntegrations());
    return () => {
      setLoginError('');
    };
  }, []);

  useEffect(() => {
    if (enabledIntegration && integrationId) {
      return SendTrackingEvent(
        invocationId,
        'action',
        'integration_activate',
        'integration_member',
        { integration: integrationId }
      );
    }
  }, [enabledIntegration]);

  useEffect(() => {
    if (toggledIntegrationFinancials) {
      let action =
        toggledIntegrationFinancials.passSubId === true
          ? 'integration_financials_activate'
          : 'integration_financials_deactivate';
      return SendTrackingEvent(
        invocationId,
        'action',
        action,
        'integration_member',
        { integration: toggledIntegrationFinancials.passId }
      );
    }
  }, [toggledIntegrationFinancials]);

  useEffect(() => {
    if (integrations) {
      let updated = integrations.map((int) => {
        const integration = { ...int };
        let details = integrationOptions.find(
          (int) => int.id === integration.key
        );
        if (details === undefined) {
          details = {
            name: integration.key,
            id: 'missing',
            image: null,
            info: `Integration missing. Contact Support code "${integration.key}"`,
            inbound: true,
            has_sso: false,
            disable: true,
          };
        }
        integration.showLogin = false;
        if (integration?.error_message) {
          ErrorNotification(`Unable to Authenticate ${details.name}`);
        }
        return { ...integration, ...details };
      });

      //Check that there is at least one enabled create household integration
      const hasActiveHouseholdIntegration = updated.some(
        (int) => !int.exclude_household && !int.inbound && int.is_enabled
      );
      setShowCreateHousehold(hasActiveHouseholdIntegration);
      setIntegrationsList(updated);
      setLoadingIntegrations(false);
      setLoginError('');
      setSelectedIntegration('');
      setButtonLoading(false);
    }
  }, [integrations]);

  useEffect(() => {
    if (enableIntegrationError) {
      if (enableIntegrationError.hasOwnProperty('data')) {
        const firstMessage =
          enableIntegrationError.data[
            Object.keys(enableIntegrationError.data)[0]
          ];
        if (firstMessage) {
          setLoginError(firstMessage);
          if (firstMessage.includes('Error connecting to partner')) {
            setLoginError('Error connecting to partner');
          }
          if (firstMessage.includes('Invalid Credentials')) {
            setLoginError('Invalid Credentials');
          }
          if (firstMessage.includes('invalid_client')) {
            setLoginError('Invalid Credentials');
          }
        }
      } else {
        setLoginError('Error signing in. Please try again');
      }
      setButtonLoading(false);
    }
  }, [enableIntegrationError]);

  // const sortIntegrations = (integrations) => {
  //   let matchedIntegrations = integrations
  //     .filter((int) => {
  //       return int.is_enabled;
  //     })
  //     .sort((a, b) => {
  //       return a.name - b.name;
  //     });
  //   // .sort((a, b) => {
  //   //   return a.priority - b.priority || a.name.localeCompare(b.name);
  //   // });
  //   let inactiveIntegrations = integrations
  //     .filter((int) => {
  //       return !int.is_enabled;
  //     })
  //     .sort((a, b) => {
  //       return a.name - b.name;
  //     });
  //   // .sort((a, b) => {
  //   //   return a.priority - b.priority || a.name.localeCompare(b.name);
  //   // });
  //   return [...matchedIntegrations, ...inactiveIntegrations];
  // };

  const toggleIntegration = (integration) => {
    if (integration.enable_method === 'oauth') {
      if (integration.hasOwnProperty('partner_oauth_url')) {
        window.location.replace(integration.partner_oauth_url);
        SendTrackingEvent(
          invocationId,
          'action',
          'integration_sso',
          'integration_member',
          { integration: integration.id }
        );
      }
    } else if (integration.enable_method === 'session') {
        dispatch(enableIntegration(integration));
        SendTrackingEvent(
          invocationId,
          'action',
          'integration_sso',
          'integration_member',
          { integration: integration.id }
        );
    } else {
      const mappedIntegrations = integrationsList.map((int) => {
        if (int.id === integration.id) {
          int.showLogin = true;
        } else {
          int.showLogin = false;
        }
        return int;
      });
      setIntegrationsList(mappedIntegrations);
      setSelectedIntegration(integration.name);
      setLoginError('');
      setCurrentLogin({ username: '', password: '' });
    }
    return null;
  };

  const generateDeactivateWarningModal = {
    heading: `Deactivate ${deactivateIntegration.name}`,
    text: deactivateIntegration?.warningModalText,
    marginTop: '16vh',
    minWidth: '335px',
    buttons: [
      {
        text: 'Close',
        function: () => {
          setShowDeactivateWarningModal(false);
        },
        theme: ButtonThemes.cancel,
      },
      {
        text: 'Confirm',
        function: () => {
          dispatch(disableIntegration(deactivateIntegration));
          setShowDeactivateWarningModal(false);
          setDeactivateIntegration({});
          return SendTrackingEvent(
            invocationId,
            'action',
            'integration_deactivate',
            'integration_member',
            { integration: deactivateIntegration.id }
          );
        },
        theme: ButtonThemes.primary,
      },
    ],
  };

  const sendDisableIntegration = (integration) => {
    if (integration?.warningModal) {
      setShowDeactivateWarningModal(true);
      setDeactivateIntegration(integration);
    } else {
      dispatch(disableIntegration(integration));
      return SendTrackingEvent(
        invocationId,
        'action',
        'integration_deactivate',
        'integration_member',
        { integration: integration.id }
      );
    }
  };

  const inboundIntegrationsHelpUrl =
    'https://usersupport.asset-map.com/en/articles/8945582-inbound-integrations';

  const visitIntegration = (integration) => {
    if (
      integration.enable_method === 'inbound' &&
      // Check for service-specific help URL before serving generic Asset-Map one
      integration.enable_url !== null
    ) {
      window.open(integration.enable_url, '_blank');
    } else {
      window.open(inboundIntegrationsHelpUrl, '_blank');
    }
    return SendTrackingEvent(
      invocationId,
      'action',
      'integration_click',
      'integration_member',
      { integration: integration.id }
    );
  };

  const checkHasValue = (value) => {
    if (value === '' || value === null || value === undefined) {
      return false;
    } else {
      return true;
    }
  };

  const integrationLogin = (integration) => {
    if (!checkHasValue(currentLogin.username)) {
      return setLoginError('Please provide a username');
    }
    if (!checkHasValue(currentLogin.password)) {
      return setLoginError('Please provide a password');
    }
    setButtonLoading(true);
    dispatch(enableIntegration(integration, currentLogin));
  };

  const updateLoginInfo = (e) => {
    setLoginError('');
    setCurrentLogin({
      ...currentLogin,
      [e.currentTarget.name]: e.currentTarget.value,
    });
  };

  const toggleIntFinancials = (integration, toggle) => {
    dispatch(toggleFinancials(integration, toggle));
  };

  const loginInputs = [
    {
      type: 'text',
      label: `${selectedIntegration} Username`,
      name: 'username',
      width: '100%',
      required: true,
      placeholder: `${selectedIntegration} Username`,
      isVisible: true,
      value: currentLogin.username,
      onChange: (e) => updateLoginInfo(e),
      // hasError: missingName,
    },
    {
      type: 'password',
      label: `${selectedIntegration} Password`,
      name: 'password',
      width: '100%',
      required: true,
      placeholder: `${selectedIntegration} Password`,
      isVisible: true,
      value: currentLogin.password,
      onChange: (e) => updateLoginInfo(e),
      // hasError: missingName,
    },
  ];

  //Button Only shows when active integration that can create a household

  return (
    <Page>
      <ColumnContainerLayout>
        <PageHeader
          content={
            showCreateHousehold
              ? {
                  ...headerContent.integrations,
                  buttonText: 'Create Household',
                  showButton: true,
                  buttonLink: '/household/integrations',
                }
              : headerContent.integrations
          }
        />
        <SubHeader content={headerContent.integrationsSubHeader} />
        {showDeactivateWarningModal && deactivateIntegration && (
          <SimpleModal
            hide={() => setShowDeactivateWarningModal(false)}
            content={generateDeactivateWarningModal}
          />
        )}
        {loadingIntegrations && (
          <CardsContainer>
            {[1, 2, 3, 4, 5].map((item) => {
              return (
                <Card key={item}>
                  <CardContent>
                    <CardImageContainer>
                      <ImagePlaceholder />
                    </CardImageContainer>
                    <CardInfoPlaceholder />
                    <ButtonPlaceholder />
                    <CardSmallTextPlaceholder />
                    <CardPermissionsTextPlaceholder />
                  </CardContent>
                </Card>
              );
            })}
          </CardsContainer>
        )}
        <CardsContainer>
          {integrationsList.map((integration, index) => {
            return (
              <IntegrationCard
                key={index}
                cardId={index}
                integration={integration}
                toggleIntegration={toggleIntegration}
                sendDisableIntegration={sendDisableIntegration}
                visitIntegration={visitIntegration}
                integrationLogin={integrationLogin}
                loginInputs={loginInputs}
                buttonLoading={buttonLoading}
                loginError={loginError}
                toggleFinancials={toggleIntFinancials}
              />
            );
          })}
        </CardsContainer>
      </ColumnContainerLayout>
    </Page>
  );
};

const ColumnContainerLayout = styled.div`
  margin: 10px 0;
`;

const CardsContainer = styled.div`
  @media ${maxDevice.tablet} {
    justify-content: center;
  }
  @media ${maxDevice.mobileL} {
    margin: 10px 15px 15px 20px;
  }
  @media ${maxDevice.mobileS} {
    margin: 10px 15px 15px 15px;
  }
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  align-items: center;
  align-content: center;
  justify-content: flex-start;
  margin: 10px 30px 60px 30px;
`;

const Card = styled.div`
  @media (max-width: 1360px) {
    flex: 1 1 40%;
    max-width: 40%;
    min-width: 40%;
  }
  @media ${maxDevice.tablet} {
    flex: 1 1 85%;
    max-width: 85%;
    min-width: 85%;
  }
  @media ${maxDevice.mobileL} {
    /* min-width: 250px; */
    margin: 0 0px 20px 0px;
    height: 320px;
  }
  @media ${maxDevice.mobileS} {
    padding: 15px 13px;
    margin: 0 0px 10px 0px;
  }
  border: 1px solid ${colors.lightGrey};
  border-radius: 0 0 5px 5px;
  background: ${colors.lighterGrey};
  text-align: center;
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  align-items: center;
  align-content: center;
  justify-content: center;
  flex: 1 1 360px;
  max-width: 360px;
  min-width: 300px;
  height: 280px;
  padding: 20px 25px;
  margin: 0 10px 20px 10px;
  button {
    margin: 0px 0 25px 0;
    width: 150px;
  }
  /* &:hover {
    transform: scale(1.01);
  } */
`;

const CardContent = styled.div`
  width: 100%;
  button {
    width: 100%;
    margin-bottom: 10px;
  }
`;

const CardImageContainer = styled.div`
  flex: 0 0 auto;
  display: flex;
  align-content: center;
  justify-content: center;
  align-items: center;
  height: 45px;
`;

const CardInfo = styled.div`
  @media ${maxDevice.tablet} {
    font-size: 11px;
  }
  font-size: 12px;
  font-weight: ${fonts.semiBold};
  height: 50px;
  padding: 15px 0;
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  align-items: center;
  align-content: center;
  justify-content: center;
  flex: 1 1 auto;
  p {
    display: inline;
    max-width: 300px;
    &:first-child {
      margin-bottom: 8px;
    }
  }
  a {
    color: ${colors.lightBlue};
  }
  /* border-bottom: 1px solid ${colors.lightGrey}; */
`;

const CardInfoPlaceholder = styled(CardInfo)`
  margin: 25px 0;
  height: 45px;
  background: ${colors.lightGrey};
`;

const CardSmallText = styled.p`
  font-size: 10px;
  color: ${colors.paleGrey};
`;

const CardSmallTextPlaceholder = styled(CardSmallText)`
  max-width: 240px;
  height: 20px;
  margin: 0 auto;
  margin-top: 20px;
  background: ${colors.lightGrey};
`;

const CardPermissionsText = styled.p`
  font-size: 10px;
  color: ${colors.paleGrey};
  margin-top: 12px;
`;

const CardPermissionsTextPlaceholder = styled(CardPermissionsText)`
  max-width: 140px;
  height: 20px;
  margin: 0 auto;
  margin-top: 10px;
  background: ${colors.lightGrey};
`;

// const HorizontalLine = styled.div`
//   @media ${maxDevice.mobileL} {
//     margin: 0 20px 25px 20px;
//   }
//   border-top: 1px solid ${colors.lightGrey};
//   margin: 0 40px 25px 40px;
// `;
/*
const CardImportedInfo = styled.div`
  flex: 0 0 auto;
  width: 100%;
  margin-top: 15px;
  padding: 5px 0;
  font-size: 12px;
  color: ${colors.paleGrey};
  border-top: 1px solid ${colors.lightGrey};
  border-bottom: 1px solid ${colors.lightGrey};
  display: flex;
  align-content: center;
  justify-content: center;
  align-items: center;
`;

const CardImportedItem = styled.div`
  flex: 0 0 auto;
`;

const CardItemLabel = styled.span`
  text-transform: capitalize;
`;

const CardItemValue = styled.span`
  margin-left: 2px;
`;

const CardItemSeparator = styled.span`
  margin: 0 8px;
`;

const CardSearchBarContainer = styled.div`
  flex: 1 1 auto;
  width: 100%;
  margin: 20px 0;
`;

const CardSearchHeader = styled.p`
  font-weight: ${fonts.semiBold};
  font-size: 14px;
  margin-bottom: 10px;
`;
*/

const ImagePlaceholder = styled.p`
  height: 50px;
  background: ${colors.lightGrey};
  max-width: 300px;
  width: 100%;
`;

const ButtonPlaceholder = styled.p`
  height: 38px;
  background: ${colors.lightGrey};
  width: 150px;
  margin: 0 auto;
  border-radius: 5px;
`;

export default IntegrationsContainer;
