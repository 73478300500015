/* eslint-disable react-hooks/rules-of-hooks */
import { formatMoney } from 'accounting-js';
import {
  textFilterOptions,
  integerFilterOptions,
  DateFormatUTC,
  DateFromNowUTC,
  DateFormat,
} from '.';
import { DateDiff } from '.';
import {
  folderIcon,
  aviIcon,
  mp4Icon,
  mp3Icon,
  jpgIcon,
  pngIcon,
  gifIcon,
  docIcon,
  pptIcon,
  xlsIcon,
  unknownIcon,
  csvIcon,
  htmlIcon,
  jsIcon,
  xmlIcon,
  zipIcon,
  jsonIcon,
  txtIcon,
  rtfIcon,
  svgIcon,
  pdfIcon,
  circleAdd,
  circleDelete,
  errorCircle,
  circleEdit,
} from '../assets';
import { colors, messageColors, targetMapColors } from '../styles/variables';
import { StatusLabel } from '../styles/library/fontStyles';
import { ProposalIconContainer } from '../styles/library/imageStyles';

//TRANSFORMS ARRAY TO HAVE VALUES NEEDED FOR SELECT DROPDOWN
export const TransformSelectOptions = (list, label, value) => {
  if (list) {
    return list.map((item) => {
      item.label = item[label];
      item.value = item[value];
      return item;
    });
  }
};

export const FilterMembersByContext = (list, context) => {
  const firstObject = list[0];
  const contextExists = firstObject?.context.hasOwnProperty(context);
  if (contextExists) {
    return [...list].filter((member) => {
      return member.context[context];
    });
  }
  return [...list];
};

//TAKES ARRAYS AND RETURNS A SINGLE UNIQUE ARRAY
export const MergeArrays = (...arrays) => {
  let jointArray = [];

  arrays.forEach((array) => {
    jointArray = [...jointArray, ...array];
  });
  return [...new Set([...jointArray])];
};

//TRANSFORMS CURRENCY LIST FOR SELECT DROPDOWN DISPLAY
export const GetCurrencies = (currencies) => {
  if (currencies && currencies.length >= 1) {
    return currencies.map((item) => {
      let obj = {};
      obj.value = item.code;
      obj.label = item.name;
      return obj;
    });
  }
};

//TRANSFORMS COUNTRY LIST FOR SELECT DROPDOWN DISPLAY
export const GetCountries = (countries) => {
  if (countries && countries.length >= 1) {
    return countries.map((item) => {
      let obj = {};
      obj.value = item.code;
      obj.label = item.name;
      return obj;
    });
  }
};

//TRANSFORMS STATES LIST FOR SELECT DROPDOWN DISPLAY
export const GetStates = (states) => {
  return states.map((item) => {
    let obj = {};
    obj.value = item.abbreviation;
    obj.label = item.name;
    return obj;
  });
};

//CALCULATES AGE FROM DOB
export const GenerateAge = (dob, dateFormat) => {
  if (dateFormat === undefined) {
    dateFormat = 'MM/DD/YYYY';
  }
  let age = DateDiff(undefined, dob);
  return age;
};

//DETERMINES DISPLAY GENDER
export const TransformGender = (gender) => {
  switch (gender) {
    case 'M':
      return 'Male';
    case 'F':
      return 'Female';
    default:
      return 'Not Specified';
  }
};

//DETERMINES INTERVAL FROM INTEGER
export const TransformInterval = (interval) => {
  switch (interval) {
    case 1:
      return 'Annual';
    case 2:
      return 'Semi-Annual';
    case 4:
      return 'Quarter';
    case 6:
      return 'Bi-month';
    case 12:
      return 'Month';
    case 24:
      return 'Semi-Month';
    case 26:
      return 'Bi-week';
    case 52:
      return 'Week';
    case 365:
      return 'Daily';
    default:
      return 'N/A';
  }
};

//CREATES VALUE FOR RELATIONSHIP - USES RELATIONSHIP TO DISPLAY IF EXISTS, THEN CATEGORY VALUE
export const GenerateRelationshipDisplay = (member, categories = []) => {
  let displayValue = member.category;
  if (member?.description) {
    displayValue = member?.description;
  } else {
    const matchedCategory = categories.find(
      (cat) => cat.value === member?.category
    );
    if (matchedCategory?.label) {
      displayValue = matchedCategory?.label;
    }
  }
  return displayValue;
};

//CREATES DISPLAY VALUES FOR ENTITIES
export const GenerateEntityLabel = (value, categories = null) => {
  if (typeof value === 'object' && value !== null) {
    value = value.key;
  }
  if (categories) {
    let entityCategory = value;
    const category = categories.find((cat) => cat.value === value);
    if (category?.label) {
      entityCategory = category.label;
    }
    return entityCategory;
  }
  switch (value) {
    case 'corp':
      return 'Corporation - C Corp';
    case 'scorp':
      return 'Corporation - S Corp';
    case 'llc':
      return 'Corporation - LLC';
    case 'trust':
      return 'Trust - Irrevocable';
    case 'revtrust':
      return 'Trust - Revocable';
    case 'pubcharity':
      return 'Charity - Public';
    case 'corpother':
      return 'Other';
    case 'foundation':
      return 'Private Foundation';
    case 'sole':
      return 'Sole Proprietorship';
    case 'estate':
      return 'Estate';
    case 'buspart':
      return 'Business Partner';
    default:
      return value;
  }
};

//CREATES CATEGORY DISPLAY VALUE  FOR INSTRUMENTS
export const GenerateInstrumentType = (type, instrumentCategories) => {
  const matchedCategory = instrumentCategories.find(
    (cat) => cat.value === type
  );
  if (matchedCategory?.label) {
    return matchedCategory?.label;
  }

  return type;
};

//TAKES TARGETMAP TYPE AND RETURNS DISPLAY VALUE
export const TransformTargetMapType = (type) => {
  if (!type) {
    return 'None';
  }
  switch (type) {
    case 'death':
      return 'Loss of Life';
    case 'disability':
      return 'Long-term Disability';
    case 'ltc':
      return 'Long-term Care';
    default:
      return type.charAt(0).toUpperCase() + type.slice(1);
  }
};

//TAKES ARRAY OF NOTIFICATIONS AND ADDS TWO TRANSFORMED DATE OPTIONS
export const TransformNotificationDate = (notifications) => {
  return notifications.map((notification) => {
    notification.date = DateFormatUTC(notification.created_utc, 'LLL', true);
    notification.relative = DateFromNowUTC(notification.created_utc);
    return notification;
  });
};

/*
  TURNS OBJECT OF OBJECTS INTO ARRAY OF OBJECTS
  - saveKey <boolean> - if true sets object key to fieldName in new object
 */
export const ConvertObjectToArray = (object, saveKey = false) => {
  return Object.entries(object).map((e) => {
    if (saveKey) {
      return { fieldName: e[0], ...e[1] };
    } else {
      return e[1];
    }
  });
};

//TURNS SIGNALS OBJECT OF OBJECTS INTO ARRAY OF OBJECTS
export const ConvertSignalsObjectToArray = (object) => {
  if (object) {
    let newObject = {
      calculated_utc: object?.calculated_utc,
      signals: [],
    };
    newObject.signals = Object.entries(object).reduce((acc, curr) => {
      if (curr[1].hasOwnProperty('score')) {
        return [...acc, { name: curr[0], ...curr[1] }];
      }
      return acc;
    }, []);
    return newObject;
  } else {
    return false;
  }
};

//CREATES CATEGORY DISPLAY VALUE  FOR INSTRUMENTS
// const GenerateInstrumentTypeOld = (type, instrumentCategories) => {
//   if (instrumentCategories) {
//     let matchedObj = instrumentCategories[type];
//     if (matchedObj) {
//       return matchedObj.name;
//     }
//   } else {
//     return type;
//   }
// };

//TURNS ENCODED TEXT INTO HTML THEN INTO STRING
export const HtmlToString = (string) => {
  return new DOMParser()
    .parseFromString(string, 'text/html')
    .querySelector('html').textContent;
};

//REPLACES NULL VALUES WITH EMPTY STRING
export const PruneObjectNulls = (object) => {
  let updatedObject = {};
  for (const [key, value] of Object.entries(object)) {
    if (value === null) {
      updatedObject[key] = '';
    } else {
      updatedObject[key] = value;
    }
  }
  return updatedObject;
};

//TAKES AN ARRAY AND SETS THE PASSED IN FIELD NAME TO THE PASSED IN VALUE
export const SetObjectFieldValue = (list, field, value = false) => {
  return list.map((item) => {
    item[field] = value;
    return item;
  });
};

//DETERMINES COLOR FOR TARGET-MAP BAR & CIRCLE DISPLAY
export const DetermineTargetMapColor = (type) => {
  switch (type) {
    case 'death':
      return targetMapColors.death;
    case 'disability':
      return targetMapColors.disability;
    case 'ltc':
      return targetMapColors.ltc;
    case 'education':
      return targetMapColors.education;
    case 'retirement':
      return targetMapColors.retirement;
    case 'custom':
      return targetMapColors.custom;
    case 'user':
      return targetMapColors.user;
    default:
      return targetMapColors.other;
  }
};

// DETERMINES DROPDOWN OPTIONS FOR FILTER MODAL
export const DetermineOptions = (type) => {
  switch (type) {
    case 'integerFormatter':
      return integerFilterOptions;
    case 'currencyFormatter':
      return integerFilterOptions;
    case 'ratioFormatter':
      return integerFilterOptions;
    case 'textFormatter':
      return textFilterOptions;
    case 'linkedFormatter':
    case 'capitalizedTextFormatter':
    case 'iconFormatter':
      return [
        { label: 'Is', value: 'is', not: false },
        { label: 'Is Not', value: 'is_not', not: true },
      ];
    case 'dateFormatter':
      return [
        {
          label: 'Not Available',
          value: 'none',
        },
      ];
    case 'booleanFormatter':
      return [
        { label: 'Yes', value: 'yes' },
        { label: 'No', value: 'no' },
      ];
    case 'status':
      return [
        { label: 'Client', value: 'client' },
        { label: 'Prospective', value: 'prospect' },
        { label: 'Sample', value: 'sample' },
        { label: 'Stencil', value: 'stencil' },
      ];
    case 'signal_ltc_state':
    case 'signal_ltd_state':
    case 'signal_liability_state':
    case 'signal_liquidity_state':
    case 'signal_longevity_state':
    case 'signal_life_state':
      return [
        { label: 'Green', value: 'green' },
        { label: 'Yellow', value: 'yellow' },
        { label: 'Red', value: 'red' },
        // { label: 'Snoozed', value: 'snoozed' },
        { label: 'None', value: 'none' },
      ];
    default:
      return [
        {
          label: 'Not Available',
          value: 'none',
        },
      ];
  }
};

//TRANSFORMS COLUMN ALIGNMENT FOR HHI
export const DetermineTextAlign = (position) => {
  switch (position) {
    case 'right':
      return 'flex-end';
    case 'left':
      return 'flex-start';
    case 'center':
      return 'center';
    default:
      return 'flex-end';
  }
};

//TARGETMAP AMOUNT PLACEHOLDER TEXT FOR AMOUNT IF DIFFERENT
export const CreateAmountPlaceholder = (
  instrumentInfo,
  currency,
  placeholder = 'Amount'
) => {
  if (instrumentInfo && instrumentInfo.hasOwnProperty('default_amount')) {
    return FormatCurrency(instrumentInfo?.default_amount, currency);
  } else {
    return placeholder;
  }
};

//CHECK IF TARGETMAP VALUE DIFFERS FROM INSTRUMENT
export const CheckOverwritten = (isNew, instrumentInfo) => {
  if (isNew) return false;
  if (instrumentInfo && instrumentInfo.hasOwnProperty('default_amount')) {
    if (instrumentInfo?.amount !== instrumentInfo?.default_amount) {
      return true;
    }
    return false;
  }
  return false;
};

export const FormatPhoneNumber = (number) => {
  const cleaned = ('' + number).replace(/\D/g, '');
  const match = cleaned.match(/^(1|)?(\d{3})(\d{3})(\d{4})$/);
  if (match) {
    const intlCode = match[1] ? '+1 ' : '';
    return [intlCode, match[2], '-', match[3], '-', match[4]].join('');
  }
  return null;
};

//RETURN ICON AND LABEL FOR PROPOSAL ACTION
export const GenerateProposalIcon = (item, direction, style) => {
  switch (item?.proposal_action) {
    case 'add':
      item.label = `Created`;
      item.icon = circleAdd;
      break;
    case 'delete':
      item.label = `Deleted`;
      item.icon = circleDelete;
      break;
    case 'modify':
      item.label = `Edited`;
      item.icon = circleEdit;
      break;
    default:
      item.label = 'An unknown action has taken place.';
      item.icon = errorCircle;
  }
  return (
    <ProposalIconContainer
      title={item.label}
      className="proposal_icon"
      data-proposal-action={`proposal-${item?.proposal_action}-icon`}
      $direction={direction}
      $isResidence={item.is_residence}
      $top={item?.is_legal ? '-6px' : null}
      style={{ ...style }}
    >
      <img src={item?.icon} alt={item?.proposal_action} />
    </ProposalIconContainer>
  );
};

//RETURNS CURRENCY SYMBOL FROM CURRENCY OBJECT
export const TransformCurrencySymbol = (currency) => {
  if (currency) {
    if (currency.code === 'EUR') {
      return '€';
    }
    if (currency.code === 'CNY' || currency.code === 'JPY') {
      return '¥';
    }
    if (currency.code === 'GHS') {
      return '₵';
    }
    if (
      currency.code === 'GBP' ||
      currency.code === 'EGP' ||
      currency.code === 'FKP' ||
      currency.code === 'JEP' ||
      currency.code === 'GIP' ||
      currency.code === 'GGP' ||
      currency.code === 'LBP' ||
      currency.code === 'SHP' ||
      currency.code === 'IMP' ||
      currency.code === 'SYP'
    ) {
      return '£';
    } else {
      return currency.symbol;
    }
  } else {
    return '';
  }
};

//TRANSFORMS NUMBER TO CURRENCY, MUST PROVIDE VALUE AND MATCHING CURRENCY OBJECT
export const FormatCurrency = (value, currency, precision = 0) => {
  let prefixFormat = { pos: '%s %v', neg: '(%s %v)', zero: '%s %v' };
  const suffixFormat = { pos: '%v %s', neg: '(%v %s)', zero: '%v %s' };
  if (currency && currency.hasOwnProperty('symbol_location')) {
    if (currency.code === 'EUR') {
      currency.symbol = '€';
    }
    if (currency.code === 'CNY' || currency.code === 'JPY') {
      currency.symbol = '¥';
    }
    if (currency.code === 'GHS') {
      currency.symbol = '₵';
    }
    if (
      currency.code === 'GBP' ||
      currency.code === 'EGP' ||
      currency.code === 'FKP' ||
      currency.code === 'JEP' ||
      currency.code === 'GIP' ||
      currency.code === 'GGP' ||
      currency.code === 'LBP' ||
      currency.code === 'SHP' ||
      currency.code === 'IMP' ||
      currency.code === 'SYP'
    ) {
      currency.symbol = '£';
    }
    if (currency.code === 'USD') {
      prefixFormat = { pos: '%s%v', neg: '(%s%v)', zero: '%s%v' };
    }
    if (currency.code === 'INR') {
      const newValue = new Intl.NumberFormat('en-IN', {
        maximumSignificantDigits: 3,
        style: 'currency',
        currency: 'INR',
      }).format(value);
      return newValue;
    }
    return formatMoney(value, {
      symbol: currency.symbol,
      precision,
      decimal: currency.decimal,
      thousand: currency.thousand_separator,
      format:
        currency.symbol_location === 'suffix' ? suffixFormat : prefixFormat,
    });
  } else {
    return formatMoney(value, {
      symbol: '$',
      precision,
      decimal: '.',
      thousand: ',',
      format: prefixFormat,
    });
  }
};

//RETURNS INSTRUMENT PRIORITY TO BE USED IN SORTING
export const AddInstrumentPriority = (instrument) => {
  switch (instrument.category) {
    case 'income.earned.w2':
      return 102;
    case 'income.earned.1099':
      return 103;
    case 'income.earned.other':
      return 104;
    case 'income.unearned.annuity':
      return 106;
    case 'income.unearned.distributions':
      return 107;
    case 'income.unearned.pension':
      return 108;
    case 'income.unearned.rental':
      return 109;
    case 'income.unearned.revmortgage':
      return 110;
    case 'income.unearned.socialsecurity':
      return 111;
    case 'income.unearned.other':
      return 112;
    case 'income.expense.taxes':
      return 141;
    case 'income.expense.savings':
      return 142;
    case 'income.expense.premiums':
      return 143;
    case 'income.expense.living':
      return 144;
    case 'income.expense.gifts':
      return 145;
    case 'income.expense.other':
      return 146;
    case 'retirement':
      return 201;
    case 'nonretirement':
      return 202;
    case 'residence':
      return 203;
    case 'creditcard':
      return 301;
    case 'homeequity':
      return 302;
    case 'creditline':
      return 303;
    case 'loan':
      return 304;
    case 'mortgage':
      return 305;
    case 'schoolloan':
      return 306;
    case 'otherliability':
      return 307;
    case 'lifeterm':
      return 401;
    case 'lifeul':
      return 402;
    case 'lifewhole':
      return 403;
    case 'lifevul':
      return 404;
    case 'disability':
      return 405;
    case 'ltc':
      return 406;
    case 'propcas':
      return 407;
    default:
      return 500;
  }
};

//RETURNS MEMBER PRIORITY TO BE USED IN SORTING
export const AddMemberPriority = (member) => {
  switch (member.category) {
    case 'primary':
      return 1;
    case 'spouse':
      return 2;
    case 'partner':
      return 3;
    case 'dependent':
      return 4;
    case 'family':
      return 5;
    case 'friend':
      return 6;
    case 'trustee':
      return 7;
    case 'buspart':
      return 8;
    case 'employee':
      return 9;
    case 'employer':
      return 10;
    case 'accountant':
      return 11;
    case 'attorney':
      return 12;
    case 'other':
      return 13;
    case 'corp':
      return 50;
    case 'llc':
      return 51;
    case 'scorp':
      return 52;
    case 'partnership':
      return 53;
    case 'sole':
      return 54;
    case 'revtrust':
      return 55;
    case 'trust':
      return 56;
    case 'charity':
      return 57;
    case 'pubcharity':
      return 58;
    case 'foundation':
      return 59;
    case 'institution':
      return 60;
    case 'corpother':
      return 61;
    default:
      return 100;
  }
};

//SORTS MEMBERS TO MATCH BACKEND NATURAL SORTING ORDER
export const SortMembers = (members) => {
  // eslint-disable-next-line array-callback-return
  return members.sort((a, b) => {
    const firstDate = new Date(a.date_of_birth);
    const secondDate = new Date(b.date_of_birth);
    if (a.priority > b.priority) return 1;
    if (a.priority < b.priority) return -1;
    if (firstDate > secondDate) return 1;
    if (firstDate < secondDate) return -1;
    if (a.name.toLowerCase() > b.name.toLowerCase()) return 1;
    if (a.name.toLowerCase() < b.name.toLowerCase()) return -1;
    if (a.created_utc > b.created_utc) return 1;
    if (a.created_utc < b.created_utc) return -1;
  });
};

//SORTS PROFESSIONALS TO MATCH BACKEND NATURAL SORTING ORDER
export const SortProfessionals = (members) => {
  // eslint-disable-next-line array-callback-return
  return members.sort((a, b) => {
    const firstDate = new Date(a.date_of_birth);
    const secondDate = new Date(b.date_of_birth);
    if (a.priority > b.priority) return 1;
    if (a.priority < b.priority) return -1;
    if (a.business_name.toLowerCase() > b.business_name.toLowerCase()) return 1;
    if (a.business_name.toLowerCase() < b.business_name.toLowerCase())
      return -1;
    if (firstDate > secondDate) return 1;
    if (firstDate < secondDate) return -1;
    if (a.created_utc > b.created_utc) return 1;
    if (a.created_utc < b.created_utc) return -1;
  });
};

//SORTS INSTRUMENTS TO MATCH BACKEND NATURAL SORTING ORDER
export const SortInstrumentPriority = (instruments) => {
  // eslint-disable-next-line array-callback-return
  return instruments.sort((a, b) => {
    if (a.priority > b.priority) return 1;
    if (a.priority < b.priority) return -1;
    if (a.source.toLowerCase() > b.source.toLowerCase()) return 1;
    if (a.source.toLowerCase() < b.source.toLowerCase()) return -1;
    if (a.amount < b.amount) return 1;
    if (a.amount > b.amount) return -1;
    if (a.name.toLowerCase() > b.name.toLowerCase()) return 1;
    if (a.name.toLowerCase() < b.name.toLowerCase()) return -1;
  });
};

//TAKES CURRENT HOUSEHOLD AND DATA RETURNED FROM API AND UPDATES CURRENT HOUSEHOLD TO USE RETURNED INFO AND SET IN STORE
export const UpdateCurrentHousehold = (data, currentHousehold, categories) => {
  let copiedHousehold = { ...currentHousehold };
  if (data && currentHousehold.hasOwnProperty([data.key])) {
    if (data?.action === 'update') {
      copiedHousehold[data.key] = copiedHousehold[data.key].map((x) =>
        x.id === data.item.id ? data.item : x
      );
    }
    if (data?.action === 'create' || data?.action === 'clone') {
      copiedHousehold[data.key] = [...copiedHousehold[data.key], data.item];
    }
    if (data?.action === 'delete') {
      copiedHousehold[data.key] = copiedHousehold[data.key].filter(
        (x) => x.id !== data.item.passSubId
      );
    }
    if (data?.key === 'householdmembers') {
      copiedHousehold[data.key] = copiedHousehold[data.key].map((member) => {
        const matchingCategory = categories[member.category];
        member.priority =
          matchingCategory?.priority || AddMemberPriority(member);
        return member;
      });
      copiedHousehold[data.key] = SortMembers(copiedHousehold[data.key]);
    }
    if (data?.key === 'excludedHouseholdMembers') {
      copiedHousehold[data.key] = copiedHousehold[data.key].map((member) => {
        const matchingCategory = categories[member.category];
        member.priority =
          matchingCategory?.priority || AddMemberPriority(member);
        return member;
      });
      copiedHousehold[data.key] = SortMembers(copiedHousehold[data.key]);
    }
    if (data?.key === 'householdprofessionals') {
      copiedHousehold[data.key] = copiedHousehold[data.key].map((pro) => {
        const matchingCategory = categories[pro.category];
        pro.priority = matchingCategory?.priority || AddMemberPriority(pro);
        return pro;
      });
      copiedHousehold[data.key] = SortProfessionals(copiedHousehold[data.key]);
    }
    if (data?.key === 'instruments') {
      copiedHousehold[data.key] = copiedHousehold[data.key].map(
        (instrument) => {
          const matchingCategory = categories[instrument.category];
          instrument.priority =
            matchingCategory?.priority || AddInstrumentPriority(instrument);
          return instrument;
        }
      );
      copiedHousehold[data.key] = SortInstrumentPriority(
        copiedHousehold[data.key]
      );
    }
  }
  return copiedHousehold;
};

//RETURNS A FILE ICON FROM STRING MIMETYPE
export const GenerateFileIcon = (object, keyValue) => {
  if (object?.type === 'folder') {
    return folderIcon;
  }
  switch (object[keyValue]) {
    case 'video/avi':
    case 'video/x-msvideo':
      return aviIcon;
    case 'video/mp4':
      return mp4Icon;
    case 'audio':
    case 'audio/mpeg':
      return mp3Icon;
    case 'image/jpeg':
      return jpgIcon;
    case 'image/png':
      return pngIcon;
    case 'image/gif':
      return gifIcon;
    case 'image/svg':
    case 'image/svg+xml':
      return svgIcon;
    case 'application/pdf':
      return pdfIcon;
    case 'application/zip':
      return zipIcon;
    case 'application/json':
      return jsonIcon;
    case 'text/plain':
      return txtIcon;
    case 'text/rtf':
    case 'application/rtf':
      return rtfIcon;
    case 'text/csv':
    case 'application/csv':
      return csvIcon;
    case 'text/html':
    case 'application/html':
      return htmlIcon;
    case 'text/javascript':
    case 'application/javascript':
      return jsIcon;
    case 'text/xml':
    case 'application/xml':
      return xmlIcon;
    case 'application/msword':
    case 'application/vnd.openxmlformats-officedocument.wordprocessingml.document':
      return docIcon;
    case 'application/vnd.ms-powerpoint':
    case 'application/vnd.openxmlformats-officedocument.presentationml.presentation':
      return pptIcon;
    case 'application/vnd.ms-excel':
    case 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet':
      return xlsIcon;
    default:
      return unknownIcon;
  }
};

//MERGES INTEGRATION MEMBER INFO AND MERGES WITHOUT HOUSEHOLD MEMBER INFO
export const TransformIntegrationMembers = (list, householdmembers) => {
  return list.map((member) => {
    const matchedMember = householdmembers.find((mem) => mem.id === member.id);
    if (matchedMember) {
      matchedMember.name = HtmlToString(matchedMember.name);
      return {
        ...member,
        ...matchedMember,
      };
    } else {
      return member;
    }
  });
};

//CHECKS IF RESULT IS IN HOUSEHOLD AND SETS NAME VALUE
export const TransformSalesforceSearchResults = (results, householdMembers) => {
  return results.map((result) => {
    result.is_linked = householdMembers.find((mem) => mem.partner_data);
    if (!result?.name) {
      result.name = result?.first_name
        ? `${result.first_name} ${result.last_name}`
        : result.last_name;
    }
    return result;
  });
};

//TRANSFORMS LINKED SALESFORCE INSTRUMENTS
export const TransformSalesforceLinkedInstruments = (list, value) => {
  if (list && list.length !== 0) {
    return list.map((instrument) => {
      instrument.action = value;
      if (value === 'new') {
        instrument.message = 'OK';
      }
      return instrument;
    });
  }
  return [];
};

export const CapitalizeFirstLetter = (string) => {
  return string.charAt(0).toUpperCase() + string.slice(1);
};

//TAKES SNAKE CASE AND CONVERTS TO CAPITAL (test_error - Test Error)
export const TransformObjectKey = (key) => {
  return key
    .replace(/^[-_]*(.)/, (_, c) => c.toUpperCase())
    .replace(/[-_]+(.)/g, (_, c) => ' ' + c.toUpperCase());
};

//GENERATE MEMBER CONNECT STATUS DISPLAY
export const GenerateStatusDisplay = (status) => {
  let labelText = status;
  let textColor = colors.darkGrey;
  let backgroundColor = colors.lightGrey;
  let borderColor = null;
  switch (status) {
    case 'active':
      labelText = 'active';
      textColor = colors.green;
      backgroundColor = messageColors.successLabelBg;
      break;
    case 'inactive':
      labelText = 'inactive';
      textColor = colors.midGrey;
      backgroundColor = 'transparent';
      borderColor = colors.midGrey;
      break;
    case 'pending':
      labelText = 'pending';
      textColor = messageColors.warning;
      backgroundColor = messageColors.warningLabelBg;
      break;
    case 'rejected':
      labelText = 'rejected';
      textColor = messageColors.error;
      backgroundColor = messageColors.errorLabelBg;
      break;
    default:
      labelText = status;
  }
  return (
    <StatusLabel
      color={textColor}
      background={backgroundColor}
      borderColor={borderColor}
    >
      {labelText}
    </StatusLabel>
  );
};

//CREATES CATEGORY DISPLAY VALUE  FOR INSTRUMENTS
export const GenerateLegalType = (type, legalCategories) => {
  const matchedCategory = legalCategories[type];
  if (matchedCategory?.name) {
    return matchedCategory?.name;
  }
  return type;
};

//FORMAT LIST OF LEGALS WITH PROPER FIELDS
export const TransformLegalList = (legals, members) => {
  return [...legals].map((legal, index) => {
    let matchingMembers = [];
    if (legal?.applicable_members && members) {
      matchingMembers = [...legal?.applicable_members].map((am) => {
        const matchingMember = [...members]?.find(
          (member) => member?.uuid === am?.uuid
        );
        return matchingMember ? matchingMember : am;
      });
    }
    return {
      ...legal,
      index: index,
      value: legal?.reference_name,
      label: legal?.reference_name,
      name: legal?.reference_name,
      members: matchingMembers,
      year: DateFormat(legal?.date_created, 'YYYY'),
      is_proposal: legal?.is_proposal,
      is_legal: true,
      proposal_action: legal?.proposal_action,
      proposal_action_id: legal?.proposal_action_id,
    };
  });
};

export const GenerateTargetMapFundingColor = (targetMap) => {
  const funded = targetMap.funded;
  if (funded <= 24) {
    return targetMapColors.red;
  } else if (funded <= 49) {
    return targetMapColors.orange;
  } else if (funded <= 74) {
    return targetMapColors.yellow;
  } else if (funded >= 75) {
    return targetMapColors.green;
  } else {
    return targetMap.color || DetermineTargetMapColor(targetMap.template);
  }
};
