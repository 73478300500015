/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import styled from 'styled-components';
import {
  ModalButtons,
  Error,
  LoadingAnimation,
  GenerateInput,
  HouseholdSharingModal,
  IntegrationTab,
  Success,
  ProposalsTab,
} from '../';
import { InfoBoxContainer } from '../InfoBox';
import { updateMemberStoreValue } from '../../store/actions';
import { chevronDownDark, link } from '../../assets';
import {
  ExpandableItem,
  ExpandedDetails,
  ModalExpandHeadingContainer,
  ExpandHeadingLabel,
  ExpandIcon,
  LabelText,
  ModalSidebarContainer,
  ToolbarContainer,
  SidebarMessageContainer,
  InputsContainer,
  InputsHeading,
  IntegrationsSidebarContainer,
  ProposalsSidebarContainer,
} from '../../styles/library/sidebarStyles';
import {
  LoadingOverlay,
  LoadingOverlayContainer,
  ModalLoadingOverlayContainer,
} from '../../styles/library/modalStyles';
import { ErrorThemes, SuccessThemes } from '../../styles/themes';
import { assetMapColors, colors, fonts } from '../../styles/variables';
import {
  CapitalizeFirstLetter,
  DateFormat,
  GenerateAge,
  // GenerateAge,
  GenerateEntityLabel,
  HtmlToString,
  // TransformGender,
} from '../../utils';
import {
  FlexCenterAll,
  // FlexCenterAll,
  FlexCenterStart,
} from '../../styles/library/layoutStyles';
import {
  DetermineRoleBackground,
  DetermineRoleColor,
  GenerateAvatarMemberIcon,
} from '../../utils/avatars';

const RelationshipSidebar = ({
  isEdit,
  item,
  isLoading,
  modalTabOptions,
  setActiveTab,
  essentialInputs,
  detailInputs,
  notesInput,
  buttonContent,
  preferences,
  categories,
  household,
  integrationContent,
  error,
  members,
  allMemberCategories,
  isConsumer,
  proposalContent,
}) => {
  const dispatch = useDispatch();
  const { updatedMember, memberAction, usingNewAvatar } = useSelector(
    (state) => ({
      updatedMember: state.members.updatedMember,
      memberAction: state.members.memberAction,
      usingNewAvatar: state.user.usingNewAvatar,
    })
  );
  const isMember = item?.member_type === 'member';
  const tabOptions = [
    {
      value: 'essentials',
      label: 'Essentials',
      isExpanded: false,
    },
    {
      value: 'details',
      label: 'Details',
      isExpanded: false,
    },
    {
      value: 'notes',
      label: 'Notes',
      isExpanded: false,
    },
  ];
  const [inputGroups, setInputGroups] = useState(tabOptions);
  const [successMessage, setSuccessMessage] = useState('');

  useEffect(() => {
    if (updatedMember && memberAction) {
      if (memberAction !== 'read') {
        let typeDisplay = 'Member';
        let actionDisplay = 'Update';
        if (
          updatedMember?.member_type ||
          updatedMember?.full_object_data?.member_type
        ) {
          typeDisplay =
            updatedMember?.member_type ||
            updatedMember?.full_object_data?.member_type;
        }
        if (memberAction) {
          actionDisplay = memberAction;
        }

        typeDisplay = CapitalizeFirstLetter(typeDisplay);
        actionDisplay = CapitalizeFirstLetter(actionDisplay);
        setSuccessMessage(`${typeDisplay} ${actionDisplay}d`);
        dispatch(updateMemberStoreValue('memberAction', null));
        setTimeout(() => {
          dispatch(updateMemberStoreValue('updatedMember', false));
          setSuccessMessage('');
        }, 2500);
      }
    }
  }, [updatedMember && memberAction]);

  useEffect(() => {
    if (modalTabOptions) {
      const updatedGroups = modalTabOptions.map((option) => {
        if (item?.is_proposal) {
          option.isExpanded = option.value === 'proposals';
        } else {
          const isExpanded = localStorage.getItem(option.value);
          option.isExpanded = isExpanded === 'true';
          if (option.integration) {
            option.isExpanded = false;
          }
        }
        return option;
      });
      setInputGroups(updatedGroups);
    }
  }, [modalTabOptions]);

  const onExpandGroup = (section) => {
    if (section.integration && !section.isExpanded) {
      setActiveTab(section.value);
    }
    setInputGroups(
      inputGroups.map((group) => {
        if (group.value === section.value) {
          localStorage.setItem(group.value, !group.isExpanded);
          group.isExpanded = !group.isExpanded;
        } else if (group.integration && section.integration) {
          group.isExpanded = false;
        }
        return group;
      })
    );
  };

  const generateBottomPadding = () => {
    const hasError = error && error !== '';
    const isNew = !isEdit;
    let height = 0;
    const errorHeight = 38;
    if (isNew) {
      height = 25;
    }
    if (hasError) {
      height += errorHeight;
    }
    return `${height}px`;
  };

  const generateMemberRole = (member) => {
    let categoryDisplay = '';
    if (member) {
      categoryDisplay = member?.category;
      if (member?.description) {
        categoryDisplay = member.description;
      } else {
        if (allMemberCategories) {
          const matchedCategory = allMemberCategories.find(
            (cat) => cat.value === member.category
          );
          if (matchedCategory?.label) {
            categoryDisplay = matchedCategory?.label;
          }
        }
      }
    }
    return HtmlToString(categoryDisplay);
  };

  const transformEntity = (entity) => {
    if (entity) {
      if (entity.owners && entity.owners.length !== 0) {
        entity.owners = entity.owners.map((owner) => {
          let matched = undefined;
          if (members) {
            matched = members.find((mem) => mem.id === owner.id);
            if (matched !== undefined) {
              owner = { ...owner, ...matched };
            } else {
              owner = { ...owner, hide: true };
            }
          }
          return owner;
        });
      }
      return entity;
    }
  };

  const generateEntityMembers = (entity) => {
    const updatedEntity = transformEntity(entity);
    return updatedEntity?.owners && updatedEntity?.owners?.length !== 0 ? (
      updatedEntity?.owners.map((owner, index) => {
        return (
          !owner.hide && (
            <LinkedLabel key={index}>
              <span>{HtmlToString(owner.name)}</span>
              {owner?.member_type !== 'entity' && (
                <MemberAge
                  style={
                    owner?.date_of_birth_estimated
                      ? { fontStyle: 'italic' }
                      : null
                  }
                >
                  {owner?.age}
                  {owner?.date_of_birth_estimated && 'e'}
                </MemberAge>
              )}
            </LinkedLabel>
          )
        );
      })
    ) : (
      <EmptyText>None</EmptyText>
    );
  };

  return (
    <ModalSidebarContainer $paddingBottom={generateBottomPadding()}>
      {isEdit && item ? (
        <>
          <RelationshipHeadingContainer
            $borderColor={usingNewAvatar && DetermineRoleColor(item?.category)}
            $backgroundColor={
              usingNewAvatar && DetermineRoleBackground(item?.category)
            }
            // $borderColor={DetermineRoleColor(item?.category)}
          >
            <TopContent>
              <DetailsContainer>
                <RelationshipName>
                  {item?.name}
                  {item?.is_linked && (
                    <LinkedImage
                      src={link}
                      alt="linked"
                      data-image="item-linked"
                    />
                  )}
                </RelationshipName>
                <RelationshipRole>
                  {isMember
                    ? generateMemberRole(item)
                    : GenerateEntityLabel(item.category, categories)}
                </RelationshipRole>
                {isMember && (
                  <>
                    {/* <RelationshipGender>
                      <DetailsLabel>Gender</DetailsLabel>
                      {TransformGender(item.gender)}
                    </RelationshipGender> */}
                    {preferences &&
                      preferences?.amr?.member_subtitle !== 'none' && (
                        <RelationshipSubtitle
                          $isEstimated={item.date_of_birth_estimated}
                        >
                          {preferences?.amr?.member_subtitle === 'age' &&
                          preferences
                            ? GenerateAge(
                                item.date_of_birth,
                                preferences.date_format
                              )
                            : DateFormat(
                                item.date_of_birth,
                                preferences
                                  ? preferences?.date_format
                                  : 'MM/DD/YYYY'
                              )}
                          {item.date_of_birth_estimated && ' (e)'}
                        </RelationshipSubtitle>
                      )}
                    {/*<AgeContainer>
                     <RelationshipDOB>
                        <DetailsLabel>DOB</DetailsLabel>
                        {preferences !== undefined && item.date_of_birth
                          ? DateFormat(
                              item.date_of_birth,
                              preferences.date_format
                            )
                          : '-'}
                        {item.date_of_birth_estimated &&
                          item.date_of_birth &&
                          '(e)'}
                      </RelationshipDOB>
                      <RelationshipAge>
                        <DetailsLabel>Age</DetailsLabel>
                        {preferences !== undefined && item.date_of_birth
                          ? GenerateAge(
                              item.date_of_birth,
                              preferences.date_format
                            )
                          : '-'}
                      </RelationshipAge>
                    </AgeContainer>
                    */}
                  </>
                )}
              </DetailsContainer>
              {usingNewAvatar && (
                <AvatarContainer>
                  {GenerateAvatarMemberIcon(item)}
                </AvatarContainer>
              )}
            </TopContent>
            {!isMember && (
              <>
                <AltLabel>Linked Members</AltLabel>
                <LinkedMembersContainer>
                  {generateEntityMembers(item)}
                </LinkedMembersContainer>
              </>
            )}
          </RelationshipHeadingContainer>
          <ModalLoadingOverlayContainer>
            {isLoading && (
              <LoadingOverlayContainer>
                <LoadingOverlay>
                  <LoadingAnimation />
                </LoadingOverlay>
              </LoadingOverlayContainer>
            )}
            {inputGroups.map((group) => {
              return (
                !group.isHidden && (
                  <ExpandableItem key={group.value}>
                    <ModalExpandHeadingContainer
                      onClick={() => onExpandGroup(group)}
                      $isExpanded={group.isExpanded}
                    >
                      <ExpandHeadingLabel>
                        <LabelText>{group.label}</LabelText>
                      </ExpandHeadingLabel>
                      <ExpandIcon $isExpanded={group.isExpanded}>
                        <img src={chevronDownDark} alt="down" />
                      </ExpandIcon>
                    </ModalExpandHeadingContainer>
                    {group.isExpanded && (
                      <ExpandedDetails $padding="0">
                        {group.value === 'essentials' ? (
                          <InputsContainer>
                            {essentialInputs.map((input, index) => {
                              return GenerateInput(input, index);
                            })}
                          </InputsContainer>
                        ) : group.value === 'details' ? (
                          <InputsContainer>
                            {detailInputs.map((input, index) => {
                              return GenerateInput(input, index);
                            })}
                          </InputsContainer>
                        ) : group.value === 'notes' ? (
                          <InputsContainer $padding={'20px 15px 50px 15px'}>
                            {GenerateInput(notesInput, 0)}
                          </InputsContainer>
                        ) : group.value === 'connect' ? (
                          <ClientPortalContainer>
                            <HouseholdSharingModal
                              showModal={false}
                              isSidebar={true}
                              householdId={household?.id}
                              member={item}
                              hide={() => console.log('hide')}
                            />
                          </ClientPortalContainer>
                        ) : group.value === 'proposals' ? (
                          <ProposalsSidebarContainer>
                            <ProposalsTab
                              isSidebar={true}
                              isConsumer={false}
                              proposalContent={proposalContent}
                              hasError={error && error !== ''}
                            />
                          </ProposalsSidebarContainer>
                        ) : (
                          <IntegrationsSidebarContainer>
                            <IntegrationTab
                              isSidebar={true}
                              content={integrationContent?.content}
                              loading={integrationContent?.loading}
                              integration={integrationContent?.integration}
                              member={integrationContent?.member}
                              household={household}
                              role={integrationContent?.role}
                              onClick={integrationContent?.onClick}
                              onClickLoading={
                                integrationContent?.onClickLoading
                              }
                              error={integrationContent?.error}
                              type={integrationContent?.type}
                              lastHouseholdSync={
                                integrationContent?.lastHouseholdSync
                              }
                            />
                          </IntegrationsSidebarContainer>
                        )}
                      </ExpandedDetails>
                    )}
                  </ExpandableItem>
                )
              );
            })}
          </ModalLoadingOverlayContainer>
        </>
      ) : (
        <ExpandedDetails $padding="0">
          <InputsContainer $padding="15px">
            <InputsHeading>Essentials</InputsHeading>
            {essentialInputs.map((input, index) => {
              return GenerateInput(input, index);
            })}
          </InputsContainer>
          <InputsContainer
            $padding="15px"
            $background={colors.hoverLighterGrey}
            $showBorder={true}
          >
            <InputsHeading>Details</InputsHeading>
            {detailInputs.map((input, index) => {
              return GenerateInput(input, index);
            })}
          </InputsContainer>
          <InputsContainer $padding="15px 15px 0px 15px">
            <InputsHeading>Notes</InputsHeading>
            {GenerateInput(notesInput, 0)}
          </InputsContainer>
        </ExpandedDetails>
      )}
      {error && error !== '' && (
        <SidebarMessageContainer>
          <Error errorMessage={error} theme={ErrorThemes.sidebar} />
        </SidebarMessageContainer>
      )}
      {successMessage && successMessage !== '' && (
        <SidebarMessageContainer>
          <Success
            successMessage={successMessage}
            theme={SuccessThemes.sidebar}
          />
        </SidebarMessageContainer>
      )}
      <ToolbarContainer>
        <ModalButtons
          isNew={!isEdit}
          deleteFunction={buttonContent.deleteFunction}
          saveFunction={buttonContent.saveFunction}
          showLoading={buttonContent.showLoading}
          loadingText={buttonContent.loadingText}
          isSidebar={true}
        />
      </ToolbarContainer>
    </ModalSidebarContainer>
  );
};

const ClientPortalContainer = styled.div`
  padding: 20px;
  overflow-x: auto;
  max-width: 278px;
  min-height: 430px;
  ${InfoBoxContainer} {
    background: ${colors.white};
  }
`;

// const AgeContainer = styled.div`
//   ${FlexCenterStart};
// `;

const RelationshipHeadingContainer = styled.div`
  padding: 10px 15px 10px 15px;
  border-bottom: 5px solid;
  background: ${colors.white};
  border-color: ${(props) =>
    props.$borderColor ? props.$borderColor : colors.paleGrey};
  background-color: ${(props) => props.$backgroundColor};
  box-shadow: 0px 4px 10px -4px rgba(0, 0, 0, 0.1);
  z-index: 5;
  position: sticky;
  top: 0;
  flex-direction: column;
`;

const TopContent = styled.div`
  ${FlexCenterStart};
`;

const AvatarContainer = styled.div`
  flex: 0 0 auto;
  width: 35px;
  height: 35px;
  ${FlexCenterAll};
  svg {
    ${
      '' /* width: 40px;
    height: 40px;
    max-width: 33px;
    max-height: 33px; */
    }
    width: 100%;
    height: 100%;
  }
`;

const DetailsContainer = styled.div`
  ${'' /* margin-right: 15px; */}
  flex: 1 1 auto;
  ${'' /* max-width: 195px; */}
  word-break: break-word;
`;

// const DetailsLabel = styled.span`
//   margin-right: 5px;
//   font-size: 11px;
//   font-weight: ${fonts.light};
// `;

const RelationshipName = styled.p`
  font-size: 16px;
  font-weight: ${fonts.semiBold};
  line-height: 22px;
`;

const RelationshipRole = styled.p`
  font-size: 16px;
  line-height: 24px;
`;

const RelationshipSubtitle = styled.p`
  font-size: 15px;
  font-style: ${(props) => (props.$isEstimated ? 'italic' : null)};
  color: ${assetMapColors.expected};
`;

// const RelationshipGender = styled.p`
//   font-size: 15px;
//   line-height: 20px;
// `;

const LinkedMembersContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: 4px;
`;

const LinkedLabel = styled.span`
  font-size: 11px;
  display: ${FlexCenterStart};
  background: ${colors.lightGrey};
  margin-bottom: 3px;
  padding: 3px 5px;
  border-radius: 2px;
`;

const MemberAge = styled.span`
  margin-left: 4px;
  color: ${colors.paleGrey};
  font-size: 10px;
`;

const AltLabel = styled.span`
  margin-right: 5px;
  font-size: 11px;
  font-weight: ${fonts.semiBold};
`;

const EmptyText = styled.p`
  font-size: 12px;
  color: ${colors.paleGrey};
`;

const LinkedImage = styled.img`
  width: 10px;
  max-height: 10px;
  margin-left: 3px;
  opacity: 0.8;
`;

export default RelationshipSidebar;
