import styled from 'styled-components';
import PropTypes from 'prop-types';
import { Button } from '../';
import { colors, maxDevice, boxShadows, fonts } from '../../styles/variables';
import { GenerateAvatarMemberIcon } from '../../utils';
import { DetermineRoleColor } from '../../utils/avatars';

const MemberCard = ({ member, onEdit, onRemove }) => {
  return (
    <CardContainer>
      <TopContainer>
        <IconCardContainer $color={DetermineRoleColor(member?.role?.value)}>
          {GenerateAvatarMemberIcon(member)}
        </IconCardContainer>
        <RoleText $color={DetermineRoleColor(member?.role?.value)}>
          {member.role.label}
        </RoleText>
      </TopContainer>
      <BottomContainer>
        {member.isMember ? (
          <NameText>
            {member.first_name} {member.last_name}
          </NameText>
        ) : (
          <NameText>{member.name}</NameText>
        )}
        {member.age ? <AgeText>{member.age}</AgeText> : <AgeText></AgeText>}
      </BottomContainer>
      <ButtonCardContainer>
        {member.role.value !== 'primary' && (
          <>
            <Button text={'Remove'} onClick={() => onRemove(member)} />
            <span style={{ width: '20px' }}></span>
          </>
        )}
        <Button text={'Edit'} onClick={() => onEdit(member)} />
      </ButtonCardContainer>
    </CardContainer>
  );
};

const InputContainer = styled.div`
  @media ${maxDevice.sideNav} {
    min-width: 100%;
    flex: 1 1 auto;
    margin-bottom: 15px;
    max-width: 100%;
  }
  margin: 0 15px 0 0;
  display: flex;
  max-width: 400px;
  flex: 1 1 auto;
  input {
    padding: 9.5px 10px;
    flex: 1 1 auto;
  }
`;

const CardContainer = styled.div`
  @media ${maxDevice.sideNav} {
    width: 100%;
    margin-right: 0;
  }
  box-shadow: ${boxShadows.boxShadow};
  border-radius: 2px;
  padding: 20px 20px 20px 20px;
  background: white;
  min-width: 170px;
  width: 170px;
  height: 220px;
  margin-right: 15px;
  margin-bottom: 15px;
  display: flex;
  flex-direction: column;
  align-content: center;
  align-items: stretch;
  justify-content: center;
  ${InputContainer} {
    flex: 0 0 auto;
  }
  div {
    input {
      padding: 5px 10px;
      width: 100%;
      flex: 1 1 auto;
      border-radius: 5px;
    }
  }
`;

const IconCardContainer = styled.div`
  flex: 0 0 auto;
  width: 75px;
  height: 75px;
  border-radius: 100%;
  border: 2px solid ${(props) => props.$color};
  display: flex;
  align-content: center;
  align-items: center;
  justify-content: center;
  svg {
    width: 40px;
    height: 40px;
    max-width: 40px;
    max-height: 35px;
  }
`;

const TopContainer = styled.div`
  flex: 1 1 auto;
  display: flex;
  flex-direction: column;
  align-content: center;
  align-items: center;
  justify-content: center;
  border-bottom: 1px solid ${colors.lightGrey};
`;

const RoleText = styled.p`
  margin: 10px 0;
  font-weight: ${fonts.bold};
  text-transform: uppercase;
  font-size: 11px;
  text-align: center;
  color: ${(props) => props.$color};
`;

const NameText = styled.p`
  margin-top: 10px;
  font-weight: ${fonts.semiBold};
  font-size: 14px;
  text-align: center;
  text-overflow: ellipsis;
  overflow: hidden;
  max-width: 170px;
`;

const AgeText = styled.p`
  margin-top: 5px;
  margin-bottom: 10px;
  font-weight: ${fonts.semiBold};
  color: ${colors.paleGrey};
  font-size: 12px;
  text-align: center;
`;

const BottomContainer = styled.div`
  flex: 1 1 auto;
  display: flex;
  flex-direction: column;
  align-content: center;
  align-items: center;
  justify-content: center;
`;

const ButtonCardContainer = styled.div`
  display: flex;
  button {
    width: 100%;
    background: ${colors.paleGrey};
    padding: 7px;
    font-size: 11px;
  }
`;

MemberCard.propTypes = {
  member: PropTypes.object,
  onEdit: PropTypes.func,
  onRemove: PropTypes.func,
};

export default MemberCard;
