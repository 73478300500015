import { useState } from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import { TargetMapPercentCircle } from '../';
import {
  chevronRight,
  errorCircleRed,
  lineGraph,
  barChartHorizontal,
  chevronDownDark,
  chevronLeftDark,
} from '../../assets';
import * as styles from '../../styles/variables';
import { GenerateTargetMapFundingColor } from '../../utils';
import { Tooltip } from 'react-tippy';
import 'react-tippy/dist/tippy.css';
// import { LoadingPlaceholder } from '../../styles/library/fontStyles';
import { TooltipIconError } from '../../styles/library/inputStyles';
import {
  FlexCenterAll,
  FlexCenterEnd,
  FlexCenterStart,
} from '../../styles/library/layoutStyles';

const AltTargetMapHeading = ({
  targetMap,
  tab,
  error,
  updatePercentageValue,
}) => {
  const [showingGraphs, setShowingGraphs] = useState(true);
  const [showingPercentCircle, setShowingPercentCircle] = useState(true);
  const [activeGraph, setActiveGraph] = useState('bar');

  const fundingTypes = [
    {
      type: 'Cash Flow',
      color: styles.colors.lightBlue,
      value: targetMap?.income_funded,
    },
    {
      type: 'Asset',
      color: styles.colors.green,
      value: targetMap?.asset_funded,
    },
    {
      type: 'Insurance',
      color: styles.colors.blue,
      value: targetMap?.insurance_funded,
    },
  ];

  const generateSubtitle = (tab) => {
    switch (tab) {
      case 'want':
        return 'What you want in retirement: after tax spending';
      case 'have':
        return 'What you have: gross (pre-tax) income sources in retirement';
      case 'means':
        return 'Understanding what you want and what you need in retirement';
      case 'chart':
        return `Income sources and how they're allocated in retirement`;
      default:
        return tab;
    }
  };

  const generateErrorIcon = (input) => {
    return (
      <ToolTipContainer>
        <Tooltip
          title={input.errorMessage}
          position="top"
          trigger="mouseenter click"
          followCursor="true"
          delay={0}
          arrow={true}
          arrowSize={'small'}
          animation={'fade'}
          animateFill={false}
          transitionFlip={false}
          size={'small'}
        >
          <TooltipIconError
            src={errorCircleRed}
            alt="error"
            data-image={`tooltip-error-${input.name}`}
          />
        </Tooltip>
      </ToolTipContainer>
    );
  };

  const generatePercentChunk = ({ value, type, color }) => {
    return (
      <PercentageChunk $color={color} $width={value} key={type}>
        <Tooltip
          title={`${value}% ${type}`}
          position="top"
          trigger="mouseenter click"
          followCursor="true"
          delay={0}
          arrow={true}
          arrowSize={'small'}
          animation={'fade'}
          animateFill={false}
          transitionFlip={false}
          size={'small'}
        >
          <TooltipPlaceholder />
        </Tooltip>
      </PercentageChunk>
    );
  };

  const inputs = [
    {
      name: 'retirement_age',
      label: 'Retirement Age',
      type: 'number',
      value: 67,
    },
    {
      name: 'mortality_age',
      label: 'Mortality Age',
      type: 'number',
      value: 100,
    },
    {
      name: 'return_rate_retirement',
      label: 'Pre-Retirement Rate of return',
      type: 'percent',
      value: 8,
    },
    {
      name: 'return_rate',
      label: 'In-Retirement Rate of return',
      type: 'percent',
      value: 4,
    },
    {
      name: 'guardrail',
      label: 'Withdrawal Rate Guardrail',
      type: 'percent',
      value: 4,
    },
  ];

  return (
    <TargetMapContentContainer>
      <TargetMapChartContainer>
        <TopRowContent>
          <HeadingTitle>{generateSubtitle(tab)}</HeadingTitle>
          <ActionIcons>
            {showingGraphs && (
              <>
                <ActionIcon
                  $isActive={activeGraph === 'bar'}
                  onClick={() => setActiveGraph('bar')}
                  title="Percent Breakdown"
                >
                  <img src={barChartHorizontal} alt="percent-bar" />
                </ActionIcon>
                <ActionIcon
                  $isActive={activeGraph === 'line'}
                  onClick={() => setActiveGraph('line')}
                  title="Graph Breakdown"
                >
                  <img src={lineGraph} alt="line-graph" />
                </ActionIcon>
              </>
            )}
            <ActionIcon
              $isActive={true}
              $isExpanded={showingGraphs}
              onClick={() => setShowingGraphs(!showingGraphs)}
              title={showingGraphs ? 'Collapse' : 'Expand'}
            >
              <img src={chevronDownDark} alt="chevron" />
            </ActionIcon>
          </ActionIcons>
        </TopRowContent>
        {showingGraphs && (
          <ChartContentContainer>
            <ExpandAction>
              <ActionIcon
                $isActive={true}
                $isExpanded={!showingPercentCircle}
                onClick={() => setShowingPercentCircle(!showingPercentCircle)}
                title={showingPercentCircle ? 'Collapse' : 'Expand'}
              >
                <img src={chevronLeftDark} alt="chevron" />
              </ActionIcon>
            </ExpandAction>
            {showingPercentCircle && (
              <LeftContent>
                <TargetMapPercentCircle
                  targetMap={targetMap}
                  width={'130px'}
                  strokeWidth={16}
                />
                <KeyContainer>
                  <KeyOption>
                    <KeyColor
                      $color={GenerateTargetMapFundingColor(targetMap)}
                    />
                    Net Capital at Retirement
                  </KeyOption>
                  <KeyOption>
                    <KeyColor />
                    Funding {targetMap.funded >= 100 ? 'Surplus' : 'Gap'} at
                    Retirement
                  </KeyOption>
                </KeyContainer>
              </LeftContent>
            )}
            <RightContent>
              {activeGraph === 'bar' ? (
                <>
                  <FundingKeyContainer>
                    {fundingTypes.map((type) => {
                      return (
                        <KeyOption key={type.type}>
                          <KeyColor $color={type.color} />
                          {type.type}
                        </KeyOption>
                      );
                    })}
                  </FundingKeyContainer>
                  {!showingPercentCircle && (
                    <FundingPercent>{`${targetMap?.funded}%`}</FundingPercent>
                  )}
                  <TargetMapBarContainer>
                    <OverallPercentageBar />
                    {fundingTypes.map((type) => {
                      return generatePercentChunk(type);
                    })}
                  </TargetMapBarContainer>
                </>
              ) : activeGraph === 'line' ? (
                <div>Line Graph for {tab}</div>
              ) : (
                <div>Unknown Graph</div>
              )}
            </RightContent>
          </ChartContentContainer>
        )}
      </TargetMapChartContainer>
      <InputsContainer>
        {inputs.map((input) => {
          return (
            <TargetMapExtraInfoChunk key={input.name}>
              <ChunkValue>
                {error?.[input?.name] &&
                  generateErrorIcon({
                    name: input.name,
                    errorMessage: error[input?.name],
                  })}
                <PercentageInput
                  type="number"
                  $hasError={error?.[input?.name]}
                  value={input.value}
                  onChange={(e) => console.log('percent on change')}
                  onBlur={(e) => console.log('percent on blur')}
                />
                {input.type === 'percent' && (
                  <>
                    <span>%</span>
                    <ChevronContainer>
                      <ChevronUp
                        src={chevronRight}
                        alt="up"
                        data-image={`arrow-up-return`}
                        onClick={() => {
                          updatePercentageValue(
                            true,
                            console.log('up'),
                            'return_rate',
                            input.value
                          );
                        }}
                      />
                      <ChevronDown
                        src={chevronRight}
                        alt="down"
                        data-image={`arrow-down-return`}
                        onClick={() => {
                          updatePercentageValue(
                            false,
                            console.log('down'),
                            'return_rate',
                            input.value
                          );
                        }}
                      />
                    </ChevronContainer>
                  </>
                )}
              </ChunkValue>
              <ChunkLabel>{input.label}</ChunkLabel>
            </TargetMapExtraInfoChunk>
          );
        })}
      </InputsContainer>
    </TargetMapContentContainer>
  );
};

const TargetMapContentContainer = styled.div`
  border-radius: 6px;
  margin: 20px 30px;
  ${FlexCenterStart};
  flex-direction: column;
  align-items: stretch;
  z-index: 1;
`;

const TargetMapChartContainer = styled.div`
  box-shadow: ${styles.boxShadows.boxShadowSoft};
  flex: 1 1 auto;
  width: 100%;
  border-radius: 6px 6px 0 0;
  ${FlexCenterStart};
  flex-direction: column;
  align-items: stretch;
`;

const ChartContentContainer = styled.div`
  min-height: 180px;
  ${FlexCenterStart};
  align-items: stretch;
  padding: 20px 0;
  @media (max-width: 800px) {
    flex-wrap: wrap;
  }
`;

const LeftContent = styled.div`
  @media (max-width: 600px) {
    flex-wrap: wrap;
    flex: 1 1 auto;
    border: none;
  }
  ${FlexCenterAll};
  flex-direction: column;
  flex: 0 0 auto;
  padding: 0 30px 0 10px;
  border-right: 1px solid ${styles.colors.lightGrey};
`;

const KeyContainer = styled.div`
  margin-top: 15px;
`;

const KeyOption = styled.div`
  font-size: 10px;
  ${FlexCenterStart};
  line-height: 15px;
`;

const KeyColor = styled.div`
  width: 8px;
  height: 8px;
  background: ${(props) =>
    props.$color ? props.$color : styles.colors.lightGrey};
  margin-right: 5px;
`;

const FundingKeyContainer = styled.div`
  @media (max-width: 600px) {
    display: none;
  }
  position: absolute;
  top: 0;
  right: 30px;
  ${FlexCenterEnd};
  ${KeyOption} {
    line-height: auto;
    margin-left: 15px;
  }
`;

const RightContent = styled.div`
  @media (max-width: 600px) {
    flex-wrap: wrap;
    width: 100%;
    margin-top: 20px;
  }
  flex: 1 1 auto;
  ${FlexCenterAll};
  position: relative;
`;

const InputsContainer = styled.div`
  background: ${styles.colors.lightGrey};
  flex: 0 1 auto;
  border-radius: 0 0 6px 6px;
  padding: 10px 10px;
  display: flex;
  align-content: center;
  align-items: flex-start;
  justify-content: flex-start;
  flex-wrap: wrap;
`;

const TopRowContent = styled.div`
  border-bottom: 1px solid ${styles.colors.lightGrey};
  ${FlexCenterStart};
  padding: 8px 20px;
  flex-wrap: wrap;
`;

const HeadingTitle = styled.h3`
  flex: 1 0 auto;
  font-size: 13px;
  font-weight: ${styles.fonts.semiBold};
`;

const ActionIcons = styled.div`
  ${FlexCenterEnd};
`;

const ActionIcon = styled.button`
  padding: 0;
  ${FlexCenterAll};
  width: 35px;
  height: 35px;
  border-radius: 100%;
  opacity: ${(props) => (props.$isActive ? 1 : 0.5)};
  img {
    width: 15px;
    height: 15px;
    transform: ${(props) => (props.$isExpanded ? 'rotate(180deg)' : null)};
  }
  &:hover {
    background: ${styles.colors.hoverLighterGrey};
    transform: scale(1.05);
  }
`;

const ExpandAction = styled.div`
  @media (max-width: 600px) {
    display: none;
  }
  ${ActionIcon} {
    width: 25px;
    height: 25px;
    img {
      width: 12px;
      height: 12px;
    }
  }
  ${FlexCenterAll};
  padding: 0 5px;
`;

const TooltipPlaceholder = styled.div`
  height: 100%;
  width: 100%;
`;

const PercentageChunk = styled.div`
  background: ${(props) => props.$color};
  width: ${(props) => `${props.$width}%`};
  flex: 0 1 auto;
  height: 100%;
  /* display: none; */
  &:hover {
    opacity: 0.9;
  }
`;

const OverallPercentageBar = styled.div`
  flex: 0 1 auto;
  height: 100%;
  &:hover {
    opacity: 0.9;
  }
`;

const FundingPercent = styled.div`
  font-weight: ${styles.fonts.semiBold};
  font-size: 22px;
  margin: 0 -10px 0 10px;
`;

const TargetMapBarContainer = styled.div`
  margin: 0 30px;
  overflow: hidden;
  height: 35px;
  border-radius: 4px;
  width: 100%;
  ${FlexCenterStart};
  background: ${styles.colors.lightGrey};
  cursor: default;
  /* &:hover {
    ${OverallPercentageBar} {
      display: none;
    }
  }*/
`;

const TargetMapExtraInfoChunk = styled.div`
  @media ${styles.maxDevice.tablet} {
    text-align: center;
    width: 60px;
  }
  flex: 1 1 auto;
  text-align: left;
  margin: 5px 10px;
  width: 80px;
  border-right: 1px solid ${styles.colors.hoverLightGrey};
  &:first-child {
    margin-left: 0;
    @media ${styles.maxDevice.tablet} {
      margin-left: 10px;
    }
  }
  &:last-child {
    margin-right: 0;
    @media ${styles.maxDevice.tablet} {
      margin-right: 10px;
    }
    border-right: none;
  }
`;

const ChunkValue = styled.div`
  @media ${styles.maxDevice.tablet} {
    font-size: 14px;
    justify-content: center;
  }
  font-size: 18px;
  font-weight: ${styles.fonts.semiBold};
  margin-bottom: 5px;
  display: flex;
  align-content: center;
  align-items: center;
  justify-content: center;
  input::-webkit-outer-spin-button,
  input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }
  input[type='number'] {
    -moz-appearance: textfield;
  }
`;

const ChunkLabel = styled.div`
  @media ${styles.maxDevice.laptop} {
    font-size: 10px;
  }
  @media ${styles.maxDevice.tablet} {
    font-size: 9px;
    text-align: center;
  }
  font-size: 11px;
  text-align: center;
  color: ${styles.colors.darkGrey};
  font-weight: ${styles.fonts.semiBold};
`;

const PercentageInput = styled.input`
  border: 1px solid ${styles.inputColors.border};
  color: ${styles.colors.darkGrey};
  width: 50px;
  font-size: 18px;
  font-weight: ${styles.fonts.semiBold};
  text-align: center;
  border-radius: 3px;
  flex: 0 0 auto;
  margin-right: 3px;
  ${({ $hasError }) =>
    $hasError &&
    `
    border: 1px solid ${styles.messageColors.error}
  `}
`;

const ChevronContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-content: center;
  align-items: center;
  justify-content: center;
  margin-left: 8px;
`;

const ChevronUp = styled.img`
  width: 11px;
  height: 11px;
  transform: rotate(-90deg);
  cursor: pointer;
  opacity: 0.5;
  &:hover {
    opacity: 1;
  }
`;

const ChevronDown = styled(ChevronUp)`
  transform: rotate(90deg);
`;

const ToolTipContainer = styled.div`
  position: relative;
  ${TooltipIconError} {
    width: 13px;
    height: 13px;
    position: absolute;
    right: 5px;
    top: -5px;
  }
`;

AltTargetMapHeading.propTypes = {
  targetMap: PropTypes.object,
  tab: PropTypes.string,
  currency: PropTypes.object,
  returnRate: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  setReturnRate: PropTypes.func,
  inflationRate: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  setInflationRate: PropTypes.func,
  duration: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  setDuration: PropTypes.func,
  setPercentageBlur: PropTypes.func,
  updatePercentageValue: PropTypes.func,
  setYearBlur: PropTypes.func,
  resetDuration: PropTypes.func,
  error: PropTypes.object,
};

export default AltTargetMapHeading;
