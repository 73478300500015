import { memo, useEffect } from 'react';
import PropTypes from 'prop-types';
import { Handle, Position, useReactFlow } from 'reactflow';
import styled from 'styled-components';
import { boxShadows, colors } from '../../styles/variables';
import {
  FlexCenterAll,
  NodeHandleStyle,
} from '../../styles/library/layoutStyles';
import { plus } from '../../assets';

const JoinNode = ({ data }) => {
  const { setNodes } = useReactFlow();

  useEffect(() => {
    if (data.isNew) {
      setNodes((nodes) =>
        [...nodes].map((value) => {
          const node = { ...value };
          if (node.id === data.id) {
            delete node.data.isNew;
          }
          return node;
        })
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data?.isNew]);

  return (
    <>
      {/* <NodeToolbar
        isVisible={data.toolbarVisible}
        position={data.toolbarPosition}
      >
        <button onClick={() => data.onCopy(data)}>copy</button>
      </NodeToolbar> */}
      <Handle
        type="source"
        position={Position.Top}
        style={NodeHandleStyle}
        id="n"
      />
      <Handle
        type="source"
        position={Position.Right}
        style={NodeHandleStyle}
        id="e"
      />
      <Handle
        type="source"
        position={Position.Left}
        style={NodeHandleStyle}
        id="w"
      />
      <Handle
        type="source"
        position={Position.Bottom}
        style={NodeHandleStyle}
        id="s"
      />
      <JoinNodeContainer $isNew={data.isNew}>
        <img src={plus} alt={data.label} />
      </JoinNodeContainer>
    </>
  );
};

const JoinNodeContainer = styled.div`
  border: 1px solid ${colors.mapGrey};
  border-radius: 5px;
  box-shadow: ${boxShadows.boxShadow};
  ${FlexCenterAll}
  width: 54px;
  height: 54px;
  border-radius: 100%;
  font-size: 12px;
  color: ${colors.darkGrey};
  text-align: left;
  background: ${(props) => (props.$isNew ? colors.paleBlue : colors.white)};
  img,
  svg {
    width: 18px;
    height: 18px;
    opacity: 0.5;
    flex: 0 0 auto;
  }
`;

JoinNode.propTypes = {
  data: PropTypes.object,
};

export default memo(JoinNode);
