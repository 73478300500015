/* eslint-disable array-callback-return */
/* eslint-disable react-hooks/exhaustive-deps */
import { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import {
  PageHeader,
  ShazamBar,
  SmallTable,
  EmptyImage,
  PageFooter,
} from '../../components';
import { checkboxGreen, square, noReports } from '../../assets';
import { PageContent } from '../../styles/library/layoutStyles';
import {
  UpdatePageTitle,
  headerContent,
  DownloadReport,
  FilterInstruments,
  HtmlToString,
  SendTrackingEvent,
  FilterMembersByContext,
  allMembersOption,
} from '../../utils';

const HouseholdPolicySummary = ({ isConsumer, canEdit = true, showPDF }) => {
  UpdatePageTitle('Policy Summary');
  const {
    currentHousehold,
    loadedUser,
    hasLegal,
    instruments,
    householdLegals,
    members,
    loadedHouseholdFields,
    customLDD,
    isStencil,
    usingNewAvatar,
    invocationId,
  } = useSelector((state) => ({
    currentHousehold: state.households.currentHousehold,
    loadedUser: state.user.loadedUser,
    hasLegal: state.user.hasLegal,
    instruments: state.households.currentHousehold.instruments,
    householdLegals: state.households.currentHousehold.householdlegals,
    members: state.households.currentHousehold.allHouseholdMembers,
    loadedHouseholdFields: state.configs.loadedHouseholdFields,
    customLDD: state.user.customLDD,
    isStencil: state.households.isStencil,
    usingNewAvatar: state.user.usingNewAvatar,
    invocationId: state.configs.invocationId,
  }));
  const [membersList, setMembersList] = useState([]);
  const [loadedMembers, setLoadedMembers] = useState(false);
  const [selectedMembers, setSelectedMembers] = useState([]);
  const [insurances, setInsurances] = useState([]);
  const [legals, setLegals] = useState([]);
  const [loadedInsurance, setLoadedInsurance] = useState(false);
  const [selectedInsurances, setSelectedInsurances] = useState([]);
  const [selectedLifeInsurance, setSelectedLifeInsurance] = useState([]);
  const [selectedDisability, setSelectedDisability] = useState([]);
  const [selectedLTC, setSelectedLTC] = useState([]);
  const [selectedPC, setSelectedPC] = useState([]);
  const [selectedMedical, setSelectedMedical] = useState([]);
  const [selectedOther, setSelectedOther] = useState([]);
  const [selectedLegal, setSelectedLegal] = useState([]);
  const [insuranceTypes, setInsuranceTypes] = useState([
    {
      label: 'Life Insurance',
      value: 'life',
      checked: true,
    },
    {
      label: 'Disability Insurance',
      value: 'disability',
      checked: true,
    },
    {
      label: 'Long-term Care Insurance',
      value: 'ltc',
      checked: true,
    },
    {
      label: 'Property & Casualty',
      value: 'propcas',
      checked: true,
    },
    {
      label: 'Medical',
      value: 'medical',
      checked: true,
    },
    {
      label: 'Other',
      value: 'otherinsurance',
      checked: true,
    },
    {
      label: 'Legal',
      value: 'legal',
      checked: true,
      isHidden: true,
    },
  ]);
  const selectedCategories = ['primary', 'spouse', 'partner'];
  const lifeInsuranceCategories = [
    'lifeterm',
    'lifewhole',
    'lifeul',
    'lifevul',
  ];

  const tables = [
    {
      heading: 'Life Insurance',
      type: 'life_insurance',
      value: 'life',
      items: selectedLifeInsurance,
    },
    {
      heading: 'Disability Insurance',
      type: 'disability',
      value: 'disability',
      items: selectedDisability,
    },
    {
      heading: 'Long-term Care Insurance',
      type: 'ltc',
      value: 'ltc',
      items: selectedLTC,
    },
    {
      heading: 'Property and Casualty Insurance',
      type: 'p&c',
      value: 'propcas',
      items: selectedPC,
    },
    {
      heading: 'Medical Insurance',
      type: 'medical',
      value: 'medical',
      items: selectedMedical,
    },
    {
      heading: 'Other Insurance',
      type: 'other_insurance',
      value: 'otherinsurance',
      items: selectedOther,
    },
    {
      heading: 'Legal',
      type: 'legal_policy',
      value: 'legal',
      items: selectedLegal,
    },
  ];

  const sharedHeadings = [
    { text: 'Reference Name', display: true, width: '6%' },
    { text: 'Carrier', display: true, width: '6%' },
    { text: 'Policy Date', display: true, width: '6%', align: 'center' },
    { text: 'Nickname', display: true, width: '6%', align: 'left' },
    { text: 'Insured', display: true, width: '10%' },
    { text: 'Owner', display: true, width: '10%' },
  ];
  const sharedHeadings2 = [
    {
      text: 'Curr Premium',
      value: 'premium',
      display: true,
      width: '6%',
      align: 'right',
      padding: '5px 10px 5px 0',
      showTotal: true,
    },
    {
      text: 'Prem Mode',
      value: 'prem_mode',
      display: true,
      width: '6%',
      align: 'left',
      showTotal: true,
    },
    { text: 'Data Collected', display: true, width: '6%', align: 'center' },
  ];

  const medicalOtherHeadings = [
    { text: 'Reference Name', display: true },
    { text: 'Carrier', display: true },
    { text: 'Policy Date', display: true, align: 'center' },
    { text: 'Insured', display: true },
    {
      text: 'Curr Premium',
      value: 'premium',
      display: true,
      align: 'right',
      padding: '5px 10px 5px 0',
      showTotal: true,
    },
    {
      text: 'Prem Mode',
      value: 'prem_mode',
      display: true,
      align: 'left',
      showTotal: true,
    },
    { text: 'Data Collected', display: true, align: 'center' },
    {
      text: 'Deductible',
      display: true,
      align: 'right',
    },
    {
      text: 'Benefit',
      display: true,
      align: 'right',
      showTotal: true,
      value: 'amount',
      // totalAppend:
      //   type === 'disability' ? '/mo' : type === 'ltc' ? '/dy' : null,
    },
  ];

  const legalHeadings = [
    { text: 'Reference Name', display: true },
    { text: 'Location', display: true },
    { text: 'Executed Date', display: true, align: 'center' },
    { text: 'Type', display: true },
    { text: 'Applicable Members', display: true },
    { text: 'Empowered Members', display: true },
    { text: 'Primary Beneficiary', display: true },
    { text: 'Data Collected', display: true, align: 'right' },
  ];

  useEffect(() => {
    if (loadedUser) {
      setInsuranceTypes(
        insuranceTypes.map((insurance) => {
          if (insurance.value === 'legal') {
            insurance.isHidden = !hasLegal;
          }
          return insurance;
        })
      );
    }
  }, [loadedUser]);

  useEffect(() => {
    if (members) {
      const allowedMembers = FilterMembersByContext(members, 'shazaam');
      let updated = [...allowedMembers].map((member) => {
        member.is_selected = false;
        member.name = HtmlToString(member.name);
        if (selectedCategories.includes(member.category)) {
          member.is_selected = true;
        }
        return member;
      });
      setMembersList([allMembersOption, ...updated]);
      setLoadedMembers(true);
    }
  }, [members]);

  useEffect(() => {
    if (membersList) {
      let selectedMembersList = membersList.filter((member) => {
        return member.is_selected;
      });
      setSelectedMembers(selectedMembersList);
    }
  }, [membersList]);

  useEffect(() => {
    if (instruments && loadedHouseholdFields) {
      mapMembers(instruments);
      const insuranceList = FilterInstruments(instruments, 'insurance');
      setInsurances(
        insuranceList.filter((insurance) => insurance.status === 'current')
      );
      const legalsList = mapLegalMembers(householdLegals);
      setLegals(legalsList);
      setLoadedInsurance(true);
    }
  }, [instruments, loadedHouseholdFields]);

  useEffect(() => {
    if (legals && legals.length !== 0 && selectedMembers) {
      let matchedLegals = filterMemberLegals(legals);
      setSelectedLegal(matchedLegals);
    }
  }, [legals, selectedMembers]);

  useEffect(() => {
    if (insurances && insurances.length !== 0 && selectedMembers) {
      let matchedInsurances = filterMemberInstruments(insurances);
      setSelectedInsurances(matchedInsurances);
    }
  }, [insurances, selectedMembers]);

  useEffect(() => {
    if (insurances && insurances.length !== 0 && selectedMembers) {
      setSelectedLifeInsurance(
        selectedInsurances.filter((insurance) =>
          lifeInsuranceCategories.includes(insurance.category)
        )
      );
      setSelectedDisability(
        selectedInsurances.filter(
          (insurance) => insurance.category === 'disability'
        )
      );
      setSelectedLTC(
        selectedInsurances.filter((insurance) => insurance.category === 'ltc')
      );
      setSelectedMedical(
        selectedInsurances.filter(
          (insurance) => insurance.category === 'medical'
        )
      );
      setSelectedOther(
        selectedInsurances.filter(
          (insurance) => insurance.category === 'otherinsurance'
        )
      );
      const matched = selectedInsurances.filter(
        (insurance) => insurance.category === 'propcas'
      );
      const mapped = matched.map((insurance) => {
        let assetList = [];
        insurance.assets = insurance.assets.map((asset) => {
          assetList.push(
            instruments.find((instrument) => instrument.id === asset)
          );
          return asset;
        });
        insurance.assetList = assetList;
        return insurance;
      });
      setSelectedPC(mapped);
    }
  }, [selectedInsurances, selectedMembers]);

  const mapMembers = (instrumentList) => {
    return instrumentList.map((instrument) => {
      if (instrument?.members && instrument?.members?.length !== 0) {
        instrument.members = addMemberDetails(instrument.members, 'id');
      }
      return instrument;
    });
  };

  const addMemberDetails = (membersList, matchKey = 'uuid') => {
    return membersList.map((member) => {
      let matched = members?.find(
        (mem) => mem?.[matchKey] === member?.[matchKey]
      );
      if (matched !== undefined) {
        member.member = matched;
      } else {
        member.member = { hide: true };
      }
      return member;
    });
  };

  const mapLegalMembers = (legalsList) => {
    return (
      legalsList?.length &&
      [...legalsList].map((legal) => {
        const copiedLegal = { ...legal };
        copiedLegal.applicable_members = addMemberDetails(
          legal?.applicable_members
        );
        copiedLegal.empowered_members = addMemberDetails(
          legal?.empowered_members
        );
        copiedLegal.beneficiary_members = addMemberDetails(
          legal?.beneficiary_members
        );
        return copiedLegal;
      })
    );
  };

  const filterMemberLegals = (legalsList) => {
    let selectedIds = selectedMembers.map((mem) => mem.id);
    return legalsList.reduce((acc, legal) => {
      const applicable_members = legal.applicable_members.map(
        (mem) => mem?.member?.id
      );
      if (applicable_members.some((item) => selectedIds.includes(item))) {
        return [...acc, legal];
      }
      return acc;
    }, []);
  };

  const filterMemberInstruments = (instrumentList) => {
    let selectedIds = selectedMembers.map((mem) => mem.id);
    return instrumentList.filter((instrument) => {
      const membersArr = instrument.members.map((member) => {
        if (member.relation === 'insured') {
          return member.id;
        }
      });
      if (selectedIds && selectedIds.length !== 0) {
        if (membersArr.some((item) => selectedIds.includes(item))) {
          return instrument;
        }
      } else {
        if (instrument.members.length === 0) {
          return instrument;
        }
      }
    });
  };

  const selectMember = (clickedMember) => {
    if (clickedMember.id === 'all') {
      return setMembersList(
        membersList.map((member) => {
          if (member.id === clickedMember.id) {
            member.is_selected = !member.is_selected;
          } else {
            if (member.category === 'primary') {
              member.is_selected = true;
            } else {
              member.is_selected = clickedMember.is_selected;
            }
          }
          return member;
        })
      );
    }
    const pureMembers = selectedMembers.filter(
      (mem) => mem.member_type !== 'all'
    );
    if (pureMembers.length <= 1 && pureMembers.includes(clickedMember)) {
      return;
    }
    setMembersList(
      membersList.map((member) => {
        if (member.id === 'all') {
          member.is_selected = false;
        }
        if (member.id === clickedMember.id) {
          member.is_selected = !member.is_selected;
        }
        return member;
      })
    );
  };

  const generateColumns = (type) => {
    if (type === 'medical' || type === 'other_insurance') {
      return medicalOtherHeadings;
    }
    if (type === 'legal_policy') {
      return legalHeadings;
    }
    const headings = [
      ...sharedHeadings,
      type !== 'p&c'
        ? { text: 'Primary Beneficiary', display: true, width: '10%' }
        : { text: 'Linked Asset', display: true, width: '10%' },
      type !== 'p&c'
        ? { text: 'Contingent Beneficiary', display: true, width: '10%' }
        : null,
      type !== 'p&c'
        ? { text: 'Policy Type', display: true, width: '6%' }
        : { text: 'Linked Member', display: true, width: '10%' },
      ...sharedHeadings2,
      type === 'life_insurance'
        ? {
            text: 'Acc. Value',
            value: 'cash_value',
            display: true,
            width: '6%',
            align: 'right',
            showTotal: true,
          }
        : type !== 'p&c'
          ? {
              text: 'Elimination Period',
              display: true,
              width: '6%',
              align: 'right',
            }
          : null,
      type === 'life_insurance'
        ? {
            text: 'Cash Surr. Value',
            value: 'surrender_value',
            display: true,
            width: '6%',
            align: 'right',
            showTotal: true,
          }
        : type !== 'p&c'
          ? {
              text: 'Benefit Period',
              display: true,
              width: '6%',
              align: 'right',
            }
          : null,
      type !== 'p&c'
        ? {
            text:
              type === 'life_insurance'
                ? 'Face Amount'
                : type === 'disability' || type === 'ltc'
                  ? 'Benefit'
                  : null,
            value:
              type === 'life_insurance'
                ? 'amount'
                : type === 'disability'
                  ? 'disability_total'
                  : type === 'ltc'
                    ? 'ltc_total'
                    : null,
            display: true,
            width: '9%',
            align: 'right',
            showTotal: true,
            totalAppend:
              type === 'disability' ? '/mo' : type === 'ltc' ? '/dy' : null,
          }
        : {
            text: 'Coverage Amount',
            value: 'amount',
            display: true,
            width: '12%',
            align: 'right',
            showTotal: true,
          },
    ];
    return headings.filter((heading) => heading !== null);
  };

  const toggleSelected = (type) => {
    setInsuranceTypes(
      insuranceTypes.map((insurance) => {
        if (insurance.value === type.value) {
          insurance.checked = !insurance.checked;
        }
        return insurance;
      })
    );
  };

  const isChecked = (value) => {
    const matched = insuranceTypes.find((type) => type.value === value);
    return matched?.checked && !matched?.isHidden;
  };

  const onClickPDF = () => {
    const children = selectedMembers.reduce((acc, curr) => {
      if (curr.id !== 'all') {
        return [
          ...acc,
          {
            id: curr.id,
            selected: true,
          },
        ];
      } else {
        return acc;
      }
    }, []);
    const types = insuranceTypes.reduce((acc, curr) => {
      if (curr.checked) {
        return [
          ...acc,
          {
            selected: true,
            value: curr.value,
          },
        ];
      }
      return acc;
    }, []);
    SendTrackingEvent(
      invocationId,
      'click',
      'create_pdf_report',
      'household_policy_summary',
      {
        isConsumer: isConsumer,
      }
    );
    return DownloadReport({
      value: 'policysummary',
      childValue: 'policysummary_members',
      hasOptions: true,
      children,
      types,
    });
  };

  return (
    <PageContainer>
      <PageHeader
        content={{ ...headerContent.policy, showButton: !isStencil && showPDF }}
        buttonFunction={onClickPDF}
      />
      {isStencil ? (
        <>
          <EmptyImage
            imageSrc={noReports}
            imageName="No Reports"
            text="Reports are not available for Stencil Households"
          />
        </>
      ) : (
        <PageContent>
          <ShazamBar
            items={membersList}
            onClick={selectMember}
            loaded={loadedMembers}
            isNew={usingNewAvatar}
          />
          <InsuranceTypeContainer>
            {insuranceTypes.map((type, index) => {
              return (
                !type.isHidden && (
                  <OptionValue
                    key={index}
                    onClick={() => {
                      toggleSelected(type);
                    }}
                  >
                    <CheckboxItem
                      src={type.checked ? checkboxGreen : square}
                      alt="box"
                      style={type.checked ? { opacity: 1 } : { opacity: 0.4 }}
                      data-image={`checkbox-${
                        type.checked ? 'checked' : 'unchecked'
                      }`}
                    />
                    <OptionLabel>{type.label}</OptionLabel>
                  </OptionValue>
                )
              );
            })}
          </InsuranceTypeContainer>
          <ContentContainer>
            {tables.map((table) => {
              return (
                isChecked(table.value) && (
                  <SmallTable
                    key={table.value}
                    tableHeading={table.heading}
                    columnHeadings={generateColumns(table.type)}
                    type={table.type}
                    items={table.items}
                    loaded={loadedInsurance}
                    showTotals={true}
                    marginBottom={'40px'}
                  />
                )
              );
            })}
          </ContentContainer>
          {isConsumer ? (
            <PageFooter footerValue={currentHousehold?.disclaimer} />
          ) : (
            customLDD?.disclaimer && (
              <PageFooter footerValue={customLDD?.disclaimer} />
            )
          )}
        </PageContent>
      )}
    </PageContainer>
  );
};

const CheckboxItem = styled.img`
  width: 15px;
  height: 15px;
  flex: 0 0 auto;
  &:hover {
    transform: scale(1.01);
    opacity: 0.8;
  }
`;

const OptionLabel = styled.div`
  flex: 1 1 auto;
  margin-left: 8px;
`;

const OptionValue = styled.div`
  flex: 0 0 auto;
  margin: 0 10px 5px 10px;
  text-align: center;
  display: flex;
  align-content: center;
  align-items: center;
  justify-content: center;
  cursor: pointer;
`;

const PageContainer = styled.div`
  flex: 1 1 auto;
  overflow-y: auto;
`;

const InsuranceTypeContainer = styled.div`
  margin: 15px 0 10px 0;
  font-size: 13px;
  display: flex;
  flex-wrap: wrap;
  align-content: center;
  align-items: center;
  justify-content: center;
`;

const ContentContainer = styled.div`
  margin: 15px 0 100px 0;
`;

HouseholdPolicySummary.propTypes = {
  isConsumer: PropTypes.bool,
  canEdit: PropTypes.bool,
  hasPDF: PropTypes.bool,
};

export default HouseholdPolicySummary;
