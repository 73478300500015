/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import styled from 'styled-components';
import { useHotkeys } from 'react-hotkeys-hook';
import PropTypes from 'prop-types';
import {
  PageHeader,
  SmallTable,
  ContextMenu,
  BulkActions,
  PageFooter,
  ShazamBar,
} from '../../components';
import {
  toggleShowDeleteModal,
  toggleShowAssetModal,
  toggleShowCashFlowModal,
  toggleShowLiabilityModal,
  toggleShowInsuranceModal,
  toggleShowLegalModal,
  getInstrumentInfo,
  toggleShowBulkModal,
  toggleShowHiddenFinancials,
  toggleShowMultiSelectFinancials,
  toggleShowCashValueColumn,
  clearInstrumentInfo,
  getLegal,
} from '../../store/actions';
import {
  lightBlueCirclePlus,
  orangeCirclePlus,
  blueCirclePlus,
  pinkCirclePlus,
  hide,
  trashDark,
  copy,
  eyeClosedDark,
  eyeDark,
  search,
} from '../../assets';
import { PageContent } from '../../styles/library/layoutStyles';
import {
  UpdatePageTitle,
  headerContent,
  DownloadReport,
  HtmlToString,
  InfoNotification,
  SetObjectFieldValue,
  SendTrackingEvent,
  FilterMembersByContext,
  allMembersOption,
} from '../../utils';
import { FilterMemberInstruments } from '../../utils/assetmap/filters';

const HouseholdFinancials = ({ isConsumer, canEdit = true, showPDF }) => {
  UpdatePageTitle('Financials');
  const {
    instruments,
    loadedHouseholdFields,
    currentHousehold,
    members,
    showHiddenFinancials,
    showCashValueColumn,
    showMultiSelectFinancials,
    sideNavWidth,
    hasContextMenuEnabled,
    hasLegal,
    legals,
    usingNewAvatar,
    invocationId,
  } = useSelector((state) => ({
    instruments: state.households.currentHousehold.instruments,
    loadedHouseholdFields: state.configs.loadedHouseholdFields,
    currentHousehold: state.households.currentHousehold,
    members: state.households.currentHousehold.allHouseholdMembers,
    showHiddenFinancials: state.user.showHiddenFinancials,
    showCashValueColumn: state.user.showCashValueColumn,
    showMultiSelectFinancials: state.user.showMultiSelectFinancials,
    sideNavWidth: state.user.sideNavWidth,
    hasContextMenuEnabled: state.user.hasContextMenuEnabled,
    hasLegal: state.user.hasLegal,
    legals: state.households.currentHousehold.householdlegals,
    usingNewAvatar: state.user.usingNewAvatar,
    invocationId: state.configs.invocationId,
  }));
  const dispatch = useDispatch();
  const [membersList, setMembersList] = useState([]);
  const [loadedMembers, setLoadedMembers] = useState(false);
  const [selectedMembers, setSelectedMembers] = useState([]);
  const [instrumentsList, setInstrumentsList] = useState();
  const [visibleInstruments, setVisibleInstruments] = useState([]);
  const [assets, setAssets] = useState([]);
  const [cashFlow, setCashFlow] = useState([]);
  const [liabilities, setLiabilities] = useState([]);
  const [insurance, setInsurance] = useState([]);
  const [legal, setLegal] = useState([]);
  const [loadedInstruments, setLoadedInstruments] = useState(false);
  const [loadedLegals, setLoadedLegals] = useState(false);
  const [showContextMenu, setShowContextMenu] = useState(false);
  const [selectedInstruments, setSelectedInstruments] = useState([]);
  const [showSearchFinancials, setShowSearchFinancials] = useState(false);
  const [searchValue, setSearchValue] = useState('');

  const filterInstruments = (
    instruments,
    type,
    showHidden = false,
    selectedMembers
  ) => {
    const updatedInstruments = instruments.filter((instrument) => {
      if (showHidden) {
        return instrument.instrument_type === type;
      } else {
        return instrument.instrument_type === type && !instrument.is_hidden;
      }
    });
    const pureMembersList = membersList.filter(
      (mem) => mem.member_type !== 'all'
    );
    const pureSelectedMembers = selectedMembers.filter(
      (mem) => mem.member_type !== 'all'
    );
    const allMembersSelected =
      pureMembersList?.length === pureSelectedMembers?.length;
    if (allMembersSelected) {
      return updatedInstruments;
    } else {
      return FilterMemberInstruments(
        selectedMembers,
        updatedInstruments,
        type === 'insurance' ? 'insured' : 'owner'
      );
    }
  };

  const mapMembers = (instrumentList, relation = 'owner') => {
    let copied = instrumentList.map((item) => {
      return { ...item };
    });
    return copied.map((instrument) => {
      if (instrument.members && instrument.members.length !== 0) {
        let filtered = instrument.members.filter(
          (v, i, a) =>
            a.findIndex((t) => t.relation === relation && t.id === v.id) === i
        );
        instrument.members = filtered.map((member) => {
          let matched = members.find((mem) => mem.id === member.id);
          if (matched !== undefined) {
            member.member = matched;
            member.member.name = HtmlToString(matched.name);
          } else {
            member.member = { hide: true };
          }
          return member;
        });
      }
      return instrument;
    });
  };

  const generateInsuranceMember = (instrumentList, assetsList) => {
    let copied = instrumentList.map((item) => {
      return { ...item };
    });
    return copied.map((instrument) => {
      if (instrument.members && instrument.members.length !== 0) {
        let filtered = instrument.members.filter(
          (v, i, a) =>
            a.findIndex((t) => t.relation === 'insured' && t.id === v.id) === i
        );
        const convertedMembers = filtered.map((member) => {
          let matched = members.find((mem) => mem.id === member.id);
          if (matched !== undefined) {
            member.member = matched;
            member.member.name = HtmlToString(matched.name);
          } else {
            member.member = { hide: true };
          }
          return member;
        });
        const convertedAssets = instrument.assets.map((asset) => {
          const matchedAsset = assetsList.find((item) => item.id === asset);
          let member = {};
          member.member = {};
          if (matchedAsset) {
            member.member.name = matchedAsset.name;
          }
          return member;
        });
        instrument.members = [...convertedMembers, ...convertedAssets];
      }
      return instrument;
    });
  };

  useEffect(() => {
    if (members && !loadedMembers) {
      const allowedMembers = FilterMembersByContext(members, 'shazaam');
      let updated = [...allowedMembers].map((member) => {
        member.is_selected = false;
        member.name = HtmlToString(member.name);
        member.is_selected = true;

        return member;
      });
      setMembersList([{ ...allMembersOption, is_selected: true }, ...updated]);
      setLoadedMembers(true);
    }
  }, [members]);

  useEffect(() => {
    if (membersList && loadedMembers) {
      let selectedMembersList = membersList.filter((member) => {
        return member.is_selected;
      });
      setSelectedMembers(selectedMembersList);
    }
  }, [membersList]);

  useEffect(() => {
    if (instruments && loadedHouseholdFields) {
      setSelectedInstruments([]);
      setInstrumentsList(instruments);
      dispatch(toggleShowMultiSelectFinancials(true));
      return () => {
        setSelectedInstruments([]);
        setInstrumentsList(instruments.map((i) => (i.is_selected = false)));
      };
    }
  }, [instruments, loadedHouseholdFields]);

  useEffect(() => {
    if (instrumentsList && loadedMembers && selectedMembers.length) {
      //@TODO NEED TO FILTER BASED OFF SELECTED MEMBERS
      const cashFlow = filterInstruments(
        instrumentsList,
        'income',
        showHiddenFinancials,
        selectedMembers
      );
      const updatedCashFlows = mapMembers(cashFlow);
      setCashFlow(updatedCashFlows);

      const assets = filterInstruments(
        instrumentsList,
        'asset',
        showHiddenFinancials,
        selectedMembers
      );
      const updatedAssets = mapMembers(assets);
      setAssets(updatedAssets);

      const liabilities = filterInstruments(
        instrumentsList,
        'liability',
        showHiddenFinancials,
        selectedMembers
      );
      const updatedLiabilities = mapMembers(liabilities);
      setLiabilities(updatedLiabilities);

      const insurance = filterInstruments(
        instrumentsList,
        'insurance',
        showHiddenFinancials,
        selectedMembers
      );
      const updatedInsurance = generateInsuranceMember(insurance, assets);
      setInsurance(updatedInsurance);
      setLoadedInstruments(true);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [instrumentsList, showHiddenFinancials, selectedMembers]);

  const filterLegals = (legals, selectedMembers) => {
    const pureMembersList = membersList.filter(
      (mem) => mem.member_type !== 'all'
    );
    const pureSelectedMembers = selectedMembers.filter(
      (mem) => mem.member_type !== 'all'
    );
    const allMembersSelected =
      pureMembersList?.length === pureSelectedMembers?.length;
    if (allMembersSelected) {
      return legals;
    } else {
      return [...legals].reduce((acc, legal) => {
        const copiedLegal = { ...legal };
        const membersArr = copiedLegal?.applicable_members?.reduce(
          (acc, member) => {
            return [...acc, member?.uuid];
          },
          []
        );
        const selectedIds = selectedMembers.map((mem) => mem.uuid);
        if (selectedIds && selectedIds.length !== 0) {
          if (membersArr.some((item) => selectedIds.includes(item))) {
            return [...acc, copiedLegal];
          }
        }
        return acc;
      }, []);
    }
  };

  const mapLegalMembers = (legals) => {
    const memberMap = members.reduce((acc, obj) => {
      acc[obj.uuid] = obj;
      return acc;
    }, {});

    const legalList = [...legals].map((legal) => {
      const copiedLegal = { ...legal };
      copiedLegal.applicable_members = legal.applicable_members
        .filter((item) => item !== undefined)
        .map((am) => {
          return memberMap[am.uuid];
        });
      copiedLegal.empowered_members = legal.empowered_members
        .filter((item) => item !== undefined)
        .map((am) => {
          return memberMap[am.uuid];
        });
      return copiedLegal;
    });
    return legalList;
  };

  useEffect(() => {
    if (legals && members && loadedMembers) {
      const filteredLegals = filterLegals(legals, selectedMembers);
      const transformedLegals = mapLegalMembers(filteredLegals);
      setLegal(transformedLegals);
      setLoadedLegals(true);
    }
  }, [legals, members, selectedMembers]);

  useEffect(() => {
    if (loadedInstruments) {
      const combined = [...assets, ...cashFlow, ...liabilities, ...insurance];
      setVisibleInstruments(combined);
    }
  }, [assets, cashFlow, liabilities, insurance]);

  useEffect(() => {
    if (loadedHouseholdFields) {
      if (showMultiSelectFinancials) {
        dispatch(toggleShowHiddenFinancials(false));
        SendTrackingEvent(
          invocationId,
          'click',
          'multi_select_show_menu',
          'household_financials',
          {
            household: currentHousehold.id,
          }
        );
      } else {
        dispatch(toggleShowHiddenFinancials(true));
      }
    }
  }, [loadedHouseholdFields, showMultiSelectFinancials]);

  useEffect(() => {
    if (showSearchFinancials) {
      const searchQuery = searchValue.toLowerCase().trim();
      const updatedList = visibleInstruments.map((instrument) => {
        const name = instrument.name.toLowerCase();
        const source = instrument.source.toLowerCase();
        instrument.is_selected =
          (name.includes(searchQuery) || source.includes(searchQuery)) &&
          searchQuery !== '';
        return instrument;
      });
      setVisibleInstruments(updatedList);
    }
  }, [showSearchFinancials, searchValue]);

  const closeMenu = (fun) => {
    setShowContextMenu(false);
    return fun;
  };

  const contextMenu = {
    heading: 'Actions',
    items: [
      {
        label: 'Cash Flow',
        image: lightBlueCirclePlus,
        name: 'create-cash-flow',
        onClick: () => {
          dispatch(clearInstrumentInfo());
          closeMenu(dispatch(toggleShowCashFlowModal(true, true)));
        },
      },
      {
        label: 'Asset',
        image: pinkCirclePlus,
        name: 'create-asset',
        onClick: () => {
          dispatch(clearInstrumentInfo());
          closeMenu(dispatch(toggleShowAssetModal(true, true)));
        },
      },
      {
        label: 'Liability',
        image: orangeCirclePlus,
        name: 'create-liability',
        onClick: () => {
          dispatch(clearInstrumentInfo());
          closeMenu(dispatch(toggleShowLiabilityModal(true, true)));
        },
      },
      {
        label: 'Insurance',
        image: blueCirclePlus,
        name: 'create-insurance',
        onClick: () => {
          dispatch(clearInstrumentInfo());
          closeMenu(dispatch(toggleShowInsuranceModal(true, true)));
        },
      },
      {
        label: 'Hidden',
        hasToggle: true,
        name: 'show-hidden',
        checked: showHiddenFinancials,
        onClick: () =>
          dispatch(toggleShowHiddenFinancials(showHiddenFinancials)),
      },
      {
        label: 'Cash Value',
        hasToggle: true,
        name: 'show-cash-value',
        checked: showCashValueColumn,
        onClick: () => dispatch(toggleShowCashValueColumn(showCashValueColumn)),
      },
    ],
  };

  const cashFlowDeleteFunction = (cashFlow) => {
    dispatch(
      toggleShowDeleteModal(true, 'cashflow', cashFlow.name, cashFlow.id)
    );
  };

  const cashFlowEditFunction = (cashFlow) => {
    dispatch(getInstrumentInfo(currentHousehold.id, cashFlow.id, isConsumer));
    dispatch(toggleShowCashFlowModal(true));
  };

  const assetDeleteFunction = (asset) => {
    dispatch(toggleShowDeleteModal(true, 'asset', asset.name, asset.id));
  };

  const assetEditFunction = (asset) => {
    dispatch(getInstrumentInfo(currentHousehold.id, asset.id, isConsumer));
    dispatch(toggleShowAssetModal(true));
  };

  const liabilityDeleteFunction = (liability) => {
    dispatch(
      toggleShowDeleteModal(true, 'liability', liability.name, liability.id)
    );
  };

  const liabilityEditFunction = (liability) => {
    dispatch(getInstrumentInfo(currentHousehold.id, liability.id, isConsumer));
    dispatch(toggleShowLiabilityModal(true));
  };

  const insuranceDeleteFunction = (insurance) => {
    dispatch(
      toggleShowDeleteModal(true, 'insurance', insurance.name, insurance.id)
    );
  };

  const insuranceEditFunction = (insurance) => {
    dispatch(getInstrumentInfo(currentHousehold.id, insurance.id, isConsumer));
    dispatch(toggleShowInsuranceModal(true));
  };

  const legalEditFunction = (legal) => {
    dispatch(getLegal(currentHousehold.id, legal.id));
    dispatch(toggleShowLegalModal(true));
  };

  const legalDeleteFunction = (legal) => {
    dispatch(
      toggleShowDeleteModal(true, 'legal', legal.reference_name, legal.id)
    );
  };

  useEffect(() => {
    if (loadedInstruments) {
      setSelectedInstruments(
        visibleInstruments.reduce((acc, curr) => {
          if (curr.is_selected) {
            return [...acc, curr.id];
          }
          return acc;
        }, [])
      );
    }
  }, [visibleInstruments]);

  const toggleSelected = (instrument) => {
    const updatedList = instrumentsList.map((i) => {
      if (i.id === instrument.id) {
        i.is_selected = !i.is_selected;
      }
      return i;
    });
    setInstrumentsList(updatedList);
  };

  const clearAllSelectedInstruments = (keepMenuOpen = false) => {
    setInstrumentsList(SetObjectFieldValue(instrumentsList, 'is_selected'));
    setSelectedInstruments([]);
    if (!keepMenuOpen) {
      dispatch(toggleShowMultiSelectFinancials(true));
    }
  };

  const selectMember = (clickedMember) => {
    closeBulkActionsMenu();
    if (clickedMember.id === 'all') {
      return setMembersList(
        membersList.map((member) => {
          if (member.id === clickedMember.id) {
            member.is_selected = !member.is_selected;
          } else {
            if (member.category === 'primary') {
              member.is_selected = true;
            } else {
              member.is_selected = clickedMember.is_selected;
            }
          }
          return member;
        })
      );
    }
    const pureMembers = selectedMembers.filter(
      (mem) => mem.member_type !== 'all'
    );
    if (pureMembers.length <= 1 && pureMembers.includes(clickedMember)) {
      return;
    }
    setMembersList(
      membersList.map((member) => {
        if (member.id === 'all') {
          member.is_selected = false;
        }
        if (member.id === clickedMember.id) {
          member.is_selected = !member.is_selected;
        }
        return member;
      })
    );
  };

  const bulkInstrumentsAction = (action) => {
    if (selectedInstruments && selectedInstruments.length > 0) {
      if (action) {
        return dispatch(
          toggleShowBulkModal(
            true,
            'instruments',
            action,
            currentHousehold.id,
            null,
            selectedInstruments
          )
        );
      }
    } else {
      return InfoNotification('No Financials Selected');
    }
  };

  const generateMemberHeading = (type) => {
    switch (type) {
      case 'income':
        return 'Interested Members';
      case 'assets':
        return 'Owner Members';
      case 'liability':
        return 'Obligated Members';
      case 'insurance':
        return 'Insured';
      case 'legal':
        return 'Applicable Members';
      default:
        return 'Members';
    }
  };

  const generateDescriptionHeading = (type) => {
    switch (type) {
      case 'income':
        return 'Income Source';
      case 'assets':
      case 'legal':
        return 'Location';
      case 'liability':
        return 'Lender';
      case 'insurance':
        return 'Insurance Provider';
      default:
        return 'Source';
    }
  };

  const generateValueHeading = (type) => {
    switch (type) {
      case 'income':
        return 'Annual Amount';
      case 'assets':
        return 'Value';
      case 'liability':
        return 'Debt Outstanding';
      case 'insurance':
        return 'Benefit Amount';
      default:
        return 'Amount';
    }
  };

  const generateColumnHeadings = (type) => {
    let showUM = true;
    let showValue = true;
    if (type === 'legal') {
      showUM = false;
      showValue = false;
    }
    let headings = [
      { text: 'Type', display: true },
      type !== 'legal' ? { text: 'Status', display: true } : null,
      { text: generateMemberHeading(type), display: true },
      { text: 'Reference Name', display: true },
      { text: generateDescriptionHeading(type), display: true },
      type === 'insurance' && showCashValueColumn
        ? {
            text: 'Cash Value',
            display: true,
            align: 'right',
            padding: '5px 8px 5px 0',
          }
        : null,
      type !== 'legal'
        ? {
            text: generateValueHeading(type),
            display: showValue,
            align: 'right',
            padding: '5px 8px 5px 0',
          }
        : null,
      type === 'legal'
        ? { text: 'Date Executed', display: true, align: 'center' }
        : null,
      { text: 'Data Collected', display: true, align: 'center' },
      type !== 'legal'
        ? { text: 'UM', display: showUM, align: 'center' }
        : null,
      { text: 'Actions', display: false },
    ];
    headings = headings.filter((heading) => heading !== null);
    if (showMultiSelectFinancials) {
      headings = [
        { text: 'Select', display: true, align: 'left' },
        ...headings,
      ];
    }
    return headings;
  };

  const tableList = [
    {
      type: 'income',
      heading: 'Cash Flows',
      headingButton: canEdit
        ? {
            text: 'New Cash Flow',
            function: () => dispatch(toggleShowCashFlowModal(true, true)),
          }
        : null,
      items: cashFlow,
      deleteFunction: cashFlowDeleteFunction,
      editFunction: cashFlowEditFunction,
      display: true,
      loaded: loadedInstruments,
    },
    {
      type: 'assets',
      heading: 'Assets',
      headingButton: canEdit
        ? {
            text: 'New Asset',
            function: () => dispatch(toggleShowAssetModal(true, true)),
          }
        : null,
      items: assets,
      deleteFunction: assetDeleteFunction,
      editFunction: assetEditFunction,
      display: true,
      loaded: loadedInstruments,
    },
    {
      type: 'liability',
      heading: 'Liabilities',
      headingButton: canEdit
        ? {
            text: 'New Liability',
            function: () => dispatch(toggleShowLiabilityModal(true, true)),
          }
        : null,
      items: liabilities,
      deleteFunction: liabilityDeleteFunction,
      editFunction: liabilityEditFunction,
      display: true,
      loaded: loadedInstruments,
    },
    {
      type: 'insurance',
      heading: 'Insurance',
      headingButton: canEdit
        ? {
            text: 'New Insurance',
            function: () => dispatch(toggleShowInsuranceModal(true, true)),
          }
        : null,
      items: insurance,
      deleteFunction: insuranceDeleteFunction,
      editFunction: insuranceEditFunction,
      display: true,
      loaded: loadedInstruments,
    },
    {
      type: 'legal',
      heading: 'Legal',
      headingButton: canEdit
        ? {
            text: 'New Legal',
            function: () => dispatch(toggleShowLegalModal(true, true)),
          }
        : null,
      items: legal,
      deleteFunction: legalDeleteFunction,
      editFunction: legalEditFunction,
      display: hasLegal,
      loaded: loadedLegals,
    },
  ];

  const BulkActionHotkeys = () => {
    useHotkeys('ctrl+shift+s', () => toggleShowBulkActions());
    useHotkeys('esc', () => closeBulkActionsMenu());
    return <></>;
  };

  const resetBulkActions = () => {
    clearAllSelectedInstruments();
    setShowSearchFinancials(false);
    setSearchValue('');
  };

  const toggleShowBulkActions = () => {
    if (loadedInstruments) {
      resetBulkActions();
      dispatch(toggleShowMultiSelectFinancials(showMultiSelectFinancials));
    }
  };

  const closeBulkActionsMenu = () => {
    if (loadedInstruments) {
      resetBulkActions();
      dispatch(toggleShowMultiSelectFinancials(true));
      setVisibleInstruments(
        SetObjectFieldValue(visibleInstruments, 'is_selected')
      );
    }
  };

  const toggleShowSearchFinancials = () => {
    clearAllSelectedInstruments(true);
    setShowSearchFinancials(true);
    SendTrackingEvent(
      invocationId,
      'click',
      'multi_select_search',
      'household_financials',
      {
        household: currentHousehold.id,
      }
    );
  };

  const bulkActions = [
    {
      value: 'clear',
      label: 'Clear',
      title: 'Clear Selected',
      icon: hide,
      onClick: () => clearAllSelectedInstruments(true),
    },
    {
      value: 'trash',
      label: 'Delete',
      title: 'Delete Selected',
      icon: trashDark,
      onClick: () => bulkInstrumentsAction('delete'),
    },
    {
      value: 'copy',
      label: 'Clone',
      title: 'Clone selected',
      icon: copy,
      onClick: () => bulkInstrumentsAction('clone'),
    },
    {
      value: 'hide',
      label: 'Hide',
      title: 'Hide selected',
      icon: eyeClosedDark,
      iconWidth: '15px',
      onClick: () => bulkInstrumentsAction('hide'),
    },
    {
      value: 'show',
      label: 'Unhide',
      title: 'Unhide selected',
      icon: eyeDark,
      iconWidth: '15px',
      onClick: () => bulkInstrumentsAction('show'),
    },
    {
      value: 'search',
      label: 'Search',
      title: 'Search Financials',
      icon: search,
      iconWidth: '15px',
      onClick: () => toggleShowSearchFinancials(),
    },
  ];

  const onClickPDF = () => {
    const children = selectedMembers.reduce((acc, curr) => {
      if (curr.id !== 'all') {
        return [
          ...acc,
          {
            id: curr.id,
            selected: true,
          },
        ];
      } else {
        return acc;
      }
    }, []);
    SendTrackingEvent(
      invocationId,
      'click',
      'create_pdf_report',
      'household_financials',
      {
        isConsumer: isConsumer,
      }
    );
    return DownloadReport({
      value: 'financials',
      hasOptions: true,
      childValue: 'financial_members',
      children,
    });
  };

  return (
    <>
      {hasContextMenuEnabled && canEdit && (
        <ContextMenu
          menu={contextMenu}
          sideNavWidth={sideNavWidth}
          containerId={'financials-container'}
          showMenu={showContextMenu}
          setShowMenu={setShowContextMenu}
        />
      )}
      <PageContainer id="financials-container">
        <PageHeader
          content={{ ...headerContent.financials, showButton: showPDF }}
          buttonFunction={onClickPDF}
          multiSelectFunction={!isConsumer ? toggleShowBulkActions : null}
          isSelecting={showMultiSelectFinancials}
        />
        <PageContent>
          <BulkActionHotkeys />
          <BulkActions
            show={showMultiSelectFinancials}
            count={selectedInstruments.length}
            actions={bulkActions}
            closeMenu={toggleShowBulkActions}
            search={{
              showingSearch: showSearchFinancials,
              goBack: () => setShowSearchFinancials(false),
              searchValue: searchValue,
              searchOnChange: (e) => setSearchValue(e.currentTarget.value),
            }}
          />
          <ShazamBar
            items={membersList}
            onClick={selectMember}
            loaded={loadedMembers}
            isNew={usingNewAvatar}
          />
          <TablesContainer>
            {tableList.map((table, index) => {
              return (
                table?.display && (
                  <SmallTable
                    key={index}
                    tableHeading={table.heading}
                    headingButton={table.headingButton}
                    columnHeadings={generateColumnHeadings(table.type)}
                    type={table.type}
                    items={table.items}
                    loaded={table.loaded}
                    deleteFunction={canEdit ? table.deleteFunction : null}
                    editFunction={table.editFunction}
                    showEdit={showMultiSelectFinancials}
                    toggleFunction={toggleSelected}
                  />
                )
              );
            })}
          </TablesContainer>
          {isConsumer && (
            <PageFooter footerValue={currentHousehold?.disclaimer} />
          )}
        </PageContent>
      </PageContainer>
    </>
  );
};

const PageContainer = styled.div`
  flex: 1 1 auto;
  overflow-y: auto;
`;

const TablesContainer = styled.div`
  margin: 25px 0 0 0;
`;

HouseholdFinancials.propTypes = {
  isConsumer: PropTypes.bool,
  canEdit: PropTypes.bool,
  showPDF: PropTypes.bool,
};

export default HouseholdFinancials;
