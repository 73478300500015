import PropTypes from 'prop-types';
import {
  Handle,
  //  NodeToolbar,
  Position,
  useReactFlow,
} from 'reactflow';
import styled from 'styled-components';
import { useSelector, useDispatch } from 'react-redux';
import { boxShadows, colors, fonts } from '../../styles/variables';
import {
  FlexCenterAll,
  FlexCenterStart,
  NodeHandleStyle,
} from '../../styles/library/layoutStyles';
import { GenerateAvatarMemberIcon, ShowMemberAge } from '../../utils';
import {
  pencil,
  // timesDarkGrey, trashDark
} from '../../assets';
import {
  getMemberInfo,
  getProfessionalInfo,
  // toggleShowDeleteModal,
  toggleShowEntityModal,
  toggleShowMemberModal,
  toggleShowProfessionalModal,
} from '../../store/actions';
import { useEffect } from 'react';
import {
  DetermineRoleBackground,
  DetermineRoleColor,
} from '../../utils/avatars';

const MemberNode = ({ data }) => {
  const dispatch = useDispatch();
  const {
    // deleteElements,
    setNodes,
  } = useReactFlow();
  const {
    currentHousehold,
    isConsumer,
    // invocationId
  } = useSelector((state) => ({
    currentHousehold: state.households.currentHousehold,
    isConsumer: state.user.isConsumer,
    // invocationId: state.configs.invocationId,
  }));

  useEffect(() => {
    if (data?.isNew) {
      setTimeout(() => {
        setNodes((nodes) =>
          [...nodes].map((value) => {
            const node = { ...value };
            if (node.id === data.id) {
              delete node.data.isNew;
            }
            return node;
          })
        );
      }, 100);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data?.isNew]);

  // const onDelete = (member) => {
  //   dispatch(
  //     toggleShowDeleteModal(
  //       true,
  //       member.isProfessional
  //         ? 'professional'
  //         : member.isMember
  //           ? member?.is_active
  //             ? 'member'
  //             : 'personal'
  //           : member?.is_active
  //             ? 'entity'
  //             : 'otherEntity',
  //       member.name,
  //       member.id
  //     )
  //   );
  // };

  const onEdit = (member) => {
    if (member?.isProfessional) {
      dispatch(
        getProfessionalInfo(currentHousehold?.id, member?.id, isConsumer)
      );
      dispatch(toggleShowProfessionalModal(true, true));
    } else {
      dispatch(getMemberInfo(currentHousehold?.id, member?.id, isConsumer));
      member.isMember
        ? dispatch(toggleShowMemberModal(true, true, null, !member.is_active))
        : dispatch(toggleShowEntityModal(true, true, null, !member.is_active));
    }
  };

  // const onRemove = (member) => {
  //   deleteElements({ nodes: [{ id: member.id }] });
  // };

  // const toolBarActions = [
  //   {
  //     value: 'delete',
  //     icon: trashDark,
  //     onClick: onDelete,
  //     title: 'Delete Member from Household',
  //   },
  //   {
  //     value: 'edit',
  //     icon: pencil,
  //     onClick: onEdit,
  //     title: 'Edit Member Info',
  //   },
  //   {
  //     value: 'remove',
  //     icon: timesDarkGrey,
  //     onClick: onRemove,
  //     title: 'Remove from Map',
  //     style: { opacity: 0.4, width: '10px' },
  //   },
  // ];

  const editAction = {
    value: 'edit',
    icon: pencil,
    onClick: onEdit,
    title: 'Edit Member Info',
  };

  const memberHandleStyle = {
    ...NodeHandleStyle,
    borderColor: DetermineRoleColor(data?.category, data?.isProfessional),
    // background: DetermineRoleBackground(data?.category, data?.isProfessional),
    background: colors.white,
  };

  const generateEditTitle = (data) => {
    let type = 'Member';
    if (data?.isProfessional) {
      type = 'Professional';
    }
    if (data?.member_type === 'entity') {
      type = 'Entity';
    }
    return `Edit ${type} Info`;
  };

  return (
    <>
      {/* <ToolbarContainer
        position={data?.toolbarPosition}
        align="center"
        offset={10}
      >
        {toolBarActions.map((action) => {
          return (
            <ActionButton
              key={action.value}
              onClick={() => action.onClick(data)}
              id={`${action.value}-button`}
              title={action.title}
            >
              <img
                id={`${action.value}-icon`}
                src={action.icon}
                alt={action.value}
                data-image={`${action.value}-${data?.id}`}
                style={{ ...action.style }}
              />
            </ActionButton>
          );
        })}
      </ToolbarContainer> */}
      <Handle
        type="source"
        position={Position.Top}
        style={memberHandleStyle}
        id="n"
      />
      <Handle
        type="source"
        position={Position.Right}
        style={memberHandleStyle}
        id="e"
      />
      <Handle
        type="source"
        position={Position.Left}
        style={memberHandleStyle}
        id="w"
      />
      <Handle
        type="source"
        position={Position.Bottom}
        style={memberHandleStyle}
        id="s"
      />
      <MemberNodeContainer
        $isNew={data?.isNew}
        $backgroundColor={DetermineRoleBackground(
          data?.category,
          data?.isProfessional
        )}
        $borderColor={DetermineRoleColor(data?.category, data?.isProfessional)}
      >
        <MemberAvatar>{GenerateAvatarMemberIcon(data)}</MemberAvatar>
        <MemberInfo>
          <MemberName>
            {data?.name}
            {ShowMemberAge(data) && (
              <MemberAge>
                {`(${data?.age}${data?.date_of_birth_estimated ? 'e' : ''})`}
              </MemberAge>
            )}
          </MemberName>
          <MemberCategory>{data?.role}</MemberCategory>
        </MemberInfo>
        <ActionButtonHover
          key={editAction.value}
          onClick={() => editAction.onClick(data)}
          id={`${editAction.value}-button`}
          title={generateEditTitle(data)}
          $backgroundColor={DetermineRoleColor(
            data?.category,
            data?.isProfessional
          )}
        >
          <img
            id={`${editAction.value}-icon`}
            src={editAction.icon}
            alt={editAction.value}
            data-image={`${editAction.value}-${data?.id}`}
            style={{ ...editAction.style }}
          />
        </ActionButtonHover>
      </MemberNodeContainer>
    </>
  );
};

// const ToolbarContainer = styled(NodeToolbar)`
//   display: flex;
//   align-items: center;
//   background: ${colors.white};
//   box-shadow: ${boxShadows.boxShadowAMRSelected};
//   border-radius: 50px;
//   padding: 3px 2px;
// `;

// const ActionButton = styled.button`
//   border-radius: 100%;
//   width: 24px;
//   height: 24px;
//   margin: 0 2px;
//   position: relative;
//   ${FlexCenterAll};
//   img {
//     width: 12px;
//     height: 12px;
//     opacity: 0.6;
//   }
//   &:hover {
//     background: ${colors.lightGrey};
//     transform: scale(1.01);
//   }
// `;

const ActionButtonHover = styled.button`
  visibility: hidden;
  position: absolute;
  right: 5px;
  top: 5px;
  border-radius: 100%;
  width: 26px;
  height: 26px;
  ${FlexCenterAll};
  background: ${(props) => `${props.$backgroundColor}40`};
  transition: visibility 0.1s ease;
  img {
    width: 12px !important;
    height: 12px !important;
    opacity: 0.7;
  }
  &:hover {
    opacity: 0.8;
    transform: scale(1.01);
  }
`;

const MemberNodeContainer = styled.div`
  background: ${(props) =>
    props.$isNew ? colors.white : `${props.$backgroundColor}`};
  border: 1px solid ${colors.mapGrey};
  border-color: ${(props) => props.$borderColor};
  border-radius: 5px;
  box-shadow: ${boxShadows.boxShadow};
  padding: 10px 20px 10px 10px;
  ${FlexCenterStart};
  align-items: flex-start;
  width: 150px;
  font-size: 12px;
  color: ${colors.darkGrey};
  text-align: left;
  img,
  svg {
    width: 26px;
    height: 26px;
    flex: 0 0 auto;
  }
  &:hover {
    ${ActionButtonHover} {
      visibility: visible;
    }
  }
`;

const MemberAvatar = styled.div`
  ${FlexCenterStart};
  margin-top: 5px;
`;

const MemberInfo = styled.div`
  margin-left: 10px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
`;

const MemberName = styled.p`
  font-weight: ${fonts.semiBold};
  font-size: 14px;
  word-break: break-word;
  max-width: 125px;
`;

const MemberCategory = styled.p`
  font-size: 11px;
  ${'' /* color: ${colors.paleGrey}; */}
  text-transform: capitalize;
  word-break: break-word;
  max-width: 125px;
  ${
    '' /* white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis; */
  }
`;

const MemberAge = styled.span`
  margin-left: 3px;
  font-size: 12px;
  ${'' /* opacity: 0.6; */}
  ${'' /* colors: ${colors.paleGrey}; */}
  font-weight: ${fonts.light};
`;

MemberNode.propTypes = {
  data: PropTypes.object,
};

export default MemberNode;
