import styled, { css } from 'styled-components';
import { lighten } from 'polished';
import { LinkText, LinkTextSmall } from './fontStyles';
import {
  maxDevice,
  colors,
  heights,
  fonts,
  boxShadows,
} from '../../styles/variables';

export const Page = styled.div`
  height: 100%;
  /* Just changes to fix horizontal scroll on Windows */
  /* width: 100vw; */
  width: 100%;
  margin: 0 auto;
  overflow: auto;
  .BaseTable__header-cell {
    background: #f8f8f8 !important;
    font-size: 12px !important;
  }
  .BaseTable__row {
    border-bottom: none;
  }
  .BaseTable__row-cell {
    border-bottom: 1px solid rgb(238, 238, 238);
  }
`;

export const FlexCenterAll = css`
  display: flex;
  align-content: center;
  align-items: center;
  justify-content: center;
`;

export const FlexCenterStart = css`
  display: flex;
  align-content: center;
  align-items: center;
  justify-content: flex-start;
`;

export const FlexCenterEnd = css`
  display: flex;
  align-content: center;
  align-items: center;
  justify-content: flex-end;
`;

export const BackgroundPage = styled.div`
  /* height: 100% */
  height: calc(100vh - ${heights.navBar});
  width: 100vw;
  margin: 0 auto;
  overflow: auto;
  &::before {
    content: '';
    z-index: -1;
    position: absolute;
    top: 0px;
    right: 0px;
    bottom: 0px;
    left: 0px;
    opacity: 1;
    opacity: ${(props) => props.theme.opacity};
    background-image: ${(props) => props.theme.backgroundImage};
    background-repeat: no-repeat;
    background-repeat: ${(props) => props.theme.backgroundRepeat};
    background-size: cover;
    background-size: ${(props) => props.theme.backgroundSize};
    background-position: center;
    background-position: ${(props) => props.theme.backgroundPosition};
    background-attachment: fixed;
    background-attachment: ${(props) => props.theme.backgroundAttachment};
  }
`;

export const PageContent = styled.div`
  @media ${maxDevice.sideNav} {
    margin: 20px 20px;
  }
  margin: ${(props) => (props.margin ? props.margin : '25px 40px')};
`;

export const LeftNavOutlet = styled.div`
  flex: 1 1 auto;
  height: 100%;
  /* display: flex; */
  position: relative;
  width: 100%;
  top: 0;
  overflow-x: auto;
  min-height: 775px;
  /* align-content: center;
  align-items: stretch;
  justify-content: center; */
`;

export const CenteredContainer = styled.div`
  @media ${maxDevice.tablet} {
    background-color: ${colors.white};
    background-image: none;
  }
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;
  align-content: center;
  align-items: center;
  justify-content: flex-start;
  overflow: auto;
  /* background-color: ${colors.lightGrey}; */
  /* background-image: url('http://allwhitebackground.com/images/5/Light-Background-HD-Images.jpg'); */
`;

export const CenteredContainerRow = styled.div`
  @media ${maxDevice.tablet} {
    background-color: ${colors.white};
    background-image: none;
  }
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  align-content: flex-start;
  align-items: flex-start;
  justify-content: center;
  /* background-color: ${colors.lightGrey}; */
  /* background-image: url('http://allwhitebackground.com/images/5/Light-Background-HD-Images.jpg'); */
`;

export const CenterMaxWidth = styled.div`
  margin: 0 auto;
  max-width: 1000px;
  padding: 30px;
`;

export const BlockContainer = styled.div`
  display: block;
  margin: 15px 0;
`;

const lighterGrey = lighten(0.15, `${colors.paleGrey}`);
export const LineBreak = styled.hr`
  color: ${lighterGrey};
  background-color: ${lighterGrey};
  height: 1px;
  margin: 0px 30px;
  border: none;
`;

export const ContentChunk = styled.div`
  display: block;
  margin-bottom: 50px;
`;

export const CenterFlexContainer = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  align-content: center;
  align-items: center;
  justify-content: center;
  ${LinkText} {
    flex: 1 1 auto;
    margin-top: 15px;
    text-align: left;
    &:nth-child(2) {
      text-align: right;
    }
  }
  ${LinkTextSmall} {
    flex: 1 1 auto;
    margin-top: 15px;
    text-align: left;
    &:nth-child(2) {
      text-align: right;
    }
  }
`;

export const StartFlexContainer = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  align-content: center;
  align-items: center;
  justify-content: flex-start;
  ${LinkText} {
    flex: 1 1 auto;
    margin-top: 15px;
    text-align: left;
    &:nth-child(2) {
      text-align: right;
    }
  }
  ${LinkTextSmall} {
    flex: 1 1 auto;
    margin-top: 15px;
    text-align: left;
    &:nth-child(2) {
      text-align: right;
    }
  }
`;

export const CenterContent = styled.div`
  @media ${maxDevice.tablet} {
    position: absolute;
    top: 40px;
    width: 100%;
    padding: 0;
    max-width: 400px;
    height: 260px;
  }
  @media ${maxDevice.mobileL} {
    max-width: 330px;
  }
  @media ${maxDevice.mobileS} {
    background-color: ${colors.white};
    max-width: 290px;
  }
  flex: 1 1 auto;
  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;
  align-content: center;
  justify-content: center;
  padding: 20px;
  width: 100%;
  max-width: 450px;
`;

export const BottomContent = styled.div`
  @media ${maxDevice.tablet} {
    position: absolute;
    top: 340px;
    width: 100%;
    padding: 0;
    max-width: 400px;
    margin-bottom: 30px;
  }
  flex: 0 1 auto;
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;
  align-content: center;
  align-items: stretch;
  justify-content: center;
`;

export const FooterContainer = styled.div`
  font-size: 11px;
  color: ${colors.paleGrey};
  margin: 20px 0 60px 0;
`;

export const LeftIcon = styled.div`
  margin-right: 30px;
  flex: 0 0 auto;
`;

export const ActionContainer = styled.div`
  @media ${maxDevice.tablet} {
    padding: 15px 30px;
    border: none;
    margin-bottom: 10px;
    background: none;
  }
  flex: 1 1 20%;
  min-width: 300px;
  display: flex;
  flex-wrap: nowrap;
  align-content: center;
  align-items: center;
  justify-content: center;
  padding: 25px;
  background-image: ${(props) =>
    props.theme.hasBackground
      ? `linear-gradient(
    to bottom right,
    rgba(255, 255, 255, 1),
    rgba(255, 255, 255, 0.7)
  )`
      : `linear-gradient(
    to bottom right,
    rgba(200, 200, 200, 0.3),
    rgba(255, 255, 255, 0.6)
  )`};

  /* background-image: linear-gradient(
    to bottom right,
    rgba(255, 255, 255, 1),
    rgba(255, 255, 255, 0.7)
  ); */
  /* background-image: linear-gradient(
    to bottom right,
    rgba(200, 200, 200, 0.3),
    rgba(255, 255, 255, 0.6)
  ); */
`;

export const ClickContainer = styled.a`
  display: flex;
  flex-wrap: nowrap;
  align-content: center;
  align-items: center;
  justify-content: center;
  color: ${colors.darkGrey};
`;

export const LoadingContainer = styled.div`
  display: flex;
  flex-wrap: nowrap;
  align-content: center;
  align-items: center;
  justify-content: center;
  color: ${colors.darkGrey};
  width: 100%;
  min-height: ${(props) => props.$minHeight};
`;

export const RightContent = styled.div`
  display: flex;
  flex: 1 1 auto;
  flex-direction: column;
  h3 {
    font-weight: ${fonts.semiBold};
    flex: 0 0 auto;
  }
  p {
    @media ${maxDevice.mobileS} {
      font-size: 12px;
    }
    font-weight: ${fonts.light};
    font-size: 14px;
    margin: 5px 0;
    word-wrap: break-word;
    flex: 1 1 auto;
    display: inline;
  }
  span {
    font-size: 11px;
    text-transform: uppercase;
    color: ${colors.darkGrey};
    font-weight: ${fonts.bold};
    flex: 0 0 auto;
  }
`;

export const ColorTileContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  align-content: center;
  align-items: center;
  justify-content: flex-start;
  margin: -10px;
`;

export const ColorTile = styled.div`
  min-width: 150px;
  min-height: 150px;
  width: 13%;
  flex: 1 1 auto;
  font-size: 13px;
  color: ${colors.white};
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  align-content: center;
  align-items: center;
  justify-content: center;
  margin: 10px;
  p {
    flex: 0 0 auto;
    margin: 5px;
  }
`;

export const PreviewContainer = styled.div`
  position: fixed;
  padding: 10px 15px;
  font-size: 14px;
  font-weight: ${fonts.semiBold};
  /* border: 1px solid ${colors.lightGrey}; */
  background: ${colors.lighterGrey};
  box-shadow: ${boxShadows.boxShadowNavbarAlt};
  z-index: 9999;
  border-radius: 3px;
  left: 50%;
  top: calc(${heights.navBar} + 10px);
  transform: translate(-50%);
  cursor: pointer;
`;

export const NodeHandleStyle = {
  backgroundColor: colors.mapGrey,
  border: `1px solid ${colors.white}`,
  width: '4px',
  minWidth: '4px',
  height: '4px',
  minHeight: '4px',
};
