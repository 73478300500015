import styled from 'styled-components';
import PropTypes from 'prop-types';
import { maxDevice } from '../styles/variables';
import { FooterContainer } from '../styles/library/layoutStyles';

const PageFooter = ({ footerValue, isAMR = false, additionalMargin }) => {
  return isAMR ? (
    <AMRFooterContainer
      $additionalMargin={additionalMargin}
      dangerouslySetInnerHTML={{
        __html: footerValue,
      }}
    />
  ) : (
    <FooterContainer
      $additionalMargin={additionalMargin}
      dangerouslySetInnerHTML={{
        __html: footerValue,
      }}
    />
  );
};

const AMRFooterContainer = styled(FooterContainer)`
  @media ${maxDevice.laptopAlt} {
    margin-bottom: 120px;
    margin-bottom: ${(props) => `calc(120px + ${props.$additionalMargin})`};
  }
  @media ${maxDevice.tablet} {
    margin-bottom: 130px;
    margin-bottom: ${(props) => `calc(130px + ${props.$additionalMargin})`};
  }
  @media ${maxDevice.mobileL} {
    margin-bottom: 180px;
    margin-bottom: ${(props) => `calc(180px + ${props.$additionalMargin})`};
  }
  margin-bottom: 80px;
  margin-bottom: ${(props) => `calc(80px + ${props.$additionalMargin})`};
  ol {
    list-style: auto !important;
  }
  ul {
    list-style-type: disc !important;
  }
  li {
    margin-left: 30px;
  }
`;

PageFooter.propTypes = {
  footerValue: PropTypes.string,
  isAMR: PropTypes.bool,
};

export default PageFooter;
