/* eslint-disable react-hooks/exhaustive-deps */
import ReactDOM from 'react-dom';
import { useRef } from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import {
  ModalButtons,
  ModalTabs,
  DetectModalKeys,
  GenerateInput,
  Error,
  LoadingAnimation,
  ProposalsTab,
} from '..';
import {
  timesWhite,
  chevronRight,
  greyCirclePlus,
  arrowBack,
} from '../../assets';
import {
  ModalBackground,
  ModalOverlay,
  ModalContainer,
  ModalContentContainer,
  ModalHeadingContainer,
  ModalHeading,
  CloseButton,
  TabContent,
  ModalLoadingOverlayContainer,
  LoadingOverlayContainer,
  LoadingOverlay,
} from '../../styles/library/modalStyles';
import {
  GenerateAvatarMemberIcon,
  GenerateAvatarMemberIconShazam,
  GenerateError,
  GenerateProposalIcon,
} from '../../utils';
import {
  colors,
  colorsRGB,
  assetMapColors,
  fonts,
  inputColors,
} from '../../styles/variables';
import {
  FlexCenterAll,
  FlexCenterEnd,
  FlexCenterStart,
} from '../../styles/library/layoutStyles';
import { Tooltip } from 'react-tippy';

const LegalModal = ({
  hide,
  modalHeader,
  hasTabs,
  modalTabOptions,
  activeTab,
  setActiveTab,
  loadedData,
  legalsList,
  handleClickLegal,
  hasCategories,
  categoryOptions,
  handleClickCategory,
  selectedLegal,
  showBackButton,
  essentialInputs,
  notesInput,
  handleClickBack,
  onSaveLegal,
  buttonLoading,
  onDeleteLegal,
  modalState,
  modalErrors,
  modalError,
  errorTitle,
  proposalContent,
  usingNewAvatar,
}) => {
  const ref = useRef();
  const generateHeader = () => {
    if (modalState === 'list') {
      return <HeaderSectionHidden>Summary</HeaderSectionHidden>;
    }
    if (modalState === 'edit') {
      if (showBackButton) {
        const selectedLegalInput = {
          type: 'select',
          label: 'Current Legal',
          name: 'legal',
          required: true,
          placeholder: 'Legal',
          value: selectedLegal,
          options: legalsList,
          width: '100%',
          isVisible: true,
          onChange: (e) => handleClickLegal(e),
          errorMessage: GenerateError(modalErrors, 'legal'),
        };
        return (
          <HeaderSection>
            <BackButtonContainer>
              <BackButton onClick={() => handleClickBack()}>
                <img src={arrowBack} alt={'back'} data-image="back-arrow" />
                Back to Summary
              </BackButton>
            </BackButtonContainer>
            {activeTab === 'essentials' && (
              <HeaderInput>{GenerateInput(selectedLegalInput, 0)}</HeaderInput>
            )}
          </HeaderSection>
        );
      }
    }
    if (modalState === 'add') {
      if (showBackButton) {
        return (
          <HeaderSection>
            <BackButtonContainer onClick={() => handleClickBack()}>
              <BackButton>
                <img src={arrowBack} alt={'back'} data-image="back-arrow" />
                Back to Summary
              </BackButton>
            </BackButtonContainer>
          </HeaderSection>
        );
      }
    }
  };

  return ReactDOM.createPortal(
    <>
      <DetectModalKeys onEsc={hide} />
      <ModalBackground />
      <ModalOverlay>
        <ModalContainer role="dialog" ref={ref}>
          <ModalHeadingContainer>
            <ModalHeading>{modalHeader}</ModalHeading>
            <CloseButton
              src={timesWhite}
              onClick={() => hide()}
              alt="close"
              data-image="close"
            />
          </ModalHeadingContainer>
          <ModalLoadingOverlayContainer>
            {!loadedData && (
              <LoadingOverlayContainer>
                <LoadingOverlay>
                  <LoadingAnimation />
                  {/* <LoadingAnimation smaller={true} /> */}
                  {/* <p>Loading</p> */}
                </LoadingOverlay>
              </LoadingOverlayContainer>
            )}
            {hasTabs && (
              <ModalTabs
                tabs={modalTabOptions}
                activeTab={activeTab}
                onClick={setActiveTab}
              />
            )}
            <ModalContentContainer
              $minHeight={modalState === 'list' ? '210px' : null}
            >
              {generateHeader()}
              {modalState !== 'list' && (
                <>
                  <Section $hidePadding={!showBackButton}>
                    {activeTab === 'essentials' ? (
                      <TabContent>
                        {essentialInputs?.map((input, index) => {
                          return GenerateInput(input, index);
                        })}
                      </TabContent>
                    ) : activeTab === 'notes' ? (
                      <TabContent>
                        {notesInput?.map((input, index) => {
                          return GenerateInput(input, index);
                        })}
                      </TabContent>
                    ) : activeTab === 'proposals' ? (
                      <ProposalsTab
                        proposalContent={proposalContent}
                        hasError={modalError && modalError !== ''}
                      />
                    ) : null}
                  </Section>
                  {activeTab !== 'proposals' && (
                    <ModalButtons
                      isNew={modalState === 'add'}
                      hide={hide}
                      saveFunction={onSaveLegal}
                      showLoading={buttonLoading}
                      loadingText={modalState === 'add' ? 'Creating' : 'Saving'}
                      deleteFunction={onDeleteLegal}
                    />
                  )}
                </>
              )}
              {modalState === 'list' && (
                <>
                  {legalsList?.length > 0 ? (
                    <Section>
                      <ActiveLegals>
                        <SectionHeader>Active</SectionHeader>
                        {legalsList.map((legal, index) => {
                          return (
                            <LegalRow
                              key={index}
                              onClick={() => handleClickLegal(legal)}
                              isProposal={legal?.is_proposal}
                              isDelete={legal?.proposal_action === 'delete'}
                            >
                              <LegalLeft>
                                <LegalName>
                                  {legal.name}{' '}
                                  {legal.year && (
                                    <LegalYear>{`(${legal.year})`}</LegalYear>
                                  )}
                                </LegalName>
                              </LegalLeft>
                              <LegalRight>
                                {legal?.members?.length &&
                                  legal.members
                                    .filter(Boolean)
                                    .map((member, index) => {
                                      return (
                                        member && (
                                          <Tooltip
                                            title={member?.name}
                                            position="top"
                                            trigger="mouseenter click"
                                            delay={0}
                                            arrow={true}
                                            arrowSize={'small'}
                                            animation={'fade'}
                                            animateFill={false}
                                            transitionFlip={false}
                                            size={'small'}
                                            style={{ display: 'flex' }}
                                            key={index}
                                          >
                                            {usingNewAvatar ? (
                                              <LegalMember>
                                                {GenerateAvatarMemberIcon(
                                                  member
                                                )}
                                              </LegalMember>
                                            ) : (
                                              <LegalMemberImage
                                                src={GenerateAvatarMemberIconShazam(
                                                  member
                                                )}
                                              ></LegalMemberImage>
                                            )}
                                          </Tooltip>
                                        )
                                      );
                                    })}
                              </LegalRight>
                              {legal?.is_proposal &&
                                GenerateProposalIcon(legal)}
                              <ArrowButton
                                onClick={() => handleClickLegal(legal)}
                              >
                                <img
                                  src={chevronRight}
                                  alt="arrow"
                                  data-image="arrow-right"
                                />
                              </ArrowButton>
                            </LegalRow>
                          );
                        })}
                      </ActiveLegals>
                    </Section>
                  ) : (
                    <p>No Legals</p>
                  )}
                  {hasCategories && categoryOptions?.length && (
                    <Section>
                      <SectionHeader>Add</SectionHeader>
                      <ActiveAddables>
                        {categoryOptions.map((category) => {
                          return (
                            <LegalRowAdd
                              key={category.value}
                              onClick={() => handleClickCategory(category)}
                            >
                              <LegalLeft>
                                <LegalCategory>{category.label}</LegalCategory>
                              </LegalLeft>
                              <LegalRight>
                                <PlusButton>
                                  <img
                                    src={greyCirclePlus}
                                    alt="plus"
                                    data-image="plus"
                                  />
                                </PlusButton>
                              </LegalRight>
                            </LegalRowAdd>
                          );
                        })}
                      </ActiveAddables>
                    </Section>
                  )}
                </>
              )}
            </ModalContentContainer>
          </ModalLoadingOverlayContainer>
          {modalError && modalError !== '' && (
            <Error errorMessage={modalError} title={errorTitle} />
          )}
        </ModalContainer>
      </ModalOverlay>
    </>,
    document.body
  );
};

const BackButtonContainer = styled.div`
  font-size: 11px;
  ${FlexCenterStart};
  margin: 0;
`;

const BackButton = styled.div`
  ${FlexCenterStart};
  cursor: pointer;
  &:hover {
    transform: scale(1.01);
    opacity: 0.9;
  }
  img {
    width: 12px;
    height: 12px;
    padding-right: 5px;
  }
`;

const HeaderInput = styled.div`
  padding-top: 10px;
  font-weight: 500;
  padding: 10px 15px 5px 20px;
  margin: 15px -20px 0px -20px;
  background: ${colors.hoverLighterGrey};
`;

const HeaderSection = styled.div`
  font-size: 12px;
  text-align: left;
  font-weight: ${fonts.semiBold};
`;

const HeaderSectionHidden = styled(HeaderSection)`
  margin: -25px 0;
  visibility: hidden;
`;

const SectionHeader = styled.p`
  font-weight: ${fonts.semiBold};
  font-size: 11px;
  margin-bottom: 10px;
`;

const ActiveLegals = styled.div`
  display: flex;
  flex-direction: column;
  margin: 0 -20px;
  padding: 15px 20px 10px 20px;
  background: ${colors.lightGrey};
`;

const ArrowButton = styled.button`
  height: 22px;
  width: 22px;
  border-radius: 100%;
  ${FlexCenterAll};
  img {
    width: 10px;
    height: 10px;
  }
`;

const LegalRow = styled.div`
  position: relative;
  display: flex;
  justify-content: space-between;
  align-items: center;
  cursor: pointer;
  padding: 10px 10px 10px 12px;
  border: ${(props) =>
    props.isProposal
      ? `1px solid #E2458530`
      : `1px solid ${inputColors.border}80`};
  background-color: ${(props) =>
    props.isProposal ? assetMapColors.draft : colors.white};
  border-radius: 6px;
  margin-bottom: 8px;
  &:hover {
    ${ArrowButton} {
      opacity: 0.8;
      background-color: ${(props) =>
        props.isProposal ? `${colors.pink}15` : colors.lightGrey};
    }
    transform: scale(1.01);
    background-color: ${(props) =>
      props.isProposal ? `${assetMapColors.draft}99` : colors.lighterGrey};
  }
  ${({ isDelete, isProposal }) =>
    isProposal &&
    isDelete &&
    `
    background-image: url("data:image/svg+xml,%3Csvg width='10' height='10' viewBox='0 0 6 6' xmlns='http://www.w3.org/2000/svg'%3E%3Cg fill='%23E24585' fill-opacity='0.15' fill-rule='evenodd'%3E%3Cpath d='M5 0h1L0 6V5zM6 5v1H5z'/%3E%3C/g%3E%3C/svg%3E");
  `}
`;

const LegalRowAdd = styled(LegalRow)`
  border: 1px solid ${colors.lightGrey};
`;

const LegalLeft = styled.div`
  display: flex;
  align-items: center;
  flex: 1 1 auto;
`;

const LegalRight = styled.div`
  display: flex;
  align-items: center;
  margin: 0 5px 0 10px;
  flex: 0 1 auto;
  ${FlexCenterEnd};
  flex-wrap: wrap;
  max-width: 145px;
`;

const LegalName = styled.p`
  font-size: 12px;
  font-weight: ${fonts.semiBold};
  line-height: 16px;
`;

const LegalYear = styled.span`
  color: ${colorsRGB.paleGrey};
  font-size: 10px;
  line-height: 13px;
  font-weight: ${fonts.regular};
`;

const LegalMember = styled.div`
  max-width: 14px;
  width: 100%;
  max-height: 14px;
  height: 100%;
  margin: 1.5px 2px;
  ${FlexCenterAll};
  svg {
    width: 100%;
    height: 100%;
    max-width: 14px;
    max-height: 14px;
  }
`;

const LegalMemberImage = styled.img`
  max-width: 13px;
  width: 100%;
  max-height: 13px;
  height: 100%;
  margin: 1.5px 2px;
  ${FlexCenterAll};
`;

const Section = styled.div`
  text-align: left;
  padding: ${(props) => (props.$hidePadding ? '0' : '15px 0 0 0')};
`;

const ActiveAddables = styled.div`
  display: flex;
  flex-direction: column;
`;

const LegalCategory = styled.div`
  font-size: 12px;
  color: ${colors.paleGrey};
`;

const PlusButton = styled.button`
  display: flex;
  padding: 0;
  align-items: center;
  img {
    width: 20px;
    height: 20px;
  }
`;

LegalModal.propTypes = {
  hide: PropTypes.func,
  householdId: PropTypes.string,
  isNew: PropTypes.bool,
  isList: PropTypes.bool,
  proposalId: PropTypes.string,
  showProposalMode: PropTypes.bool,
};

export default LegalModal;
