import Cookies from 'js-cookie';
import {
  UserTypes as USER,
  ApiTypes as API,
  HouseholdTypes as HOUSEHOLD,
} from '../actions';
import { CONSUMER_BASENAME as consumerBasename } from '../../utils/constants';
import {
  CheckIsAuthenticated,
  AddAuthCookies,
  ToggleCookie,
  HasCookie,
  GetNavExpandedValue,
  CheckIsConnectURL,
  CheckHasAtLeastOneEntitlement,
  GetValueFromCookie,
  RemoveCookies,
  CheckRememberMe,
  GenerateAuthResponseError,
  UpdateUpscope,
  GetLogoValue,
  UpdateLogoValue,
  RemoveLocalStorage,
} from '../../utils';

const initialState = {
  loggedIn: CheckIsAuthenticated(),
  isConsumer: HasCookie('isConsumer'),
  isPreview: HasCookie('isPreview'),
  user: {},
  logo: GetLogoValue(),
  loadedUser: null,
  isTimeout: false,
  groups: [],
  sideNavExpanded: GetNavExpandedValue(),
  sideNavWidth: GetNavExpandedValue(true),
  isStaff: null,
  isAdmin: null,
  hasAtlas: null,
  isCustomerAdmin: null,
  isDiscoveryManager: null,
  showSnow: false,
  isManager: null,
  isBilling: null,
  isDelegate: null,
  isImpersonating: null,
  isImpersonatingCustomer: null,
  isExpired: false,
  isSSO: false,
  consumerCanCreateHousehold: null,
  showCUR: null,
  showInboundLink: null,
  showInboundDiscoveryLink: null,
  showTargetMaps: null,
  targetMapDefaultTab: null,
  showHouseholdSignals: null,
  showSignalsPlus: null,
  showReportHistory: null,
  showUnlimitedReportHistory: null,
  showSignalsPanel: null,
  showSurveys: null,
  showSurveyHistory: null,
  showHouseholdSharing: null,
  cantDeleteHousehold: false,
  canExportHousehold: false,
  showMilestones: null,
  showMilestonesElite: null,
  showTransferBoB: null,
  showTransferBoBExternal: null,
  showManagerIntegrations: null,
  showDiscoveryTheme: null,
  showClassic: false,
  hasPropCas: false,
  showCustom: false,
  showMFA: true,
  householdStartPage: 'summary',
  canSetSubtitle: false,
  canSetRounding: false,
  showStencilHelper: false,
  hasDiscoverySettings: null,
  hasDiscoverySettingsInbound: null,
  showHiddenFinancials: false,
  showCashValueColumn: false,
  showMultiSelectFinancials: false,
  showMultiSelectAMR: false,
  showDelegateModal: false,
  amrShowMembers: GetValueFromCookie('am-toggle-shazaam'),
  amrShowLegend: GetValueFromCookie('am-toggle-am-key'),
  amrShowKffs: true,
  amrShowHidden: false,
  amrShowCustodian: true,
  amrShowEntity: true,
  amrShowLegal: GetValueFromCookie('am-toggle-am-legal'),
  rememberMe: CheckRememberMe(),
  showingDropdown: false,
  modalTitle: 'Welcome to Asset-Map',
  showLoginScreen: true,
  showSelectMFA: false,
  showAuthCode: false,
  showRecoveryScreen: false,
  showHelpScreen: false,
  showResendScreen: false,
  hasEntityRollup: null,
  responseError: {
    showError: false,
    message: '',
  },
  userInfoError: {},
  userGroupsError: {},
  logoutError: {},
  showDiscovery: null,
  integrations: null,
  canSetAutoSync: null,
  userToggleModal: null,
  inCompareMode: false,
  hasFamilyMap: null,
  redirect: null,
  showingAppBar: false,
  usingNewAvatar: true,
};

//ALL VALUES FOR AUTH SCREEN MODALS
const authScreenValues = {
  showLoginScreen: false,
  showHelpScreen: false,
  showResendScreen: false,
  showSelectMFA: true,
  showCodeSentScreen: true,
  showRecoveryScreen: false,
  showAuthCode: false,
  modalTitle: 'Welcome to Asset-Map',
  responseError: {
    showError: false,
    message: '',
  },
};

const sessionErrorText =
  'You have been logged out because the user account logged in from a different location.';

/*
  SETS ALL AUTH SCREEN MODALS TO FALSE EXCEPT FOR PASSED IN FIELD
  - field <string> - key name of field to set to true
  - modalTitle <string> - Title for top of modal to display
*/
const showOnlyThisScreen = (field, modalTitle) => {
  const newState = {};
  for (const [key] of Object.entries(authScreenValues)) {
    let value = false;
    if (field === key) {
      value = true;
    }
    newState[key] = value;
    if (key === 'modalTitle' && modalTitle) {
      newState[key] = modalTitle;
    }
  }
  return newState;
};

/* OBJECT MAPPING OF FRONTEND STORE VALUE FOR EACH ENTITLEMENT
 * If you want to add a new entitlement to frontend just set up mapping here
 *  - e.g. entitlement called 'best_user' becomes isBestUser: 'best_user' inside this object
 * Then inside the app you can use isBestUser now to know if user has that entitlement
 * If you want to test entitlement that isn't on member/info yet just set it to an entitlement you know you have
 *  - e.g. isBestUser: 'superuser'
 */
const entitlementsReference = {
  isStaff: 'staff',
  isAdmin: 'superuser',
  isCustomerAdmin: 'customer_admin',
  isDiscoveryManager: 'discovery_manager',
  isManager: 'team_manager',
  hasAtlas: 'quicksight',
  showTransferBoB: 'book_of_business_transfer',
  showTransferBoBExternal: 'book_of_business_transfer_external',
  isBilling: 'billing_manager',
  isMemberBilling: 'member_billing',
  isAccountProvider: 'account_provider',
  isSSO: 'sso_only',
  isDelegate: 'delegate',
  isImpersonatingCustomer: 'support_manager_enabled',
  showReportLayouts: 'report_layouts',
  showReportHistory: 'report_archive',
  showUnlimitedReportHistory: 'report_history_elite',
  showCUR: 'change_role',
  showMilestones: 'milestone',
  showMilestonesElite: 'milestone_elite',
  showDiscovery: 'discovery',
  hasDiscoverySettings: 'discovery',
  hasDiscoveryInboundEnabled: 'client_interview_lead',
  showInboundLink: 'discovery_inbound',
  hasDiscoverySettingsInbound: 'feature_discovery_inbound_available',
  showIntegrations: 'household_integration',
  showTargetMaps: 'targetmap',
  showTargetMapCashflow: 'targetmap-cashflow',
  hasTargetMapTemplates: 'targetmap_templates',
  hasTargetMapCompare: 'targetmap-comparison',
  showTargetMapAltModel: 'targetmap_altmodel',
  showHouseholdSignals: 'signals',
  showSignalsPlus: 'signals_elite',
  hasPropCas: 'property_casualty',
  showClassic: 'classic_ux_available',
  showStencilHelper: 'stencil_helper',
  showDataLoader: 'instrument_loader',
  showDiscoveryCustom: 'discovery_advanced_share',
  showPartners: 'partner_page',
  showHouseholdSharing: 'feature_consumer_invite',
  showHouseholdImport: 'household-import',
  showSurveys: 'surveys',
  showSurveyHistory: 'surveys',
  canExportIndex: 'index_export',
  canSetSubtitle: 'can_set_subtitle',
  canSetRounding: 'can_set_rounding',
  canEditName: 'edit_name',
  canEditEmail: 'edit_email',
  canEditOrgName: 'edit_customer_name',
  showActivityMonitor: 'analytics',
  showAmrKff: 'amr_kff',
  showCustom: 'custom_ldd',
  showMFA: 'mfa_available',
  userRequiresTerms: 'terms_required',
  userRequiresSubscription: 'subscription_required',
  hasEntityRollup: 'entity_rollup',
  hasBranding: 'branding',
  hasProposals: 'proposals',
  hasSignalsSnooze: 'signals_snooze',
  hasSidebar: 'glass',
  hasBeneficiaryVisualization: 'beneficiary_visualization',
  showACH: 'ach',
  canSetAutoSync: 'integration_auto_sync_choice',
  showStencilLibrary: 'stencil_library',
  hasAssetmapPlusStencilLibrary: 'stencil_library_assetmap_plus',
  hasCustomerStencilLibrary: 'stencil_library_customer',
  hasConnectPreview: 'connect_preview',
  showProfessionals: 'household_professional',
  showExcludedMembers: 'feature_active_householdmembers',
  hasLegal: 'legal',
  hasFamilyMap: 'family_map',
};

//CREATES OBJECT THAT CONTAINS WHETHER USER HAS ENTITLEMENTS LISTED IN ENTITLEMENTS REFERENCE
const generateEntitlementsObject = (entitlements) => {
  let entitlementsObject = {};
  for (const [key, value] of Object.entries(entitlementsReference)) {
    entitlementsObject[key] = entitlements.includes(value);
  }
  return entitlementsObject;
};

const user = (state = initialState, action) => {
  switch (action.type) {
    case USER.UPDATE_USER_STORE_VALUE:
      return {
        ...state,
        [action.payload.fieldName]: action.payload.value,
      };
    case USER.LOG_IN:
    case USER.SUBMIT_AUTH_CODE:
      if (action.payload?.status === 202) {
        if (action.payload.details === 'is_mfa_incompliant') {
          return {
            ...state,
            loggedIn: false,
            loginError: false,
            isDelegate: false,
            authCodeError: false,
            ...showOnlyThisScreen('showSelectMFA', 'Set-Up MFA'),
            modalTitle: 'Set-Up MFA',
            responseError: {
              showError: false,
            },
          };
        } else {
          return {
            ...state,
            loggedIn: false,
            loginError: false,
            isDelegate: false,
            authCodeError: false,
            managers: action.payload?.managers,
            ...showOnlyThisScreen('showAuthCode', 'Enter Authentication Code'),
            mfa_uuid: action.payload.id,
            responseError: {
              showError: false,
            },
          };
        }
      } else {
        const hasConsumerAccess = action.payload?.realm?.includes('consumer');
        const canAccessAMP = action.payload?.realm?.includes('advisor');
        const isConsumerOnly = hasConsumerAccess && !canAccessAMP;
        const isConsumer =
          (hasConsumerAccess && CheckIsConnectURL()) || isConsumerOnly;
        AddAuthCookies(action.payload.token, action.payload.lifetime, false);
        ToggleCookie('isConsumer', isConsumer);
        const redirectUrl = state?.redirect;
        if (redirectUrl) {
          window.location.replace(redirectUrl);
        }
        return {
          ...state,
          loggedIn: true,
          loginError: false,
          justLoggedIn: true,
          isTimeout: false,
          loggedOut: false,
          isConsumer: isConsumer,
          hasConsumerAccess: hasConsumerAccess,
          canAccessAMP: canAccessAMP,
          authCodeError: false,
          isDelegate: false,
          showLoginScreen: false,
          showSelectMFA: true,
          modalTitle: 'Welcome to Asset-Map',
          showAuthCode: false,
        };
      }
    case USER.TOGGLE_REMEMBER_ME:
      localStorage.setItem('rememberMe', action.payload);
      return {
        ...state,
        rememberMe: action.payload,
      };
    case USER.REMOVE_LOGIN_ERROR:
      return {
        ...state,
        responseError: {
          showError: false,
          message: '',
        },
      };
    case USER.SHOW_LOGIN_SCREEN:
      return {
        ...state,
        sentReset: false,
        ...showOnlyThisScreen('showLoginScreen'),
        modalTitle: 'Welcome to Asset-Map',
        mfaMaxAttemptError: false,
        responseError: {
          showError: false,
        },
      };
    case USER.SHOW_LOGIN_HELP_SCREEN:
      return {
        ...state,
        ...showOnlyThisScreen('showHelpScreen', 'Trouble Signing In?'),
      };
    case USER.SHOW_PASSWORD_RECOVERY_SCREEN:
      return {
        ...state,
        ...showOnlyThisScreen('showRecoveryScreen', 'Password Recovery'),
      };
    case USER.SHOW_SENT_RESET_PASSWORD_SCREEN:
      return {
        ...state,
        resetEmail: action.payload.email,
        sentReset: true,
        modalTitle: 'Password Recovery Email Sent',
      };
    case USER.LOG_OUT:
      const exitPreview = Cookies.get('isPreview');
      if (exitPreview) {
        window.location.replace(`${window.location.origin}/ampux/auth`);
      }
      const exitConsumer = Cookies.get('isConsumer');
      if (exitConsumer) {
        window.location.replace(`${window.location.origin}/consumer/auth`);
      }
      RemoveLocalStorage();
      RemoveCookies();
      return {
        ...state,
        loggedIn: false,
        loggedOut: true,
        user: {},
        isImpersonating: false,
        showCUR: false,
        showLoginScreen: true,
        showSelectMFA: false,
        showAuthCode: false,
        responseError: {
          showError: HasCookie('multisession_error'),
          message: HasCookie('multisession_error') ? sessionErrorText : '',
        },
      };
    case USER.SHOW_AUTH_CODE_SCREEN:
      return {
        ...state,
        ...showOnlyThisScreen('showAuthCode', 'Enter Authentication Code'),
        sentReset: false,
        codeResent: false,
      };
    case USER.CLEAR_AUTH_CODE_ERROR:
      return {
        ...state,
        authCodeError: false,
        addAuthCodeError: null,
      };
    case USER.SHOW_RESEND_AUTH_CODE_SCREEN:
      return {
        ...state,
        ...showOnlyThisScreen('showResendScreen', 'Resend Authentication Code'),
      };
    case USER.RESEND_AUTH_CODE:
      return {
        ...state,
        codeResent: true,
        resendCodeError: false,
      };
    case USER.SHOW_AUTH_CODE_SENT_SCREEN:
      return {
        ...state,
        ...showOnlyThisScreen('showCodeSentScreen', 'Authentication Code Sent'),
      };
    case USER.TOGGLE_SHOW_DELEGATE_MODAL:
      return {
        ...state,
        showDelegateModal: action.payload,
      };
    case USER.GET_DELEGATE_ADVISOR_LIST:
      return {
        ...state,
        delegateAdvisorList: action.payload,
      };
    case USER.GET_USER_INFO:
      const hasConsumerEntitlement =
        action.payload?.entitlements?.includes('consumer');
      const hasAdvisorEntitlement = CheckHasAtLeastOneEntitlement(
        action.payload?.entitlements,
        ['advisor', 'delegate']
      );
      const isConsumerOnly = hasConsumerEntitlement && !hasAdvisorEntitlement;
      const isConsumer =
        (hasConsumerEntitlement && CheckIsConnectURL()) || isConsumerOnly;
      AddAuthCookies(null, null, true);
      ToggleCookie('isConsumer', isConsumer);
      ToggleCookie('multisession_error', false);
      return {
        ...state,
        loggedIn: true,
        loginError: false,
        user: action.payload,
        logo: UpdateLogoValue(action.payload.logo),
        loadedUser: true,
        hasMultiSessionError: false,
        profilePicture: null,
        loggedOutOfCustomer: false,
        showDelegateModal: false,
        justChangedRole: false,
        hasConsumerAccess: action.payload?.consumer?.available,
        showSetupMFA: CheckHasAtLeastOneEntitlement(
          action.payload?.entitlements,
          ['mfa_noncompliant']
        ),
        showInboundDiscoveryLink: CheckHasAtLeastOneEntitlement(
          action?.payload?.entitlements,
          ['discovery_inbound', 'client_interview_lead']
        ),
        targetMapDefaultTab:
          action.payload?.preferences?.targetmap?.tm_default_tab,
        showSignalsPanel: action.payload?.preferences?.signals?.display,
        hasContextMenuEnabled: action.payload?.quick_menu_setting,
        isExpired: action.payload?.customer?.subscription?.state === 'expired',
        isImpersonating: action.payload?.delegate?.id,
        isConsumer: isConsumer,
        householdStartPage:
          action?.payload?.preferences?.default_hh_page || 'summary',
        cantDeleteHousehold: CheckHasAtLeastOneEntitlement(
          action.payload?.entitlements,
          ['delegate', 'axa']
        ),
        canExportHousehold: CheckHasAtLeastOneEntitlement(
          action.payload?.entitlements,
          ['staff', 'superuser', 'support_manager_enabled', 'customer_admin']
        ),
        usingNewAvatar: action.payload?.preferences?.amr?.icon_set === 'set-06',
        canSetHouseholdToPrivate: !action.payload?.delegate,
        ...generateEntitlementsObject(action.payload.entitlements),
      };
    case USER.GET_CONSUMER_INFO:
      localStorage.setItem('sideNavExpanded', true);
      return {
        ...state,
        consumer: action.payload,
        consumerCanCreateHousehold:
          action.payload?.create?.includes('manual') &&
          action.payload?.households_available > 0,
        canAccessAMP: CheckHasAtLeastOneEntitlement(
          action.payload?.entitlements,
          ['advisor', 'delegate']
        ),
        sideNavExpanded: GetNavExpandedValue(),
        sideNavWidth: GetNavExpandedValue(true),
      };
    case USER.GET_AUTH_STATUS:
      return {
        ...state,
        authStatus: action.payload,
      };
    case USER.ENTER_CLIENT_PREVIEW_MODE:
      const { householdId, redirect_url, memberName, memberId } =
        action.payload;
      const location = window.location.href;
      const redirectUrl = redirect_url
        ? redirect_url
        : `/${consumerBasename}/household/${householdId}/assetmap`;
      localStorage.setItem('sideNavExpanded', true);
      localStorage.setItem('preview_url', location);
      localStorage.setItem('member_name', memberName);
      localStorage.setItem('member_id', memberId);
      ToggleCookie('isConsumer', true);
      ToggleCookie('isPreview', true);
      window.location.replace(redirectUrl);
      return {
        ...state,
      };
    // case USER.SHOW_CLIENT_PREVIEW_MODE:
    //   const { householdId, location } = action.payload;
    //   localStorage.setItem('sideNavExpanded', true);
    //   localStorage.setItem('household_id', householdId);
    //   localStorage.setItem('preview_url', location);
    //   ToggleCookie('isConsumer', true);
    //   ToggleCookie('isPreview', true);
    //   const consumerUrl = `${window.origin}/consumer/household/${householdId}/summary`;
    //   window.location.replace(consumerUrl);
    //   return {
    //     ...state,
    //   };
    case USER.EXIT_CLIENT_PREVIEW_MODE:
      localStorage.setItem('sideNavExpanded', false);
      RemoveCookies(['isConsumer', 'isPreview']);
      const url = localStorage.getItem('preview_url');
      window.location.replace(url);
      RemoveLocalStorage();
      return {
        ...state,
        enteringPreviewMode: false,
        sideNavExpanded: GetNavExpandedValue(),
        sideNavWidth: GetNavExpandedValue(true),
      };
    case USER.GET_INVITE_DETAILS:
      return {
        ...state,
        inviteDetails: action.payload,
      };
    case USER.GET_INVITE_DETAILS_ERROR:
      return {
        ...state,
        inviteDetailsError: action.payload,
      };
    case USER.SHOW_INVITE_MODAL:
      return {
        ...state,
        showingInviteModal: action.payload?.showModal,
        inviteModalScreen: action.payload?.screen,
      };
    case USER.GET_REGISTRATION_DETAILS:
      return {
        ...state,
        registrationDetails: action.payload,
      };
    case USER.REGISTERED_CONSUMER_ACCOUNT:
      return {
        ...state,
        registeredConsumerAccount: true,
      };
    case USER.REGISTER_CONSUMER_ACCOUNT_ERROR:
      return {
        ...state,
        registerConsumerAccountError: action.payload,
      };
    case USER.RESENT_VERIFY_EMAIL:
      return {
        ...state,
        verifyEmailResent: action.payload,
      };
    case USER.CREATED_CONSUMER_ACCOUNT:
      return {
        ...state,
        createdConsumerAccount: true,
        createdAccountDetails: action.payload,
      };
    case USER.GET_PROFILE_PICTURE:
      return {
        ...state,
        avatars: action.payload,
      };
    case USER.SET_UPLOADED_PROFILE_PICTURE:
      return {
        ...state,
        profilePicture: action.payload,
      };
    case USER.CHECK_HAS_SUPPORT_ACCESS:
      return {
        ...state,
        supportAccess: action.payload,
      };
    case USER.UPDATED_USER_PASSWORD:
      return {
        ...state,
        passwordUpdated: true,
      };
    case USER.RESET_PASSWORD_FIELDS:
      return {
        ...state,
        passwordUpdated: false,
      };
    case USER.GET_AUTH_FACTORS:
      return {
        ...state,
        authFactors: action.payload,
        authCodeEnabled: false,
      };
    case USER.RESET_AUTH_FACTOR:
      return {
        ...state,
        showAuthCode: false,
        authFactorAdded: false,
        authFactorReturned: false,
        authFactorEnabled: false,
        showCodeSentScreen: false,
        codeResent: false,
        authCodeDisabled: false,
        addAuthFactorError: false,
        resendCodeError: false,
      };
    case USER.GET_AUTH_DEVICES:
      return {
        ...state,
        authDevices: action.payload,
      };
    case USER.GET_CUSTOM_USER_PREFERENCES:
      return {
        ...state,
        customLDD: action.payload,
        customLogo: null,
        customSettingsError: null,
      };
    case USER.SET_CUSTOM_LOGO:
      return {
        ...state,
        customLogo: action.payload,
      };
    case USER.GET_TEAM_SETTINGS:
      return {
        ...state,
        teamSettings: action.payload,
        customerSettingsError: null,
        discoveryConsentError: null,
        orgLogo: null,
        teamHasDiscoverySettings:
          action?.payload?.entitlements.includes('discovery'),
        teamHasDiscoverySettingsInbound: action?.payload?.entitlements.includes(
          'discovery_inbound_available'
        ),
        teamHasDiscoveryInboundEnabled:
          action?.payload?.entitlements.includes('discovery_inbound'),
        showMFASettings: action?.payload?.entitlements.includes(
          'customer_mfa_available'
        ),
        showDiscoveryConsentSettings: action?.payload?.entitlements.includes(
          'customer_discovery_consent'
        ),
        showDiscoveryConnectSettings: action?.payload?.entitlements.includes(
          'connect_discovery_signup_available'
        ),
        showManagerIntegrations: action?.payload?.entitlements.includes(
          'integration_manager'
        ),
        showDiscoveryTheme: action?.payload?.entitlements.includes(
          'discovery_custom_theme'
        ),
        showDiscoveryDuration:
          action?.payload?.entitlements.includes('discovery_duration'),
        discoveryDuration: action?.payload?.discovery_duration,
      };
    case USER.SET_ORG_LOGO:
      return {
        ...state,
        orgLogo: action.payload,
      };
    case USER.GET_MANAGER_INTEGRATIONS:
      return {
        ...state,
        managerIntegrations: action.payload,
        updatedManagerIntegration: false,
        updateManagerIntegrationError: false,
      };
    case USER.GET_CUSTOMER_MEMBERS_LIST:
      return {
        ...state,
        membersList: action.payload,
        justDeletedInvite: false,
        justUpdatedMember: false,
        resetMemberError: false,
      };
    case USER.GET_CUSTOMER_DELEGATE_LIST:
      return {
        ...state,
        delegateList: action.payload,
        justUpdatedDelegate: false,
      };
    case USER.GET_CUSTOMER_PENDING_LIST:
      return {
        ...state,
        pendingList: action.payload,
        showingInviteMember: false,
        justDeletedInvite: false,
        justSentInvite: false,
      };
    case USER.SEND_INVITE:
      return {
        ...state,
        justSentInvite: true,
        showingInviteMember: false,
        inviteErrorServer: false,
        sendInviteSuccess: false,
      };
    case USER.GET_LICENSES_COUNT:
      return {
        ...state,
        licensesCount: action.payload,
      };
    case USER.SHOW_INVITE_MEMBER_MODAL:
      return {
        ...state,
        showingInviteMember: action.payload,
      };
    case USER.SHOW_DELETE_MEMBER_MODAL:
      return {
        ...state,
        showingDeleteMember: action.payload,
      };
    case USER.SHOW_EDIT_DELEGATE_MODAL:
      return {
        ...state,
        showingEditDelegate: action.payload,
      };
    case USER.SHOW_ADD_LICENSES_MODAL:
      return {
        ...state,
        showingAddLicenses: action.payload,
      };
    case USER.SHOW_CONFIRM_LICENSES_MODAL:
      return {
        ...state,
        showingConfirmLicenses: action.payload,
      };
    case USER.GET_BOB_TRANSFERS:
      return {
        ...state,
        transfersList: action.payload,
        createTransferError: null,
        transferUpdated: false,
      };
    case USER.GET_BOB_TRANSFER:
      return {
        ...state,
        transferDetails: action.payload,
      };
    case USER.SHOW_BOB_TRANSFER_MODAL:
      return {
        ...state,
        showingBoBTransfer: action.payload?.showModal,
        bobTransferScreen: action.payload?.screen,
        bobTransferType: action.payload?.type,
        receiveTransferError: null,
      };
    case USER.SHOW_RECEIVE_BOB_TRANSFER_MODAL:
      return {
        ...state,
        showingReceiveBoBTransfer: action.payload?.showModal,
        receiveBoBTransferCode: action.payload?.code,
        receiveBoBTransferPin: action.payload?.pin,
        createTransferError: null,
      };
    case USER.SHOW_CANCEL_BOB_TRANSFER_MODAL:
      return {
        ...state,
        showingCancelBoBModal: action.payload,
      };
    case USER.CREATE_BOB_TRANSFER_ERROR:
      return {
        ...state,
        createTransferError: action.payload,
      };
    case USER.AUTH_BOB_TRANSFER_ERROR:
      return {
        ...state,
        authTransferError: action.payload,
      };
    case USER.GET_ADVISOR_DETAILS:
      return {
        ...state,
        [`${action.payload?.type}AdvisorDetails`]: action.payload?.advisor,
        advisorDetails: action.payload?.advisor,
      };
    case USER.GET_ADVISOR_HOUSEHOLDS:
      return {
        ...state,
        advisorHouseholds: action.payload,
        advisorHouseholdsError: null,
      };
    case USER.GET_CUSTOMER_STENCIL_LIBRARY:
      return {
        ...state,
        customerStencilLibrary: action.payload,
      };
    case USER.GET_CUSTOMER_SCOPES:
      return {
        ...state,
        customerScopes: action.payload,
        updateCustomerScopesError: false,
      };
    case USER.GET_BILLING_INFO:
      return {
        ...state,
        billing: action.payload,
        isBillingInfoError: false,
      };
    case USER.GET_INVOICES:
      return {
        ...state,
        invoices: action.payload,
        isBillingInvoiceError: false,
      };
    case USER.GET_BILLING_SUBSCRIPTION:
      return {
        ...state,
        subscription: action.payload,
        reenableSubscriptionError: null,
        isBillingSubscriptionError: false,
        resubscribeError: null,
      };
    case USER.GET_SUBSCRIPTION_OPTIONS:
      return {
        ...state,
        subscriptionOptions: action.payload,
        subscriptionOptionError: null,
      };
    case USER.GET_TM_PREFERENCES:
      return {
        ...state,
        targetMapPreferences: action.payload,
        tmPrefError: null,
        updatedTargetMapPreferences: false,
      };
    case USER.GET_QUICKSIGHT_URL:
      return {
        ...state,
        quicksightUrl: action.payload,
      };
    case USER.TM_PREFERENCES_ERROR:
      return {
        ...state,
        tmPrefError: action.payload,
      };
    case USER.GET_TERMS:
      return {
        ...state,
        termsAndConditions: action.payload,
        userRequiresTerms: !action.payload.has_accepted_terms,
      };
    case USER.TOGGLE_EXPAND_SIDE_NAV:
      localStorage.setItem('sideNavExpanded', action.payload?.sideNavExpanded);
      return {
        ...state,
        sideNavWidth: action.payload?.sideNavWidth,
        sideNavExpanded: action.payload?.sideNavExpanded,
      };
    case USER.TOGGLE_SHOW_HIDDEN_FINANCIALS:
      return {
        ...state,
        showHiddenFinancials: action.payload,
      };
    case USER.TOGGLE_SHOW_CASH_VALUE_COLUMN:
      return {
        ...state,
        showCashValueColumn: action.payload,
      };
    case USER.TOGGLE_SHOW_MULTI_SELECT_FINANCIALS:
      return {
        ...state,
        showMultiSelectFinancials: action.payload,
      };
    case USER.TOGGLE_SHOW_MULTI_SELECT_AMR:
      return {
        ...state,
        showMultiSelectAMR: action.payload,
      };
    case USER.GET_USER_GROUPS:
      return {
        ...state,
        groups: action.payload,
      };
    case USER.GET_USER_ROLES:
      return {
        ...state,
        roles: action.payload,
      };
    case USER.GET_ROLE_PERMISSIONS:
      return {
        ...state,
        rolePermissions: action.payload,
      };
    case USER.CLONE_USER_ROLE:
      return {
        ...state,
        clonedRole: action.payload,
      };
    case USER.GET_DEFAULT_ROLE_PERMISSIONS:
      return {
        ...state,
        defaultRolePermissions: action.payload,
      };
    case USER.TOGGLE_SHOW_AMR_CUSTODIAN:
      return {
        ...state,
        amrShowCustodian: action.payload,
      };
    case USER.TOGGLE_SHOW_AMR_ENTITY:
      return {
        ...state,
        amrShowEntity: action.payload,
      };
    case USER.TOGGLE_SHOW_AMR_HIDDEN:
      return {
        ...state,
        amrShowHidden: action.payload,
      };
    case USER.TOGGLE_SHOW_AMR_KFFS:
      return {
        ...state,
        amrShowKffs: action.payload,
      };
    case USER.TOGGLE_SHOW_AMR_LEGAL:
      return {
        ...state,
        amrShowLegal: GetValueFromCookie('am-toggle-am-legal', action.payload),
      };
    case USER.TOGGLE_SHOW_AMR_LEGEND:
      return {
        ...state,
        amrShowLegend: GetValueFromCookie('am-toggle-am-key', action.payload),
      };
    case USER.TOGGLE_SHOW_AMR_MEMBERS:
      return {
        ...state,
        amrShowMembers: GetValueFromCookie('am-toggle-shazaam', action.payload),
      };
    case USER.GET_REPORT_LAYOUTS:
      return {
        ...state,
        reportLayouts: action.payload,
        createReportError: false,
      };
    case USER.GET_SIDEBAR_APP_LIST:
      return {
        ...state,
        sidebarApps: action.payload,
      };
    case USER.GET_CUSTOMER_SIGNAL_SETTINGS:
      return {
        ...state,
        customerSignalSettings: action.payload,
      };
    case USER.GET_INTEGRATIONS:
      return {
        ...state,
        integrations: action.payload,
        enabledIntegration: false,
        toggledIntegrationFinancials: false,
        integrationId: null,
      };
    case USER.SEARCH_INTEGRATION:
      return {
        ...state,
        integrationResults: action.payload,
      };
    case USER.GET_INTEGRATION_MEMBERS:
      return {
        ...state,
        integrationMembers: action.payload,
      };
    case USER.GET_INTEGRATION_MEMBERS_ERROR:
      return {
        ...state,
        integrationMembersError: action.payload,
      };
    case USER.CREATE_HH_INTEGRATION:
      return {
        ...state,
        integrationHousehold: action.payload,
        createdHouseholdIntegrationError: false,
      };
    case HOUSEHOLD.TOGGLE_SHOW_CONNECT_PREVIEW_MODAL:
      return {
        ...state,
        connectPreviewError: null,
        enteringPreviewMode: false,
      };
    case USER.USER_TOGGLE_MODAL:
      return {
        ...state,
        userToggleModal: action.payload,
      };
    case API.API_SUCCESS:
      switch (action.payload.label) {
        case USER.LOG_OUT_CUSTOMER:
          return {
            ...state,
            loggedOutOfCustomer: true,
          };
        case USER.GET_USER_INFO:
          UpdateUpscope(action.payload?.data);
          return {
            ...state,
          };
        case USER.UPDATE_MANAGER_INTEGRATION:
          return {
            ...state,
            updatedManagerIntegration: true,
            updateManagerIntegrationError: false,
          };
        case USER.ACCEPT_SHARING_INVITE:
        case USER.DECLINE_SHARING_INVITE:
          return {
            ...state,
            completedInvite: true,
          };
        case USER.ADD_AUTH_FACTOR:
          return {
            ...state,
            authFactorAdded: true,
            authFactorReturned: action.payload?.data,
            addAuthFactorError: null,
          };
        case USER.ENROLL_OTP_AUTH_FACTOR:
          return {
            ...state,
            authFactorReturned: action.payload?.data,
            addAuthFactorError: null,
          };
        case USER.CONFIRM_AUTH_FACTOR:
          return {
            ...state,
            authCodeEnabled: true,
          };
        case USER.DISABLE_AUTH_FACTOR:
          return {
            ...state,
            authCodeDisabled: true,
          };
        case USER.MOVE_CUSTOMER_STENCIL:
          return {
            ...state,
            movingStencil: false,
            movedStencil: true,
            moveStencilError: null,
          };
        case USER.SEND_RESET_PASSWORD:
          return {
            ...state,
            passwordResetSent: true,
          };
        case USER.UPDATE_TM_PREFERENCES:
          return {
            ...state,
            updatedTargetMapPreferences: true,
          };
        case USER.UPDATE_SEAT_CONFIG:
          return {
            ...state,
            showingAddLicenses: false,
            showingConfirmLicenses: false,
          };
        case USER.UPDATE_USER_ROLE:
        case USER.CLONE_USER_ROLE:
        case USER.CREATE_USER_ROLE:
          return {
            ...state,
            updatedUserRole: true,
          };
        case USER.DELETE_USER_ROLE:
          return {
            ...state,
            deletedUserRole: true,
          };
        case USER.UPDATE_TEAM_SETTINGS:
        case USER.UPDATE_DISCOVERY_TEAM_SETTINGS:
        case USER.DELETE_TEAM_LOGO:
          return {
            ...state,
            customerSettingsUpdated: true,
          };
        case USER.AUTHENTICATE_BOB_TRANSFER:
          return {
            ...state,
            authenticatedBoBTransfer: true,
            authTransferError: false,
          };
        case USER.CREATE_REPORT_LAYOUT:
          return {
            ...state,
            justCreatedLayout: true,
            createdLayout: action?.payload?.data,
            justSavedLayout: false,
            savedLayout: null,
          };
        case USER.UPDATE_REPORT_LAYOUT:
          return {
            ...state,
            justSavedLayout: true,
            savedLayout: action?.payload?.data,
            createdLayout: null,
            justCreatedLayout: false,
          };
        case USER.DELETE_REPORT_LAYOUT:
          return {
            ...state,
            createdLayout: null,
            justCreatedLayout: false,
            savedLayout: null,
            justSavedLayout: false,
          };
        case USER.ENABLE_INTEGRATION:
          return {
            ...state,
            enabledIntegration: true,
            integrationId: action?.payload?.data?.passId,
          };
        case USER.TOGGLE_INTEGRATION_FINANCIALS:
          return {
            ...state,
            toggledIntegrationFinancials: action?.payload?.data,
          };
        case USER.CREATE_BOB_TRANSFER_INTERNAL:
          return {
            ...state,
            showingBoBTransfer: false,
            createTransferError: null,
          };
        case USER.CREATE_BOB_TRANSFER_EXTERNAL:
          return {
            ...state,
            showingBoBTransfer: true,
            createTransferError: null,
            createdExternalTransfer: true,
          };
        case USER.EXTEND_BOB_TRANSFER:
          return {
            ...state,
            showingBoBTransfer: true,
            extendTransferError: null,
            extendedExternalTransfer: true,
          };
        case USER.EXECUTE_EXTERNAL_BOB_TRANSFER:
          return {
            ...state,
            showingBoBTransfer: false,
            createTransferError: null,
            createdExternalTransfer: false,
          };
        case USER.UPDATE_BOB_TRANSFER:
          return {
            ...state,
            transferUpdated: true,
          };
        case USER.SEND_INVITE:
          return {
            ...state,
            sendInviteSuccess: true,
          };
        default:
          return {
            ...state,
          };
      }
    case API.API_START:
      switch (action.payload) {
        case USER.SEND_INVITE:
          return {
            ...state,
            sendInviteSuccess: false,
          };
        case USER.MOVE_CUSTOMER_STENCIL:
          return {
            ...state,
            movingStencil: true,
            moveStencilError: null,
          };
        case USER.ENTER_CLIENT_PREVIEW_MODE:
          return {
            ...state,
            enteringPreviewMode: true,
          };
        default:
          return {
            ...state,
          };
      }
    case API.API_END:
      switch (action.payload) {
        case USER.SET_DELEGATE_ADVISOR:
          return {
            ...state,
            justChangedRole: true,
          };
        case USER.DELETE_CUSTOMER_MEMBER:
        case USER.DELETE_INVITE:
          return {
            ...state,
            justDeletedInvite: true,
          };
        case USER.UPDATE_MEMBER_ROLE:
          return {
            ...state,
            justUpdatedMember: true,
          };
        case USER.UPDATE_CUSTOMER_DELEGATE:
          return {
            ...state,
            justUpdatedDelegate: true,
          };
        default:
          return {
            ...state,
          };
      }
    case API.UNAUTHORIZED:
      switch (action.payload.label) {
        case USER.LOG_IN:
          RemoveCookies();
          return {
            ...state,
            loggedIn: false,
            user: {},
            loadedUser: false,
            loginError: true,
            responseError: {
              showError: true,
              message: 'Incorrect password or username',
            },
          };
        case USER.USER_MULTISESSION_LOGOUT:
          RemoveCookies();
          ToggleCookie('multisession_error', true);
          return {
            ...state,
            loggedIn: false,
            user: {},
            showLoginScreen: true,
            loadedUser: false,
            hasMultiSessionError: true,
            responseError: {
              showError: true,
              message: sessionErrorText,
            },
          };
        default:
          RemoveCookies();
          return {
            ...state,
            loggedIn: false,
            loadedUser: false,
            showLoginScreen: true,
            showSelectMFA: false,
            showAuthCode: false,
            modalTitle: 'Welcome to Asset-Map',
            user: {},
            responseError: {
              showError: HasCookie('multisession_error'),
              message: HasCookie('multisession_error') ? sessionErrorText : '',
            },
          };
      }
    case API.API_ERROR:
      switch (action.payload.label) {
        case USER.LOG_IN:
          RemoveCookies();
          return {
            ...state,
            loggedIn: false,
            user: {},
            loginError: true,
            responseError: GenerateAuthResponseError(action.payload.error),
          };
        case USER.SEND_RESET_PASSWORD:
          let errorStatus = action.payload.error.status;
          let errorText = 'Email address not found';
          if (errorStatus === 404) {
            errorText = 'Not found';
          }
          return {
            ...state,
            responseError: {
              showError: true,
              message: errorText,
            },
            resetMemberError: action.payload.error,
          };
        case USER.LOG_OUT:
          return {
            ...state,
            logoutError: action.payload.error,
          };
        case USER.SUBMIT_AUTH_CODE:
          const maxAttemptError = action.payload.error?.status === 403;
          RemoveCookies();
          return {
            ...state,
            loggedIn: false,
            user: {},
            loadedUser: false,
            authCodeError: true,
            mfaMaxAttemptError: maxAttemptError,
            responseError: {
              showError: maxAttemptError,
              message: maxAttemptError
                ? action.payload?.error?.data?.detail || 'Unknown Error'
                : null,
            },
          };
        case USER.RESEND_AUTH_CODE:
          return {
            ...state,
            resendCodeError: action.payload.error,
            responseError: {
              showError: true,
              message: action.payload?.error?.data?.detail || 'Unknown error',
            },
          };
        case USER.GET_USER_INFO:
          return {
            ...state,
            userInfoError: action.payload.error,
          };
        case USER.RESET_USER_PASSWORD:
        case USER.UPDATE_USER_PASSWORD:
          return {
            ...state,
            passwordResetError: action.payload.error.data,
          };
        case USER.ADD_AUTH_FACTOR:
          return {
            ...state,
            addAuthFactorError: action.payload.error,
          };
        case USER.CONFIRM_AUTH_FACTOR:
          return {
            ...state,
            authCodeError: true,
            addAuthCodeError: action.payload.error,
          };
        case USER.UPDATE_CUSTOM_USER_PREFERENCES:
          return {
            ...state,
            customSettingsError: action.payload.error,
          };
        case USER.UPDATE_USER_ROLE:
        case USER.CLONE_USER_ROLE:
        case USER.DELETE_USER_ROLE:
          return {
            ...state,
            updateUserRoleError: action.payload.error,
          };
        case USER.UPDATE_TEAM_SETTINGS:
        case USER.UPDATE_DISCOVERY_TEAM_SETTINGS:
          return {
            ...state,
            customerSettingsError: action.payload.error,
          };
        case USER.UPDATE_DISCOVERY_CONSENT_SETTINGS:
          return {
            ...state,
            discoveryConsentError: action.payload.error,
          };
        case USER.MOVE_CUSTOMER_STENCIL:
          return {
            ...state,
            movingStencil: false,
            moveStencilError:
              action.payload?.error?.data?.detail || 'Error moving stencil',
          };
        case USER.UPDATE_MANAGER_INTEGRATION:
          return {
            ...state,
            updatedManagerIntegration: false,
            updateManagerIntegrationError: action.payload.error,
          };
        case USER.DEACTIVATE_CUSTOMER_MEMBER_MFA:
        case USER.RESET_CUSTOMER_MEMBER_PASSWORD:
          return {
            ...state,
            resetMemberError: action.payload.error,
          };
        case USER.SEND_INVITE:
          return {
            ...state,
            inviteError: action.payload.error,
            justSentInvite: false,
            sendInviteSuccess: false,
          };
        case USER.RESEND_VERIFY_EMAIL:
          return {
            ...state,
            verifyEmailResentError: action.payload,
          };
        case USER.VERIFY_EMAIL_CODE:
          return {
            ...state,
            verifyEmailCodeError: action.payload?.error?.data,
          };
        case USER.ACCEPT_SHARING_INVITE:
        case USER.DECLINE_SHARING_INVITE:
          return {
            ...state,
            consumerInviteError: action.payload.error,
          };
        case USER.ENTER_CLIENT_PREVIEW_MODE:
          return {
            ...state,
            enteringPreviewMode: false,
            connectPreviewError:
              action.payload?.error?.data?.detail ||
              'Error entering preview mode.',
          };
        case USER.UPDATE_MEMBER_ROLE:
          return {
            ...state,
            updateMemberError: action.payload.error,
            justUpdatedMember: false,
          };
        case USER.UPDATE_CUSTOMER_DELEGATE:
          return {
            ...state,
            justUpdatedDelegate: false,
          };
        case USER.UPDATE_SEAT_CONFIG:
          return {
            ...state,
            updateSeatsError: action.payload.error,
          };
        case USER.GET_ADVISOR_HOUSEHOLDS:
          return {
            ...state,
            advisorHouseholdsError: action.payload.error,
          };
        // case USER.CREATE_BOB_TRANSFER_INTERNAL:
        // case USER.CREATE_BOB_TRANSFER_EXTERNAL:
        case USER.EXECUTE_EXTERNAL_BOB_TRANSFER:
          return {
            ...state,
            createTransferError: action.payload,
          };
        case USER.UPDATE_BOB_TRANSFER:
          return {
            ...state,
            updateTransferError: action.payload?.error,
          };
        case USER.EXTEND_BOB_TRANSFER:
          return {
            ...state,
            extendTransferError: action.payload?.error,
          };
        case USER.UPDATE_CUSTOMER_SCOPES:
          return {
            ...state,
            updateCustomerScopesError: action.payload?.error,
          };
        case USER.GET_BILLING_INFO:
          return {
            ...state,
            isBillingInfoError: true,
            updateBillingError: null,
          };
        case USER.UPDATE_BILLING_INFO:
          return {
            ...state,
            updateBillingError: action.payload.error,
          };
        case USER.GET_INVOICES:
          return {
            ...state,
            isBillingInvoiceError: true,
          };
        case USER.GET_BILLING_SUBSCRIPTION:
          return {
            ...state,
            isBillingSubscriptionError: true,
          };
        case USER.GET_SUBSCRIPTION_OPTIONS:
          return {
            ...state,
            subscriptionOptionError: action.payload.error,
          };
        case USER.RESUBSCRIBE_PLAN:
          return {
            ...state,
            resubscribeError: action.payload.error,
          };
        case USER.REENABLE_SUBSCRIPTION:
          return {
            ...state,
            reenableSubscriptionError: action.payload?.error?.data?.detail,
          };
        case USER.GET_USER_GROUPS:
          return {
            ...state,
            userGroupsError: action.payload.error,
          };
        case USER.GET_USER_ROLES:
          return {
            ...state,
            rolesError: action.payload.error,
          };
        case USER.GET_ROLE_PERMISSIONS:
          return {
            ...state,
            rolePermissionsError: action.payload.error,
          };
        case USER.GET_DEFAULT_ROLE_PERMISSIONS:
          return {
            ...state,
            defaultRolePermissionsError: action.payload.error,
          };
        case USER.CREATE_USER_ROLE:
          return {
            ...state,
            createRoleError: action.payload.error,
          };
        case USER.CREATE_REPORT_LAYOUT:
          return {
            ...state,
            createReportError: action.payload.error,
          };
        case USER.ENABLE_INTEGRATION:
          return {
            ...state,
            enableIntegrationError: action.payload.error,
          };
        case USER.SEARCH_INTEGRATION:
          return {
            ...state,
            integrationResultsError: action.payload,
          };
        case USER.CREATE_HH_INTEGRATION:
          return {
            ...state,
            createdHouseholdIntegrationError: action.payload.error,
          };
        default:
          return state;
      }
    default:
      return state;
  }
};

export default user;
