import styled, { css, keyframes } from 'styled-components';
import { Link } from 'react-router-dom';
import { lighten } from 'polished';
import {
  colors,
  fonts,
  buttonColors,
  messageColors,
  maxDevice,
} from '../variables';

export const PrimaryHeading = styled.h1`
  @media ${maxDevice.sideNav} {
    font-size: ${(props) => (props.$fontSize ? '18px' : '20px')};
  }
  color: ${fonts.primaryColor};
  font-size: ${fonts.primaryHeading};
  font-size: ${(props) =>
    props.$fontSize ? props.$fontSize : fonts.primaryHeading};
  line-height: ${fonts.primaryHeading};
  font-weight: ${fonts.semiBold};
  margin: ${(props) => (props.margin ? props.margin : '0 0 15px 0')};
  display: flex;
  align-content: center;
  align-items: center;
  justify-content: flex-start;
`;

export const SecondaryHeading = styled.h2`
  @media ${maxDevice.sideNav} {
    font-size: 16px;
  }
  color: ${fonts.primaryColor};
  font-size: ${fonts.secondaryHeading};
  line-height: ${fonts.secondaryHeading};
  margin: ${(props) => (props.margin ? props.margin : '0 0 10px 0')};
  font-weight: ${fonts.semiBold};
`;

export const TertiaryHeading = styled.h3`
  @media ${maxDevice.sideNav} {
    font-size: 14px;
  }
  color: ${fonts.primaryColor};
  font-size: ${fonts.tertiaryHeading};
  line-height: ${fonts.tertiaryHeading};
  margin: ${(props) => (props.margin ? props.margin : '0 0 10px 0')};
  font-weight: ${fonts.semiBold};
`;

export const SubGroupHeading = styled.h4`
  @media ${maxDevice.sideNav} {
    font-size: 12px;
  }
  color: ${fonts.primaryColor};
  font-size: ${fonts.subGroupHeading};
  line-height: ${fonts.subGroupHeading};
  margin: ${(props) => (props.margin ? props.margin : '0 0 10px 0')};
  font-weight: ${fonts.semiBold};
`;

export const ContentHeader = styled.p`
  color: ${fonts.primaryColor};
  font-size: ${fonts.secondaryHeading};
  margin-bottom: 15px;
  font-weight: ${fonts.bold};
  text-transform: uppercase;
`;

export const HeaderSubContent = styled.div`
  @media ${maxDevice.tablet} {
    font-size: 11px;
  }
  font-size: 12px;
  max-width: 950px;
  margin: ${(props) => (props.$margin ? props.$margin : '10px 5px 20px 0')};
  color: ${(props) => (props.$showOld ? colors.paleGrey : colors.mediumGrey)};
  p {
    &:first-child {
      margin-bottom: 2px;
    }
    a {
      color: ${colors.lightBlue};
    }
  }
`;

const hoverLink = lighten(0.1, `${colors.lightBlue}`);
export const LinkText = styled.p`
  color: ${colors.lightBlue};
  font-size: ${fonts.small};
  cursor: pointer;
  transition: all 0.1s ease;
  &:hover {
    color: ${hoverLink};
    transform: scale(1.01);
  }
`;

export const LinkButton = styled.button`
  background: none;
  color: ${(props) => (props.disabled ? colors.paleGrey : colors.lightBlue)};
  cursor: ${(props) => (props.disabled ? 'not-allowed' : 'pointer')};
  margin-top: 10px;
  &:hover {
    color: ${(props) => (props.disabled ? colors.paleGrey : colors.blue)};
  }
`;

export const LinkTextSmall = styled.p`
  span,
  a {
    color: ${colors.lightBlue};
    font-size: ${fonts.small};
    cursor: pointer;
    transition: all 0.1s ease;
    &:hover {
      color: ${hoverLink};
      transform: scale(1.01);
    }
  }
`;

export const Text = styled.p`
  color: ${fonts.primaryColor};
  font-size: ${fonts.primary};
`;

export const CapsLockWarning = styled.p`
  color: ${messageColors.error};
  font-size: ${fonts.small};
  margin: 5px 0 0 3px;
  text-transform: uppercase;
  font-weight: ${fonts.semiBold};
`;

export const AuthText = styled(Text)`
  font-size: 13px;
  text-align: center;
  padding: 20px 0px;
`;

export const EmailLink = styled.a`
  color: ${colors.lightBlue};
  display: inline;
  cursor: pointer;
  &:hover {
    color: ${hoverLink};
  }
`;

export const LinkStyle = styled(EmailLink)`
  text-decoration: underline;
  &:hover {
    color: ${hoverLink};
  }
`;

// const hoverButtonLinkExternal = lighten(0.05, `${buttonColors.primary}`);
// export const ButtonLinkExternal = styled.a`
//   background: ${buttonColors.primary};
//   text-align: center;
//   padding: 10px 15px;
//   border-radius: 3px;
//   border: 1px solid transparent;
//   color: white;
//   cursor: pointer;
//   display: block;
//   font-size: ${fonts.buttonText};
//   line-height: ${fonts.buttonText};
//   font-weight: ${fonts.semiBold};
//   transition: all 0.1s ease;
//   letter-spacing: 0.3px;
//   &:hover {
//     transform: scale(1.01);
//     background-color: ${hoverButtonLinkExternal};
//   }
// `;

export const ButtonLinkExternal = styled.a`
  background: none;
  text-align: center;
  padding: 10px 15px;
  border-radius: 3px;
  border: 1px solid ${buttonColors.inactive};
  color: ${buttonColors.inactive};
  cursor: pointer;
  display: block;
  font-size: ${fonts.buttonText};
  line-height: ${fonts.buttonText};
  font-weight: ${fonts.semiBold};
  transition: all 0.1s ease;
  letter-spacing: 0.3px;
  &:hover {
    transform: scale(1.01);
    background-color: ${colors.lighterGrey};
  }
`;

export const ButtonLinkExternalPrimary = styled.a`
  background: ${colors.green};
  text-align: center;
  padding: 10px 15px;
  border-radius: 3px;
  border: 1px solid transparent;
  color: ${colors.white};
  cursor: pointer;
  display: block;
  font-size: ${fonts.buttonText};
  line-height: ${fonts.buttonText};
  font-weight: ${fonts.semiBold};
  transition: all 0.1s ease;
  letter-spacing: 0.3px;
  &:hover {
    transform: scale(1.01);
    background-color: ${lighten(0.05, buttonColors.primary)};
  }
`;

const hoverButtonLink = lighten(0.05, `${buttonColors.primary}`);
export const ButtonLink = styled(Link)`
  background: ${buttonColors.primary};
  text-align: center;
  padding: 10px 15px;
  border-radius: 3px;
  border: 1px solid transparent;
  color: ${colors.white};
  cursor: pointer;
  display: block;
  font-size: ${fonts.buttonText};
  line-height: ${fonts.buttonText};
  font-weight: ${fonts.semiBold};
  transition: all 0.1s ease;
  letter-spacing: 0.3px;
  &:hover {
    transform: scale(1.01);
    background-color: ${hoverButtonLink};
  }
`;

export const BackgroundGradient = css`
  background-image: linear-gradient(
    90deg,
    ${colors.lightGrey} 0px,
    rgb(242, 242, 242) 40px,
    ${colors.lightGrey} 80px
  );
  background-size: 600px;
`;

const shineLines = keyframes`
  0%{
    background-position: -100px
  }
  100% {
    background-position: 140px
 }
`;

export const LoadingPlaceholder = styled.span`
  @media ${maxDevice.sideNav} {
    width: ${(props) => (props.mobileWidth ? props.mobileWidth : props.width)};
  }
  margin: ${(props) => (props.margin ? props.margin : '6px 0')};
  display: ${(props) => (props.inline ? 'inline-block' : 'block')};
  height: ${(props) => (props.height ? props.height : '18px')};
  width: ${(props) => (props.width ? props.width : '100px')};
  border-radius: ${(props) => (props.round ? '100%' : '2px')};
  float: ${(props) => (props.float ? props.float : null)};
  background: ${colors.lightGrey};
  ${BackgroundGradient};
  animation: ${shineLines} 1.2s infinite ease-in-out;
`;

export const RowButton = styled.div`
  cursor: pointer;
  margin-bottom: 12px;
  font-size: 12px;
  font-weight: ${fonts.semiBold};
  padding: 8px;
  border-radius: 3px;
  border: 1px solid ${colors.lightBlue};
  color: ${colors.lightBlue};
  display: flex;
  align-content: center;
  align-items: center;
  justify-content: center;
  &:hover {
    transform: scale(1.01);
    background: #f7ffff;
  }
`;

export const InfoBanner = styled.div`
  display: flex;
  align-content: center;
  align-items: center;
  justify-content: flex-start;
  border: 1px solid ${colors.blue};
  color: ${colors.blue};
  background: ${colors.paleBlue};
  border-radius: 3px;
  margin-top: 20px;
  padding: 10px 15px;
  img {
    width: 20px;
    height: 20px;
    margin-right: 13px;
    flex: 0 0 auto;
  }
  p {
    font-size: 12px;
    flex: 1 1 auto;
    a {
      color: ${colors.blue};
      font-weight: ${fonts.semiBold};
    }
  }
`;

export const StatusLabel = styled.span`
  text-transform: uppercase;
  padding: 4px 10px;
  font-size: 10px;
  border-radius: 2px;
  margin: ${(props) => props.margin};
  font-weight: ${fonts.semiBold};
  color: ${(props) => props.color};
  background: ${(props) => props.background};
  border: ${(props) =>
    `1px solid ${props.borderColor ? props.borderColor : props.background}`};
`;

export const ClipTextEllipsis = css`
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`;
